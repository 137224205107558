import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import moment from 'moment';
import * as yup from 'yup';

import { convertArrayToObject } from '../../helpers';
import { sort_date } from '../../helpers/functions';

import Header from '../../components/Header';
import HeaderDetail from '../../components/Header/Detail';
import Footer from '../../components/FooterNavBar';
import SearchBox from './components/SearchBox';
import SearchResult from './components/SearchResult';
import FilterDrawer from '../../components/Drawer/SortFilter'
import useSnackbar from '../../components/Snackbar/functions';
import Loading from '../../components/Loading';

import { getClassCategoryList, getSearchAvail } from './action';
import './styles.scss';

const ClassRegister = ({
  i18n,
  getClassCategoryList,
  getClassCategoryListState,
  getSearchAvail,
  getSearchAvailListState
}) => {
  const history = useHistory();
  const [ loading, setLoading ] = useState(false);
  const [ searchForm, setSearchForm ] = useState();
  const [ searchResult, setSearchResult ] = useState();
  const [ searchResultPagination, setSearchResultPagination ] = useState();
  const [ errors, setErrors ] = useState();
  const [ filter, setFilter ] = useState();

  const isDefault = getSearchAvailListState?.status === 'idle'
  const isEmpty = getSearchAvailListState?.status === 'resolve' && (!searchResult || searchResult?.length === 0);

  const { showSnackbar } = useSnackbar();

  const showSnackbarBy = (message, type) => {
    showSnackbar({
      message: message,
      type: type
    })
  }
  
  const filterList = [
    {
      key: 'sort',
      type: 'radio', 
      drawerTop: 350, 
      list: [
        {label: 'Jam paling awal', value: 'asc_hour'},
        {label: 'Jam paling akhir', value:'dsc_hour'}
      ]
    },
    { 
      key: 'filter', 
      type: 'checkbox', 
      drawerTop: 50, 
      list: [
        {
          label: i18n('label.avail'), 
          value: [
            {label: i18n('label.available'), value: 1},
            {label: i18n('label.sold_out'), value: 2}
          ]
        },
      ]
    }
  ]

  const getHeaderDesc = () => {
    if (isDefault) return 'desc_search_class'
    else if (isEmpty) return 'desc_search_avail_class'
    else return 'desc_confirm_class'
  }

  const loadData = (params) => {
    setLoading(true);
    getSearchAvail({
      ...(searchForm.class !== 0 ? {
        ClassCategoryId: searchForm.class
      } : {}),
      SearchDate: searchForm.date && moment(searchForm.date).format("YYYY-MM-DD"),
      ClassType: searchForm.classType || 'REGULER',
      size: 10,
      ...params
    })
  }

  const searchHandler = () => {
    const schema = yup.object().shape({
      class: yup.string().required('selected'),
      date: yup.string().required('selected'),
    });

    schema
      .validate(searchForm, { abortEarly: false })
      .then(() => {
        setSearchResult([])
        setSearchResultPagination()
        loadData()
      })
      .catch((err) => {
        setErrors(convertArrayToObject(err.inner, 'path', 'message'));
      });
  }

  const filterSubmit = (filterParams) => {
    setFilter(filterParams)
  }

  useEffect(() => {
    getClassCategoryList()
  }, [])

  useEffect(() => {
    if (loading) {
      const { status, data = [], error, pagination = {} } = getSearchAvailListState;

      if (status === 'resolve') {
        setLoading(false);
        const tmpData = data ? data : [];
        setSearchResult([
          ...searchResult || [],
          ...tmpData
        ])
        setSearchResultPagination(pagination)
      } else if (status === 'rejected') {
        setLoading(false);
        let errMessage = `${i18n('register_page.step_1.failed')} `;
        switch(error?.response?.status) {
          case 409:
            errMessage += `${i18n('register_page.step_1.conflict')}`
            break;
          case 429:
            errMessage += `${i18n('register_page.step_1.too_many_request')}`
            break;
          default:
            errMessage += error?.response?.data?.message
        }
        showSnackbarBy(errMessage, 'error')
      }
    }
  }, [getSearchAvailListState?.status])

  useEffect(() => {
    if (filter) {
      let avail = false;
      let not_avail = false;
      let sorting_type
      
      filter.map(({ key, value }) => {
        if (key === 'filter') {
          if (value?.value === 1) {
            avail = true
          } else if (value?.value === 2) {
            not_avail = true
          }
        } 

        if (key === 'sort') {
          if (value === 'asc_hour') {
            sorting_type = 'asc'
          } else if (value === 'dsc_hour') {
            sorting_type = 'dsc'
          }
        }
      })

      const { data } = getSearchAvailListState;
      let tmpSearchResult = [...data]

      if (avail || not_avail) {
        tmpSearchResult = data.filter(({ fullBook }) => fullBook === !avail || fullBook === not_avail)
      }

      if (sorting_type) {
        tmpSearchResult = sort_date(tmpSearchResult, 'completeDate', sorting_type)
      }

      setSearchResult(tmpSearchResult)
    }
  }, [filter])

  return (<>
    {(loading && !searchResultPagination?.page) && <Loading/>}
    <Header label={i18n('label.class_regis')}
      onBackClick={() => history.push('/')}
    />
    <HeaderDetail 
      title={i18n('class_register_page.title')}
      desc={i18n(`class_register_page.${getHeaderDesc()}`)}
    />
    <section className='class-register-container'>
      <SearchBox
        i18n={i18n}
        searchForm={searchForm}
        setSearchForm={setSearchForm}
        onSubmit={searchHandler}
        getClassCategoryListState={getClassCategoryListState}
        errors={errors}
        setErrors={setErrors}
      />
      <SearchResult
        i18n={i18n}
        isDefault={isDefault}
        isEmpty={isEmpty}
        data={searchResult}
        loadData={loadData}
        pagination={searchResultPagination}
      />
    </section>

    {(isEmpty || isDefault) ? 
    <Footer i18n={i18n}/>
    :
    <FilterDrawer
      i18n={i18n}
      filterList={filterList}
      onSubmit={filterSubmit}
    />}
  </>);
};


ClassRegister.propTypes = {
  getClassCategoryList: PropTypes.func,
  getClassCategoryListState: PropTypes.object,
  getSearchAvail: PropTypes.func,
  getSearchAvailListState: PropTypes.object
};

const mapStateToProps = (state) => ({
  getClassCategoryListState: {
    status: state.getClassCategoryListReducer.status,
    error: state.getClassCategoryListReducer.error,
    data: state.getClassCategoryListReducer.data
  },
  getSearchAvailListState: state.getSearchAvailReducer
});

export default compose(connect(mapStateToProps, { getClassCategoryList, getSearchAvail }))(ClassRegister);
