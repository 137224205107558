import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Icon from '../../Icon';

import './styles.scss';

const TextInput = ({
  label,
  sublabel,
  placeholder,
  errorMessage,
  inputProps,
  value,
  className = '',
  iconLeft,
  iconRight,
  textRight,
  textLeft,
  prependText,
  disable = false,
  detailInput,
  inputMode,
  iconRightClick,
  withHangingLabel,
  withPlaceholder = false
}) => {
  const [focus, setFocus] = useState(false);

  const additionalInputProps = {
    onBlur: () => setFocus(false),
    onFocus: () => setFocus(true)
  }

  return (
    <div
      className={cn(`input-container ${className}`, {
        'err': errorMessage,
        'hanging-label': withHangingLabel,
        disable,
        'text': inputMode === 'textarea',
        'with-placeholder': withPlaceholder,
      })}
    >
      {label && (
        <div className={cn('label', {
          'active': !!value || focus || textLeft,
        })}>
          {label}
          {sublabel && <div className='sublabel'>{sublabel}</div>}
        </div>
      )}
      <div className='input-wrap'>
        <div className='input-group'>
          {iconLeft && <Icon icon={iconLeft} className="icon-input-left"/>}
          {textLeft && <span className={'text left'}>{textLeft}</span>}
          {inputMode === 'textarea' ? (
            <textarea
              type='text'
              placeholder={placeholder}
              value={value || ''}
              readOnly={disable}
              rows='4'
              style={{ resize: 'none', width: '100%' }}
              {...additionalInputProps}
              {...inputProps}
            />
          ) : (
            <input
              type='text'
              placeholder={placeholder}
              value={value || ''}
              readOnly={disable}
              {...additionalInputProps}
              {...inputProps}
            />
          )}
          {iconRight && (
            <Icon
              icon={iconRight}
              className="icon-input-right"
              onClick={iconRightClick && iconRightClick}
            />
          )}
          {textRight && <span className={'text right'}>{textRight}</span>}
        </div>
        {detailInput && <div className='detail-input'>{detailInput}</div>}
        {errorMessage && <div className='err-message'>{errorMessage}</div>}
      </div>
    </div>
  );
};

TextInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.any,
  inputProps: PropTypes.object,
  value: PropTypes.string,
  className: PropTypes.string,
  sublabel: PropTypes.any,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  inputMode: PropTypes.string
};

export default TextInput;
