import React, { useEffect } from 'react';
import { useLocation, useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';

import SnackbarProvider from '../../SnackbarProvider';

const FirstLayout = ({
  route, 
  i18n
}) => {
  const { pathname } = useLocation();
  const history = useHistory();

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    
    if (accessToken) {
      history.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  useEffect(() => {
    window && window.scrollTo(0, 0)
  }, [pathname])

  return (
    <SnackbarProvider>
      <div className='layout-wrap' id="guest">
        <main>{renderRoutes(route.routes, { i18n })}</main>
      </div>
    </SnackbarProvider>
  )
}

FirstLayout.propTypes = {
  route: PropTypes.object,
  i18n: PropTypes.func
};

const mapStateToProps = (state) => ({
  i18n: state.langReducer.i18n
});

export default connect(mapStateToProps)(FirstLayout);
