const defaultResponse = {
  data: [],
  status: 'idle',
  error: ''
};

const getClassListReducer = (state = defaultResponse, action) => {
  switch (action.type) {
    case 'GET_CLASS_LIST_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_CLASS_LIST_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_CLASS_LIST_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    default:
      return state;
  }
};

const getClassDetailReducer = (state = {...defaultResponse, data: {}}, action) => {
  switch (action.type) {
    case 'GET_CLASS_DETAIL_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_CLASS_DETAIL_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_CLASS_DETAIL_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_CLASS_DETAIL':
      return {
        ...state,
        ...defaultResponse,
        data: {}
      };
    default:
      return state;
  }
};

const postClassDetailReducer = (state = {...defaultResponse, data: {}}, action) => {
  switch (action.type) {
    case 'POST_CLASS_DETAIL_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'POST_CLASS_DETAIL_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'POST_CLASS_DETAIL_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_POST_CLASS_DETAIL': 
      return {
        ...state,
        ...defaultResponse,
        data: {}
      }
    default:
      return state;
  }
};

const deleteClassReducer = (state = {...defaultResponse, data: {}}, action) => {
  switch (action.type) {
    case 'DELETE_CLASS_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'DELETE_CLASS_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'DELETE_CLASS_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_DELETE_CLASS': 
      return {
        ...state,
        ...defaultResponse,
        data: {}
      }
    default:
      return state;
  }
};

export {
  getClassListReducer,
  getClassDetailReducer,
  postClassDetailReducer,
  deleteClassReducer,
};
