import React, { useState } from 'react';

import Modal from '../../../../components/Modal';
import Row from '../../../../components/Row';
import NumberInput from '../../../../components/Input/Number';

import './styles.scss';

const TopUpMember = ({ 
  i18n, 
  member,
  showModal,
  setShowModal,
  submit
}) => {
  const [ newKuota, setNewKuota ] = useState();
  const [ error, setError ] = useState();

  const onSubmit = () => {
    if (!newKuota) {
      setError('required')
    } else {
      submit({
        ...member,
        quota: newKuota
      })
      setNewKuota();
    }
  }

  const changesHandler = (val) => {
    setNewKuota(val)
    setError()
  }
  
  return (
    <Modal
      onClose={() => setShowModal(false)}
      isOpen={showModal}
      hasCloseIcon
      buttonSecondaryActionHandler={() => setShowModal(false)}
      buttonActionHandler={onSubmit}
      buttonText={i18n('cta.save')}
      buttonSecondaryText={i18n('cta.cancel_2')}
      portalId="top-up-modal"
    >
      <div className="top-up-modal-container">
        <div className="member-detail">
          <div className="name">{member?.name}</div>
          <div className="phone">{member?.phone}</div>
        </div>
        <Row className="remaining" label={i18n('label.remaining_kuota')} value={member?.quota}/>
        <div className="top-up-input-wrap">
          <NumberInput
            label={i18n('label.add_kuota')}
            value={newKuota}
            inputProps={{
              type: 'number',
              onChange: (e) => setNewKuota(e.target.value)
            }}
            withHangingLabel
            addMinusControl={isMinus => {
              const tmpKuota = isMinus ? Number(newKuota || 0) - 1 : Number(newKuota || 0) + 1;
              changesHandler(tmpKuota)
            }}
            errorMessage={error && i18n(`validation.${error}`)}
          />
        </div>

        <Row className="final" label={i18n('label.final_kuota')} value={member?.quota + Number(newKuota || 0)}/>
      </div>
    </Modal>
  );
};

export default TopUpMember
