const defaultResponse = {
  data: [],
  status: 'idle',
  error: ''
};

const getInstructorListReducer = (state = defaultResponse, action) => {
  switch (action.type) {
    case 'GET_INSTRUCTOR_LIST_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_INSTRUCTOR_LIST_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_INSTRUCTOR_LIST_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    default:
      return state;
  }
};

const getInstructorDetailReducer = (state = {...defaultResponse, data: {}}, action) => {
  switch (action.type) {
    case 'GET_INSTRUCTOR_DETAIL_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_INSTRUCTOR_DETAIL_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_INSTRUCTOR_DETAIL_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_INSTRUCTOR_DETAIL':
      return {
        ...state,
        ...defaultResponse,
        data: {}
      };
    default:
      return state;
  }
};

const postInstructorDetailReducer = (state = {...defaultResponse, data: {}}, action) => {
  switch (action.type) {
    case 'POST_INSTRUCTOR_DETAIL_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'POST_INSTRUCTOR_DETAIL_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'POST_INSTRUCTOR_DETAIL_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_POST_INSTRUCTOR_DETAIL': 
      return {
        ...state,
        ...defaultResponse,
        data: {}
      }
    default:
      return state;
  }
};

const deleteInstructorReducer = (state = {...defaultResponse, data: {}}, action) => {
  switch (action.type) {
    case 'DELETE_INSTRUCTOR_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'DELETE_INSTRUCTOR_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'DELETE_INSTRUCTOR_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_DELETE_INSTRUCTOR': 
      return {
        ...state,
        ...defaultResponse,
        data: {}
      }
    default:
      return state;
  }
};

export {
  getInstructorListReducer,
  getInstructorDetailReducer,
  postInstructorDetailReducer,
  deleteInstructorReducer,
};
