import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import cn from 'classnames';

import Button from '../../../../components/Button';
import TextInput from '../../../../components/Input/TextInput';
import Timer from './Timer';

import './styles.scss';

const NumberVerification = ({
  err,
  handlerChanges,
  i18n,
  otpCode,
  resendOtp,
  phone,
  setIsOTP,
}) => {
  const otpInputsRef = useRef([]);
  const [ otp, setOtp ] = useState([])
  const [ endTime, setEndTime ] = useState()
  
  const otpMaxLength = 4;
  const otpInputArr = [];

  const handlerOTPChanges = e => {
    const { name, value } = e.target;
    const index = parseInt(name, 10);
    const tmpOtp = [...otp];

    tmpOtp[index] = value;

    const currIndex = parseInt(name, 10);
    const nextIndex = currIndex + 1;
    const prevIndex = currIndex - 1;

    if (value.length === 1) {
      if (nextIndex < otpMaxLength) {
        otpInputsRef.current[nextIndex].focus()
      }
    } else if (value.length === 0) {
      if (prevIndex >= 0) {
        otpInputsRef.current[prevIndex].focus()
      }
    } else {
      tmpOtp[index] = value[0];
    }

    setOtp(tmpOtp)

    if (tmpOtp.length === otpMaxLength) {
      handlerChanges(tmpOtp.join(''))
    } else {
      handlerChanges()
    }
  }

  const handlerKeyUp = e => {
    const { keyCode } = e;
    const { name } = e.target;

    const currIndex = parseInt(name, 10);
    const nextIndex = currIndex + 1;
    const prevIndex = currIndex - 1;
    
    if (keyCode === 8 || keyCode === 37) { //prev
      if (prevIndex > -1) {
        if (!(keyCode === 8 && otp[currIndex] !== '')) {
          otpInputsRef.current[prevIndex].focus()
        }
      }
    } else if (keyCode === 39) { //next
      otpInputsRef.current[nextIndex].focus()
    }
  }

  const handlerResendOTP = () => {
    // Set Timer
    localStorage.getItem('endTime');
    const timeFormat = 'dddd, MMMM Do YYYY, h:mm:ss a';
    setEndTime(moment().add(1, 'minute').format(timeFormat));
    
    // Send request POST Send-OTP
    if (setIsOTP) {
      const finalParams = {
        phone: `+62${phone}`
      }
  
      setIsOTP(true);
      resendOtp(finalParams,true);
    } else {
      resendOtp();
    }
  }

  useEffect(() => {
    if (otpCode) {
      setOtp(Array.from(otpCode))
    }
  }, [otpCode])

  for (let index = 0; index < otpMaxLength; index++) {
    otpInputArr.push(
      <TextInput
        key={index}
        value={otp[index]}
        inputProps={{
          name: index,
          maxLength:"1",
          type: 'number',
          patter: "\d*",
          ref: el => otpInputsRef.current[index] = el,
          onChange: handlerOTPChanges,
          onKeyDown: handlerKeyUp,
          id: `otp-input-${index}`
        }}
        errorMessage={!!err}
      />
    );
  }

  return (
    <div className='number-verification-container'>
      <div className="label">{i18n('register_page.insert_otp')}</div>
      <div className={cn("otp-input", { err })}>
        {otpInputArr}
      </div>
      {(err) && <div className="err-message">{err}</div>}
      <div className="timer-wrap">
        {i18n('register_page.no_receive_otp')}
        <Timer endTime={endTime} resetTime={() => setEndTime()}/>
        {!endTime && 
        <Button color="purple" type='button' flat onClick={handlerResendOTP}>{i18n('cta.send_again')}</Button>
        }
      </div>
    </div>
    
  );
};

export default NumberVerification;
