import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";

import Drawer from '../../Drawer';
import Loading from '../../Loading';

import './styles.scss';

const MenuDrawer = ({
  i18n,
  showDrawer, 
  setShowDrawer,
  isAdmin,
}) => {
  const history = useHistory();
  const [ loading, setLoading ] = useState(false);

  const adminMenuList = [
    {label: i18n('label.class_regis'), link: '/admin/class'},
    {label: i18n('label.class_schedule'), link: '/admin/schedule'},
    {label: i18n('label.member_list_top_up'), link: '/admin/member'},
  ]

  const userMenuList = [
    {label: i18n('label.class_regis'), link: '/class'},
    {label: i18n('label.my_schedule'), link: '/schedule'},
    {label: i18n('label.account'), link: '/account'},
    {label: i18n('label.help'), link: '/help'},
  ]

  const menuList = [
    {label: 'Home', link: isAdmin ? '/admin':'/' },
    ...isAdmin ? adminMenuList :userMenuList,
    {label: 'Logout', onClick: () => {
      localStorage.removeItem('accessToken');
      setLoading(true)
      setShowDrawer(false)
      setTimeout(() => {
        window.location.href = '/admin/login';
      }, 300)
    }}
  ]

  return (<>
    {loading && <Loading/>}
    <Drawer
      showDrawer={showDrawer}
      top={200}
      threshold={0.5}
      onCloseDrawer={() => setShowDrawer(false)}
      className="menu-drawer-component"
    >
      {onCloseDrawer => {
        return (
          <Drawer.Body className="menu-list-drawer">
            {menuList.map(({ label, link, onClick }, idx) => (
              <div 
                key={idx}
                className="list-item" 
                onClick={onClick ? onClick : () => history.push(link)}
              >{label}</div>
            ))}
          </Drawer.Body>
        );
      }}
    </Drawer>
  </>);
};

const mapStateToProps = (state) => ({
  i18n: state.langReducer.i18n
});

export default connect(mapStateToProps)(MenuDrawer);
