import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import ListDropdown from '../../../components/Dropdown/List';
import TextInput from '../../../components/Input/TextInput';

import { miliseconds } from '../../../helpers/functions';

import { getClassCategoryList } from '../../../routes/ClassRegister/action';

import './styles.scss';

const FormClass = ({ 
  i18n, 
  form, 
  handlerChanges, 
  errors, 
  getClassCategoryList,
  getClassCategoryListState,
}) => {

  const class_type_options = [
    { value: 'REGULER', name: i18n('label.reguler') },
    { value: 'KIDS', name: i18n('label.children') }
  ];

  const categoryClass = getClassCategoryListState?.data || {};
  
  const duration_options = [
    {value: miliseconds(0, 30), label: `${i18n('class_register_page.minutes', {m: 30})}`},
    {value: miliseconds(1), label: `${i18n('class_register_page.hour', {h: 1})}`},
    {value: miliseconds(1, 15), label: `${i18n('class_register_page.hour', {h: 1})} ${i18n('class_register_page.minutes', {m: 15})}`},
    {value: miliseconds(1, 30), label: `${i18n('class_register_page.hour', {h: 1})} ${i18n('class_register_page.minutes', {m: 30})}`},
    {value: miliseconds(1, 45), label: `${i18n('class_register_page.hour', {h: 1})} ${i18n('class_register_page.minutes', {m: 45})}`},
    {value: miliseconds(2), label: `${i18n('class_register_page.hour', {h: 2})}`},
  ]

  useEffect(() => {
    getClassCategoryList();
  }, []);
  
  return (
    <div className='class-form-admin box'>
      <TextInput
        label={i18n('label.class_name')}
        value={form?.name}
        inputProps={{
          onChange: (e) => handlerChanges(e.target.value, 'name')
        }}
        withHangingLabel
        errorMessage={errors?.name && i18n(`validation.${errors?.name}`)}
      />
      <ListDropdown
        i18n={i18n}
        label={i18n('label.type')}
        labelDrawer={i18n('label.select', { label: i18n('label.type')})}
        value={form?.type} 
        onChange={val => handlerChanges(val, 'type')}  
        options={class_type_options}
        valueKey="value"
        labelKey='name'
        withHangingLabel
        errorMessage={errors?.type && i18n(`validation.${errors?.type}`)}
      />
      <ListDropdown
        i18n={i18n}
        label={i18n('label.class_category')}
        labelDrawer={i18n('label.select', { label: i18n('label.class_category')})}
        value={form?.class} 
        onChange={val => handlerChanges(val, 'class')}  
        options={categoryClass}
        valueKey="classCategoryId"
        labelKey='classCategory'
        withHangingLabel
        errorMessage={errors?.class && i18n(`validation.${errors?.class}`)}
      />
      <TextInput
        label={i18n('label.capacity')}
        value={form?.capacity}
        inputProps={{
          type: "number",
          onChange: (e) => handlerChanges(e.target.value, 'capacity')
        }}
        withHangingLabel
        errorMessage={errors?.capacity && i18n(`validation.${errors?.capacity}`)}
      />
      <TextInput
        label={i18n('label.location')}
        value={form?.location}
        inputProps={{
          onChange: (e) => handlerChanges(e.target.value, 'location')
        }}
        withHangingLabel
        errorMessage={errors?.location && i18n(`validation.${errors?.location}`)}
      />
      <TextInput
        label={i18n('label.location_url')}
        value={form?.location_url}
        inputProps={{
          onChange: (e) => handlerChanges(e.target.value, 'location_url')
        }}
        withHangingLabel
        errorMessage={errors?.location_url && i18n(`validation.${errors?.location_url}`)}
      />
      <ListDropdown
        i18n={i18n}
        label={i18n('label.duration')}
        labelDrawer={i18n('label.select', { label: i18n('label.duration')})}
        value={form?.duration} 
        onChange={val => handlerChanges(val, 'duration')}  
        options={duration_options}
        valueKey="value"
        labelKey='label'
        withHangingLabel
        errorMessage={errors?.duration && i18n(`validation.${errors?.duration}`)}
      />
      <TextInput
        label={i18n('label.desc_class')}
        value={form?.desc_class}
        inputProps={{
          onChange: (e) => handlerChanges(e.target.value, 'desc_class')
        }}
        inputMode='textarea'
        withHangingLabel
        errorMessage={errors?.desc_class && i18n(`validation.${errors?.desc_class}`)}
      />
    </div>
  );
};

FormClass.propTypes = {
  setLang: PropTypes.func,
  history: PropTypes.object,
  i18n: PropTypes.func,
  getClassCategoryList: PropTypes.func,
  getClassCategoryListState: PropTypes.object,
};

const mapStateToProps = (state) => ({
  getClassCategoryListState: {
    status: state.getClassCategoryListReducer.status,
    error: state.getClassCategoryListReducer.error,
    data: state.getClassCategoryListReducer.data
  },
});

export default compose(connect(mapStateToProps, {
  getClassCategoryList
}))(FormClass);
