import React, { useEffect } from 'react';
import { useLocation, useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { compose } from 'redux';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';
import SnackbarProvider from '../../SnackbarProvider';

import { getAccountInfo } from '../../../routes/Account/action';

const DefaultLayoutWithoutFooter = ({
  route, 
  i18n,
  accountState,
  getAccountInfo
}) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const { data: { role } } = accountState;
  const isAdminPage = pathname.includes('admin');

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    
    if (!accessToken) {
      history.push('/login');
    } else if (!role) {
      getAccountInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (role) {
      if (role === 'ADMIN') {
        if (!isAdminPage) {
          history.push('/admin')
        }
      } else {
        history.push('/')
      }
    }
  }, [role])
  
  useEffect(() => {
    window && window.scrollTo(0, 0)
  }, [pathname])

  return (
    <SnackbarProvider>
      <div className='layout-wrap' id="guest">
        <main style={{ minHeight: '100vh'}}>{renderRoutes(route.routes, { i18n, accountState })}</main>
      </div>
    </SnackbarProvider>
  )
}

DefaultLayoutWithoutFooter.propTypes = {
  route: PropTypes.object,
  i18n: PropTypes.func,
  accountState: PropTypes.object,
  getAccountInfo: PropTypes.func,
};

const mapStateToProps = (state) => ({
  i18n: state.langReducer.i18n,
  accountState: {
    status: state.getAccountInfoReducer.status,
    error: state.getAccountInfoReducer.error,
    data: state.getAccountInfoReducer.data
  },
});

export default compose(connect(mapStateToProps, { 
  getAccountInfo,
 }))(DefaultLayoutWithoutFooter);
