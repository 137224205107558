import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';

import Header from '../../components/Header';
import SearchBar from '../components/SearchBar';
import Button from '../../components/Button';
import Loading from '../../components/Loading';

import { getClassList } from './action';

import './styles.scss';

const Class = ({ 
  i18n, 
  classListState,
  getClassList,
}) => {
  const history = useHistory();
  const [ loadingSearch, setLoadingSearch ] = useState(false);
  
  const classList = classListState?.data || [];

  const handlerSearchSubmit = (searchName) => {
    getClassList({
      searchName
    })
    setLoadingSearch(true)
  }

  useEffect(() => {
    setLoadingSearch(true)
    getClassList();
  }, []);

  useEffect(() => {
    if (loadingSearch && classListState.status === 'resolve') {
      setLoadingSearch(false)
    }
  }, [loadingSearch, classListState.status])
  
  return (<>
    <Header label={i18n('label.class_regis')} onBackClick={() => history.push('/admin')}/>
    {(loadingSearch || classListState?.status === 'pending') && <Loading/>}
    <div className='class-admin'>
      <SearchBar 
        label={i18n('label.search_class')}
        inputType={'text'}
        placeholder={i18n('class_admin.placeholder_search')}
        onSubmit={handlerSearchSubmit}
      />
      <div className="class-list-container">
        <div className="top">
          <div className="label">{i18n('label.class_regis')}</div>
        </div>
        <div className="class-list">
          {classList.map((classItem, idx) => !classItem?.isDeleted && (
            <div className="class-wrap" key={idx} onClick={() => history.push(`/admin/class/edit/${classItem?.id}`)}>
              {classItem.name}
            </div>
          ))}
        </div>
      </div>
    </div>
    <footer className='member-footer'>
      <Button color="pink" onClick={() => history.push('/admin/class/create')}><span className="add-icon">+</span>{i18n("cta.add_class")}</Button>
    </footer>
  </>);
};

Class.propTypes = {
  setLang: PropTypes.func,
  history: PropTypes.object,
  i18n: PropTypes.func,
  classListState: PropTypes.array,
  getClassList: PropTypes.func
};

const mapStateToProps = (state) => ({
  lang: state.langReducer.lang,
  classListState: {
    status: state.getClassListReducer.status,
    error: state.getClassListReducer.error,
    data: state.getClassListReducer.data
  }
});

export default compose(connect(mapStateToProps, {
  getClassList
}))(Class);
