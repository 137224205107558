import React, {useState, useEffect} from 'react';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import InfiniteScroll from "react-infinite-scroll-component";

import Header from '../../components/Header';
import HeaderDetail from '../../components/Header/Detail';
import Button from '../../components/Button';
import Card from './components/Card';
import FilterDrawer from '../../components/Drawer/SortFilter'
import Loading from '../../components/Loading';
import Modal from '../../components/Modal';
import Icon from '../../components/Icon';
import useSnackbar from '../../components/Snackbar/functions';

import { getMyBookingList, putBookingCancel, resetMyBookingList } from './action';
import './styles.scss';

const ClassSchedule = ({
  i18n,
  getMyBookingList,
  getMyBookingListState,
  putBookingCancelState,
  putBookingCancel,
  resetMyBookingList
}) => {
  const history = useHistory();
  const { showSnackbar } = useSnackbar();
  
  const [ loading, setLoading ] = useState(false);
  const [ showModal, setShowModal ] = useState(false);
  const [ cancelClassDetail, setCancelClassDetail ] = useState({});
  const [ bookClassList, setBookClassList ] = useState([]);
  const [ bookClassPagination, setBookClassPagination ] = useState();

  const { status, data, pagination } = getMyBookingListState;
  const { page = 0, totalPage = 0 } = pagination;
  console.log(getMyBookingListState, '+getMyBookingListState')
  // MOCK FILTER LIST
  // todo: get category class list & instructur list
  const filterList = [
    {
      key: 'sort',
      type: 'radio', 
      drawerTop: 350, 
      list: [
        {label: 'Jam paling awal', value: 'asc_hour'},
        {label: 'Jam paling akhir', value:'dsc_hour'},
        {label: 'Tanggal paling awal', value:'asc_date'},
        {label: 'Tanggal paling akhir', value:'dsc_date'},
      ]
    },
    { 
      key: 'filter', 
      type: 'checkbox', 
      drawerTop: 200, 
      list: [
        {
          label: 'Kategori', 
          value: [
            {label: 'Yoga', value: 'yoga'},
            {label: 'Aerobik', value: 'aero'}
          ]
        },
        {
          label: 'Instruktur', 
          value: [
            {label: 'Asem', value: 1},
            {label: 'Dadang', value: 2}
          ]
        },
      ]
    }
  ]

  const filterSubmit = (filterParams) => {
    // todo: call endpoint
    console.log(filterParams, "filterParams")
  }

  const submitCancelClass = () => {
    setLoading(true)
    setShowModal(false)
    putBookingCancel(cancelClassDetail?.bookingId)
  }

  const showSnackbarBy = (message, type) => {
    showSnackbar({
      message: message,
      type: type
    })
  }

  const loadData = (params) => {
    getMyBookingList({
      ...params,
      size: 10
    })
  }

  useEffect(() => {
    loadData()
    setBookClassList([])
    setBookClassPagination()

    return () => {
      resetMyBookingList();
    }
  }, []);

  useEffect(() => {
    if(status === 'pending') {
      setLoading(true)
    } else {
      setLoading(false)
      
      const tmpData = data ? data : [];
      setBookClassList([
        ...bookClassList,
        ...tmpData
      ])
      setBookClassPagination(pagination)
    }
  }, [status]);

  useEffect(() => {
    if(loading) {
      const { status, error } = putBookingCancelState;

      if (status === 'resolve') {
        showSnackbarBy(i18n('schedule_page.success_cancel'), 'success')
        setLoading(false)
        loadData({ page: 1 })
        setBookClassList([])
        setBookClassPagination()
      } else if (status === 'rejected') {
        const errMessage = error?.response?.data?.error
        showSnackbarBy(errMessage, 'error')
        setLoading(false)
      }
    }

  }, [putBookingCancelState?.status])
  
  return (<>
    {((loading && !bookClassPagination?.page) || status === 'pending') && <Loading/>}
    <Header label={i18n('label.my_schedule')}
      onBackClick={() => history.push('/')}
    />
    <HeaderDetail 
      title={i18n('schedule_page.title')}
      desc={i18n('schedule_page.desc')}
      simple
    />
    <section className='schedule-container'>
      <div className="top">
        <Button 
          color="purple" 
          classname='history' 
          flat 
          onClick={() => history.push('/schedule/history')}
        >
          {i18n('cta.history')}
          <Icon icon="arrow right purple"/>
        </Button>
      </div>
      {(bookClassList && bookClassList.length > 0) ? 
      <div className="schedule-list">
         <InfiniteScroll
          dataLength={bookClassList ? bookClassList.length : 0}
          next={() => loadData({ page: page + 1 })}
          hasMore={page!==totalPage}
          loader={<h4>Loading...</h4>}
        >
        {bookClassList.map((bookData, idx) => (
          <Card 
            key={idx}
            bookData={bookData}
            i18n={i18n}
            onCancelClassSubmit={detail => {
              setCancelClassDetail(detail)
              setShowModal(true)
            }}
          />
        ))}
        </InfiniteScroll>
      </div>
      :
      <div className='empty-class'>
        <Icon icon="empty"/><br/>
        {i18n('schedule_page.empty')}
        <Button color="purple" onClick={() => history.push('/class')}>
          {i18n('cta.see_class_avail')}
        </Button>
      </div> 
      }
    </section>
    {/* {bookClassList && bookClassList.length > 0 && 
    <FilterDrawer 
      i18n={i18n}
      filterList={filterList}
      onSubmit={filterSubmit}
    />
    } */}

    <Modal
      onClose={() => setShowModal(false)}
      isOpen={showModal}
      hasCloseIcon
      buttonSecondaryActionHandler={() => setShowModal(false)}
      buttonActionHandler={submitCancelClass}
      buttonText={i18n('cta.yes_sure')}
      buttonSecondaryText={i18n('cta.cancel')}
      title={i18n('confirm_modal.title')}
      portalId="class-confirm"
    >
      <div className="title-confirm">{i18n('confirm_modal.title_cancel_class')}</div>
      <div className="detail-class-confirm">
        <div className="title">{cancelClassDetail?.className}</div>
        <div className="detail">
          <div className="date">{moment(cancelClassDetail?.date).format('dddd, DD MMMM YYYY')}</div>
          <div className="time">{cancelClassDetail?.hour} {cancelClassDetail?.duration}</div>
        </div>
        <div className="instructor-name">{i18n('label.instructor')}: {cancelClassDetail?.instructor}</div>
      </div>
    </Modal>
  </>);
};

ClassSchedule.propTypes = {
  getMyBookingList: PropTypes.func,
  getMyBookingListState: PropTypes.object,
  putBookingCancelState: PropTypes.object,
  putBookingCancel: PropTypes.func,
};

const mapStateToProps = (state) => ({
  getMyBookingListState: state.getMyBookingListReducer,
  putBookingCancelState: {
    status: state.putBookingCancelReducer.status,
    error: state.putBookingCancelReducer.error,
    data: state.putBookingCancelReducer.data
  }
});

export default compose(connect(mapStateToProps, { 
  getMyBookingList, 
  putBookingCancel,
  resetMyBookingList
}))(ClassSchedule);
