import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';

import Header from '../../components/Header';
import HeaderDetail from '../../components/Header/Detail';
import Icon from '../../components/Icon';

import { getAccountInfo } from '../Account/action';

import './styles.scss';

const Help = ({
  i18n,
  getAccountInfo,
  accountState
}) => {
  const history = useHistory();
  const { data: { name  } } = accountState;

  const categoryList = [
    { keyLang: 'booking_class', icon: 'calendar', link: 'booking'},
    { keyLang: 'payment', icon: 'payment', link: 'payment'},
    { keyLang: 'cancellation', icon: 'calendar', link: 'cancellation'},
    { keyLang: 'account', icon: 'account', link: 'account'},
    { keyLang: 'tnc', icon: 'document', link: 'term-and-condition'},
    { keyLang: 'contact_us', icon: 'help', link: 'contact-us'}
  ]

  useEffect(() => {
    getAccountInfo()
  }, [])

  return (<>
    <Header 
      label={i18n('label.help')} 
      onBackClick={() => history.push('/')}
    />
    <HeaderDetail title={i18n('help_page.title', { name })} simple/>
    <section className='help-container'>
      <main>
        <div className="title">{i18n('help_page.choose_category')}</div>
        <div className="category-list">
          {categoryList.map(({ keyLang, icon, link }, idx) => (
            <div className="menu">
              <div 
                key={idx}
                className="icon-item" 
                onClick={() => history.push(`/help/${link}`)}
              >
                <Icon icon={icon} className="white"/>
              </div>
              <span className='label'>{i18n(`label.${keyLang}`)}</span>
            </div>
          ))}
        </div>
      </main>
    </section>
  </>);
};

Help.propTypes = {
  getAccountInfo: PropTypes.func,
  accountState: PropTypes.object
};

const mapStateToProps = (state) => ({
  accountState: {
    status: state.getAccountInfoReducer.status,
    error: state.getAccountInfoReducer.error,
    data: state.getAccountInfoReducer.data
  }
});

export default compose(connect(mapStateToProps, { getAccountInfo }))(Help);

