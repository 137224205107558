// import axios from 'axios';
import { apiCaller } from '../../helpers/apiCaller';

const getHomeInfoRequest = () => ({
  type: 'GET_HOME_INFO_REQUEST'
});

const getHomeInfoSuccess = (data) => ({
  type: 'GET_HOME_INFO_SUCCESS',
  payload: data
});

const getHomeInfoFailure = (error) => ({
  type: 'GET_HOME_INFO_FAILURE',
  payload: error
});

const getHomeInfo = () => {
  return (dispatch) => {
    dispatch(getHomeInfoRequest());
    return apiCaller('v1/home/info', 'get')
      .then((data) => {
        if (data?.response?.data?.status === 422) {
          return dispatch(getHomeInfoFailure(data));
        }

        return dispatch(getHomeInfoSuccess(data));
      })
      .catch((error) => dispatch(getHomeInfoFailure(error)));
  };
};

export { getHomeInfo };
