const defaultResponse = {
  status: 'idle',
  error: ''
};

const getMemberListReducer = (state = defaultResponse, action) => {
  switch (action.type) {
    case 'GET_MEMBER_LIST_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_MEMBER_LIST_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_MEMBER_LIST_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_VERIFY_PHONE':
      return {
        ...state,
        ...defaultResponse
      };
    default:
      return state;
  }
};

const getMemberDetailReducer = (state = defaultResponse, action) => {
  switch (action.type) {
    case 'GET_MEMBER_DETAIL_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_MEMBER_DETAIL_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_MEMBER_DETAIL_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_VERIFY_PHONE':
      return {
        ...state,
        ...defaultResponse
      };
    default:
      return state;
  }
};

const postMemberDetailReducer = (state = defaultResponse, action) => {
  switch (action.type) {
    case 'POST_MEMBER_DETAIL_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'POST_MEMBER_DETAIL_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'POST_MEMBER_DETAIL_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_POST_MEMBER_DETAIL':
      return {
        ...state,
        ...defaultResponse,
        data: {}
      };
    default:
      return state;
  }
};

const putKuotaMemberReducer = (state = defaultResponse, action) => {
  switch (action.type) {
    case 'PUT_KUOTA_MEMBER_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'PUT_KUOTA_MEMBER_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'PUT_KUOTA_MEMBER_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_PUT_KUOTA_MEMBER':
      return {
        ...state,
        ...defaultResponse,
        data: {}
      };
    default:
      return state;
  }
};

const deleteMemberReducer = (state = defaultResponse, action) => {
  switch (action.type) {
    case 'DELETE_MEMBER_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'DELETE_MEMBER_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'DELETE_MEMBER_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_DELETE_MEMBER':
      return {
        ...state,
        ...defaultResponse,
        data: {}
      };
    default:
      return state;
  }
};

const searchParamsLeavePageReducer  = (state = { data: undefined }, action) => {
  switch (action.type) {
    case 'SEARCH_PARAMS_LEAVE_PAGE':
      return {
        data: action.payload
      };
    default:
      return state;
  }
};

export {
  getMemberListReducer,
  getMemberDetailReducer,
  postMemberDetailReducer,
  putKuotaMemberReducer,
  deleteMemberReducer,
  searchParamsLeavePageReducer,
};
