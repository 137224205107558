import React from 'react';
import { useHistory } from "react-router-dom";
import moment from 'moment';
import Parser from 'html-react-parser';
import cn from 'classnames';

import Card from '../../../../components/Card/Collapse';
import Button from '../../../../components/Button';
import Column from '../../../../components/Column';

import { convertDuration } from '../../../ClassDetail';

import './styles.scss';

const ScheduleCard = ({
  i18n,
  bookData,
  color = 'purple',
  onCancelClassSubmit
}) => {

  return (<div className='schedule-card-container'>
    <Card 
      className="schedule-card"
      color={color}
      summaryChild={<SummaryClass bookData={bookData}/>}
      detailChild={<DetailClass bookData={bookData} i18n={i18n} onCancelClassSubmit={onCancelClassSubmit}/>}
    />
    <div className={cn("status", bookData?.status)}>{i18n(`label.${bookData?.status}`)}</div>
  </div>);
};

const SummaryClass = ({
  bookData
}) => {
  return (
    <div className="summary-wrap">
      <div className="time">{bookData?.hour}</div>
      <div className="detail-class">
        <div className="name">{bookData?.className}</div>
        <div className="schedule-time">{moment(bookData?.date).format('dddd, DD MMMM YYYY')}</div>
      </div>
    </div>
  )
}
const DetailClass = ({
  bookData,
  i18n,
  onCancelClassSubmit
}) => {
  const history = useHistory();
  
  const dateTimeFormat = 'YYYY-MM-DD HH:mm';
  const cancel_date = moment(bookData?.date).subtract(2, 'days').format('YYYY-MM-DD');
  const cancel_date_time = moment(`${cancel_date} 11:00`).format(dateTimeFormat);

  const today = moment().format(dateTimeFormat);
  const disable_date = moment(cancel_date_time, dateTimeFormat).isBefore(today) ;

  return (
    <div className="detail-wrap">
      <div className="title-date">
        <div className="title">{bookData?.className}</div>
        <div className="date">{moment(bookData?.date).format('dddd, DD MMMM YYYY')} | {bookData?.hour}</div>
      </div>
      <div className="detail-class">
        <Column label={i18n('label.desc')} value={bookData?.description}/>
        <Column label={i18n('label.instructor')} value={bookData?.instructor}/>
        <Column label={i18n('label.duration')} value={convertDuration(bookData?.duration, i18n)}/>
        {bookData?.status !== 'done' && 
        <span className="cancel-info">
          {Parser(i18n('schedule_page.cancel_info', { date: moment(cancel_date).format('dddd, DD MMMM YYYY')}))}
        </span>
        }
      </div>
      {bookData?.status !== 'done' && 
      <div className="btn-wrap">
        {!disable_date && !(bookData?.status === 'REFUNDED' || bookData?.status === 'CANCELLED') && 
        <Button color="pink square" onClick={() => onCancelClassSubmit(bookData)}>{i18n('cta.cancel_class')}</Button>
        }
        {!disable_date && 
        <Button color="dark-grey square" onClick={() => history.push('/help')}>{i18n('cta.help')}</Button>
        } 
      </div>
      }
    </div>
  )
}

export default ScheduleCard;
