import React from 'react';
import Parser from 'html-react-parser';

import Header from '../../../components/Header';
import HeaderDetail from '../../../components/Header/Detail';

import './styles.scss';

const PaymentHelp = ({
  i18n
}) => {

  const tncList = [
    {
      type: 'text', 
      value: "Topup Kuota hanya dapat dilakukan melalui Whatsapp <a href='https://wa.me/6281805018899?text=Halo%20Danta%20Yoga%2C%20Saya%20(Nama%20Anda)%20butuh%20bantuan' target='_blank'> +62 818-0501-8899</a>"
    },
    {
      type: 'text',
      value: "Kuota dapat digunakan di kelas manapun"
    }
  ];

  return (<>
    <Header label={i18n('label.tnc')}/>
    <HeaderDetail title={i18n('payment_help_page.title')} desc={i18n('payment_help_page.desc')}/>
    <section className='payment-help-container'>
      {/* <HeaderHelp i18n={i18n}/> */}
      <main>
        <div className="body-wrap">
          <div className="top">
            <div className="title">{i18n('payment_help_page.header1')}</div>
          </div>
          <div className="content-wrap">
              <ol className="tnc-list">
                {tncList.map((tnc, idx) => (
                  <li className="item" key={idx}>
                    {Parser(tnc.value)}
                    {tnc.subvalue && 
                      <ul>
                        {tnc.subvalue.map((tncSubList, idxSub) => (
                          <li key={idxSub}>{tncSubList}</li>
                        ))}
                      </ul>
                    }
                  </li>
                ))}
              </ol>
          </div>
        </div>
      </main>
    </section>
  </>);
};

export default PaymentHelp;
