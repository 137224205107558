import axios from 'axios';
import moment from 'moment-timezone';

const baseUrl = process.env.REACT_APP_BACKEND_URI;

const exportMemberList = (setLoading) => {
  const accessToken = localStorage.getItem('accessToken');
  const authToken = {
    'Authorization': `Bearer ${accessToken}`,
  };

  return axios({
    method: 'get',
    url: `${baseUrl}/v1/account/export`, 
    ...(accessToken ? { 
      headers: {
        ...authToken,
      } 
    } : {}),
    responseType: "blob",
  }).then((response) => (
    new Blob([response.data], {
      type: response.headers["content-type"],
    })
  )).then(blob => URL.createObjectURL(blob))
  .then(url => {
    const linkAction = document.createElement("a");
  
    linkAction.href = url;
    linkAction.download = `Daftar Anggota (${moment().format('YYYY-MM-DD HH:mm')})`;
    linkAction.click();
    linkAction.remove();
    setLoading(false)
  }).catch((err) => {
    setLoading(false)
    return err;
  });
}

export {
  exportMemberList
}
