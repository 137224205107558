import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import Header from '../../components/Header';
import Button from '../../components/Button';
import Loading from '../../components/Loading';
import useSnackbar from '../../components/Snackbar/functions';
import CalendarClass from './CalendarClass';

import { getAvailList, setSelectedDateAvail } from './action';

import './styles.scss';

const ScheduleClassAdmin = ({ 
  i18n, 
  getAvailListState,
  getAvailList,
  selectedDateAvailState,
  setSelectedDateAvail,
}) => {
  const history = useHistory();
  const [ loading, setLoading ] = useState(false);
  const { showSnackbar } = useSnackbar();
  const selectedDateAvail = selectedDateAvailState?.date || new Date();
  const [ selectedDate, setselectedDate ] = useState(selectedDateAvail);

  const classList = getAvailListState?.data || [];

  useEffect(() => {
    if (selectedDate) {
      setLoading(true)
      getAvailList({
        Size: 100,
        SearchDate: moment(selectedDate).format('YYYY-MM-DD')
      })
      setSelectedDateAvail(selectedDate)
    }
  }, [selectedDate]) 
  
  useEffect(() => {
    if (getAvailListState.status === 'resolve') {
      setLoading(false)
    } else if (getAvailListState.status === 'rejected') {
      showSnackbar({
        message: "Terjadi kesalahan. Mohon refresh halaman",
        type: 'error'
      })
      setLoading(false)
    }
  }, [getAvailListState.status])
  
  const dateTimeFormat = 'YYYY-MM-DD';
  const today = moment().format(dateTimeFormat);
  const disable = moment(selectedDateAvail, dateTimeFormat).isBefore(today);

  return (<>
    {(loading || getAvailListState.status === 'pending') && <Loading/>}
    <Header label={i18n('label.class_schedule')} onBackClick={() => {
      setSelectedDateAvail()
      history.push('/admin')
    }}/>
    <div className='schedule-class-admin'>
      <CalendarClass
        i18n={i18n}
        selectedDate={selectedDate}
        setselectedDate={setselectedDate}
      />
      {selectedDate && 
      <div className="calendar-event box">
        <div className="label">{moment(selectedDate).format('DD MMM YYYY')}</div>
        <div className="event-list">
          {classList.map((event, idx) => (
            <div className="event-item" key={idx} onClick={() => history.push(`/admin/schedule/edit/${event?.id}`)}>
              <div className="date">{moment(event?.date).format('HH:mm')}</div>
              <div className="name">{event?.className}</div>
              <div className="edit-btn">{i18n('cta.edit')}</div>
            </div>
          ))}
        </div>
      </div>
      }
    </div>

    {!disable &&
    <Button color="pink" classname='add-schedule' onClick={() => history.push(`/admin/schedule/create?startDate=${moment(selectedDate).format('YYYY-MM-DD')}`)}>+</Button>
    }
  </>);
};

ScheduleClassAdmin.propTypes = {
  setLang: PropTypes.func,
  history: PropTypes.object,
  i18n: PropTypes.func,
  getAvailListState: PropTypes.object,
  getAvailList: PropTypes.func,
  selectedDateAvailState: PropTypes.object,
};

const mapStateToProps = (state) => ({
  lang: state.langReducer.lang,
  getAvailListState: {
    status: state.getAvailListReducer.status,
    error: state.getAvailListReducer.error,
    data: state.getAvailListReducer.data
  },
  selectedDateAvailState: {
    date: state.setSelectedDateAvailReducer.date
  }
});

export default compose(connect(mapStateToProps, {
  getAvailList,
  setSelectedDateAvail
}))(ScheduleClassAdmin);
