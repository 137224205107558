import React, { useState } from 'react';
import moment from 'moment';

import TextInput from '../../Input/TextInput';
import Drawer from '../../Drawer';
import Datepicker from './Datepicker';

import './styles.scss';

const DrawerDatepicker = ({
  i18n,
  label,
  value,
  onChange,
  errorMessage,
  labelDrawer,
  iconInput,
  withHangingLabel = true,
  placeholder,
  minDate,
  maxDate,
  disabled = false,
  formatDateValue = 'DD MMM YYYY',
  withoutLabelEmpty
}) => {
  const [ showDrawer, setShowDrawer ] = useState(false);

  const handlerCalendarDrawer = () => {
    if (showDrawer) {
      document.body.classList.remove("stop-scrolling");
    } else {
      document.body.classList.add("stop-scrolling");
    }

    setShowDrawer(!showDrawer)
  }

  const handlerChanges = date => {
    onChange(date)
    handlerCalendarDrawer()
  }

  return (
    <div className='datepicker-drawer-container'>
      <TextInput
        label={label}
        placeholder={placeholder}
        value={value ? moment(value).format(formatDateValue) : (withoutLabelEmpty ? null : (label || placeholder))}
        inputProps={{
          type:'button',
          onClick: disabled ? null : handlerCalendarDrawer
        }}
        withHangingLabel={withHangingLabel}
        errorMessage={errorMessage}
        iconLeft={iconInput}
        iconRight={disabled ? '':"arrow down white"}
      />

      <Drawer
        showDrawer={showDrawer}
        maxHeight={550}
        threshold={0.5}
        onCloseDrawer={handlerCalendarDrawer}
      >
        {onCloseDrawer => {
          return (
            <>
              <Drawer.Header
                title={labelDrawer}
                onCloseDrawer={onCloseDrawer}
              />
              <Drawer.Body className="list-drawer">  
                <Datepicker
                  selected={value}
                  onChange={(date) => handlerChanges(date)}
                  inline
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  minDate={minDate}
                  maxDate={maxDate}
                />
              </Drawer.Body>
            </>
          );
        }}
      </Drawer>

    </div>
    
  );
};

export default DrawerDatepicker;
