const defaultState = {
  data: [],
  status: 'idle',
  error: '',
  pagination: {}
};

const getMyBookingListReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'GET_MY_BOOKING_LIST_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_MY_BOOKING_LIST_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_MY_BOOKING_LIST_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data,
        pagination: action.payload.data.pagination
      };
    case 'RESET_MY_BOOKING_LIST': 
      return {
        ...state,
        ...defaultState
      };
    default:
      return state;
  }
};

const getBookingHistoryReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'GET_BOOKING_HISTORY_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_BOOKING_HISTORY_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_BOOKING_HISTORY_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data,
        pagination: action.payload.data.pagination
      };
    case 'RESET_BOOKING_HISTORY': 
      return {
        ...state,
        ...defaultState
      };
    default:
      return state;
  }
};

const putBookingCancelReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'PUT_BOOKING_CANCEL_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'PUT_BOOKING_CANCEL_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'PUT_BOOKING_CANCEL_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_PUT_BOOKING_CANCEL': 
      return {
        ...state,
        ...defaultState
      };
    default:
      return state;
  }
};

export { 
  getMyBookingListReducer, 
  getBookingHistoryReducer, 
  putBookingCancelReducer 
};
