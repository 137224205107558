import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams, useLocation } from 'react-router';

import Icon from '../Icon';
import MenuDrawer from './MenuDrawer';

import './styles.scss';

const Header = ({
  label,
  withoutBackButton = false,
  onBackClick
}) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const [ showDrawer, setShowDrawer ] = useState(false)

  const isAdmin = pathname.includes('admin');

  return (
    <header className='header-wrap'>
      <div className="top">
        {!withoutBackButton && 
          <Icon 
            icon="arrow" 
            placement='left' 
            onClick={() => onBackClick ? onBackClick() : history.goBack()}
          />
        }
        <span>{label}</span>
        <Icon 
          icon="hamburger" 
          onClick={() => setShowDrawer(true)}
        />
      </div>
      <MenuDrawer
        showDrawer={showDrawer} 
        setShowDrawer={setShowDrawer}
        isAdmin={isAdmin}
      />
    </header>
  )
}

Header.propTypes = {
  route: PropTypes.object,
  i18n: PropTypes.func
};

export default Header;
