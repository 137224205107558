const defaultResponse = {
  status: 'idle',
  error: ''
};

const getAvailListReducer = (state = defaultResponse, action) => {
  switch (action.type) {
    case 'GET_AVAIL_LIST_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_AVAIL_LIST_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_AVAIL_LIST_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    default:
      return state;
  }
};

const getInstructurListReducer = (state = defaultResponse, action) => {
  switch (action.type) {
    case 'GET_INSTRUCTUR_LIST_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_INSTRUCTUR_LIST_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_INSTRUCTUR_LIST_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    default:
      return state;
  }
};

const putAvailReducer = (state = defaultResponse, action) => {
  switch (action.type) {
    case 'PUT_AVAIL_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'PUT_AVAIL_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'PUT_AVAIL_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_PUT_AVAIL':
      return {
        ...state,
        ...defaultResponse
      };
    default:
      return state;
  }
};

const postAvailReducer = (state = defaultResponse, action) => {
  switch (action.type) {
    case 'POST_AVAIL_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'POST_AVAIL_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'POST_AVAIL_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_POST_AVAIL':
      return {
        ...defaultResponse
      };
    default:
      return state;
  }
};

const getMemberAvailListReducer = (state = defaultResponse, action) => {
  switch (action.type) {
    case 'GET_MEMBER_AVAIL_LIST_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_MEMBER_AVAIL_LIST_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_MEMBER_AVAIL_LIST_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_MEMBER_AVAIL_LIST': 
      return {
        ...defaultResponse
      };
    default:
      return state;
  }
};

const deleteMemberAvailReducer = (state = defaultResponse, action) => {
  switch (action.type) {
    case 'DELETE_MEMBER_AVAIL_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'DELETE_MEMBER_AVAIL_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'DELETE_MEMBER_AVAIL_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_DELETE_MEMBER_AVAIL': 
      return {
        ...state,
        ...defaultResponse
      };
    default:
      return state;
  }
};

const deleteAvailReducer = (state = defaultResponse, action) => {
  switch (action.type) {
    case 'DELETE_AVAIL_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'DELETE_AVAIL_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'DELETE_AVAIL_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_DELETE_AVAIL': 
      return {
        ...state,
        ...defaultResponse
      };
    default:
      return state;
  }
};

const setSelectedDateAvailReducer = (state = defaultResponse, action) => {
  switch (action.type) {
    case 'SELECTED_DATE_AVAIL':
      return {
        date: action.payload
      };
    default:
      return state;
  }
};

export {
  getAvailListReducer,
  getInstructurListReducer,
  putAvailReducer,
  postAvailReducer,
  getMemberAvailListReducer,
  deleteMemberAvailReducer,
  deleteAvailReducer,
  setSelectedDateAvailReducer,
};
