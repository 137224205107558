import React from 'react';
import Parser from 'html-react-parser';

import Header from '../../../components/Header';
import HeaderDetail from '../../../components/Header/Detail';

import Icon from '../../../components/Icon';
import map from '../../../assets/img/map.png';

import './styles.scss';

const ContactUs = ({
  i18n
}) => {

  const gmaps = "https://www.google.com/maps/place/Danta+Yoga+Studio/@-6.8858316,107.5786127,15.19z/data=!4m5!3m4!1s0x2e68e7b7fab5f94b:0x33d1b13593f721ca!8m2!3d-6.8823179!4d107.5812759";

  const data = {
    // phone: "<a href='tel:+6281805018899'>+62 818-0501-8899</a>",
    wa: "<a href='https://wa.me/6281805018899?text=Halo%20Danta%20Yoga%2C%20Saya%20(Nama%20Anda)%20butuh%20bantuan' target='_blank'>+62 818-0501-8899</a>",
    email: "dantayoga99@gmail.com",
    // fb: "danta.studio",
    ig: "<a href='https://www.instagram.com/dantayogabandung/?hl=en' target='_blank'>dantayogabandung</a>",
    address: "Jl. Prof. drg. Soeria Soemantri MPH No 99, Sukawarna, Kec. Sukajadi, Kota Bandung, Jawa Barat 40164"
  }

  return (<>
    <Header label={i18n('label.help')}/>
    <HeaderDetail title={i18n('contact_us_page.title')} desc={i18n('contact_us_page.desc')}/>
    <section className='contact-us-container'>
      {/* <HeaderHelp i18n={i18n}/> */}
      <main>
        <div className="contact-list">
          {Object.keys(data).map((contactItem, idx) => (
            contactItem !== 'address' && (
              <div className="item" key={idx}>
                <Icon icon={contactItem} className="pink"/>
                <span>{Parser(data[contactItem])}</span>
              </div>
            )
          ))}
        </div>
        <div className="location-wrap">
          <div className="top">
            <div className="title">{i18n('contact_us_page.location')}</div>
            <div className="desc">{i18n('contact_us_page.desc_loc')}</div>
          </div>
          <div className="address-wrap">
            <Icon icon="address-search"/>
            <span>{data?.address}</span>
          </div>
          <a href={gmaps} target="_blank" rel="noreferrer">
            <img className="address-map" src={map} alt="" />
          </a>
        </div>
      </main>
    </section>
  </>);
};

export default ContactUs;
