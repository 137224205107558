import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import * as yup from 'yup';

import { errorScroll } from '../../../helpers/functions';
import { convertArrayToObject } from '../../../helpers';

import TextInput from '../../../components/Input/TextInput';
import Button from '../../../components/Button';
import Icon from '../../../components/Icon';
import useSnackbar from '../../../components/Snackbar/functions';
import Loading from '../../../components/Loading';
import NumberVerification from '../../Register/components/NumberVerification';

import { postResetPassword, resetDataForgotPassword } from '../action';

import './styles.scss';

const ForgotPassword = ({
  i18n,
  postResetPassword,
  resetDataForgotPassword,
  forgotPassowrdState
}) => {
  const history = useHistory();
  const [ form, setForm ] = useState();
  const [ step, setStep ] = useState(1) //1. phone, 2 otp, 3 profile
  const [ isOTP, setIsOTP ] = useState(true);
  const [ errors, setErrors ] = useState();
  const [ loading, setLoading ] = useState(false);
  const { showSnackbar } = useSnackbar();

  const cta = {
    1: 'reset',
    2: 'verifikasi',
    3: 'reset'
  }

  const handleSubmit = (e) => { e.preventDefault();
    const params = { ...form }
    const otpRegExp = /^(\d*)$/

    let validatinsSchema = {
      phone: yup
        .string()
        .test(
          'len',
          i18n('validation.min_phone', { min: 8 }),
          (val) => val && val.toString().length > 8
        )
        .required('required'),
    }

    if (step === 2) {
      validatinsSchema = {
        ...validatinsSchema,
        otp_code: yup
          .string()
          .test(
            'len',
            i18n('validation.min_length', { min: 4 }),
            (val) => val && val.toString().length >= 4
          )
          .matches(otpRegExp, 'number')
          .required('required'),
      }
    }

    if (step === 3) {
      validatinsSchema = {
        newPassword: yup
        .string()
        .test(
          'len',
          i18n('validation.min_length', { min: 8 }),
          (val) => val && val.toString().length > 7
        )
        .required('required'),
        confirmPassword: yup.string()
        .required('required')
        .oneOf([yup.ref('newPassword'), null], i18n('validation.password_not_match')),
      }
    }

    if (!params.phone || params.phone.startsWith("0")) {
      setErrors({phone: 'Format nomor handphone salah (cth: +62 8XXX)'});
      errorScroll();
      return;
    }

    let schema = yup.object().shape({
      ...validatinsSchema
    });

    schema
      .validate(params, { abortEarly: false })
      .then(() => {
        const finalParams = {
          phone: `+62${params?.phone}`
        }
        setIsOTP(true)

        if (step === 2) {
          finalParams.resetOtp = params?.otp_code;
          setIsOTP(false)
        } else if (step === 3) {
          finalParams.resetOtp = params?.otp_code
          finalParams.newPassword = params?.newPassword
          setIsOTP(false)
        }

        resendOtp(finalParams)
      })
      .catch((err) => {
        setErrors(convertArrayToObject(err.inner, 'path', 'message'));
        errorScroll();
      });
  };

  const handlerChanges = (value, key, errorKey) => {
    setForm({ ...form, [key]: value });
    let errorMessage = { ...errors, [errorKey ? errorKey : key]: undefined };
    if (key === 'confirmPassword') {
      if(form?.newPassword !== value) {
        errorMessage = {...errors, [key]: 'password_not_match'}
      }
    }

    setErrors(errorMessage);
  };
  
  const resendOtp = (params) => {
    setLoading(true);
    postResetPassword(params, isOTP)
  }
  
  useEffect(() => {
    if (loading) {
      if (forgotPassowrdState?.status === 'resolve') {
        let snackbarProps = {
          message: i18n('login_page.success_reset'),
          type: 'success'
        }

        if (step === 1) {
          setStep(2)
          setIsOTP(false)
          snackbarProps.message = i18n('register_page.step_1.success')
        } else if (step === 2) {
          if (!isOTP) {
            setStep(3)
          }
          snackbarProps.message = i18n('register_page.step_2.success')
        } else if (step === 3) {
          history.push('/login');
        }

        resetDataForgotPassword()
        showSnackbar(snackbarProps)
        setLoading(false)
      } else if(forgotPassowrdState?.status === 'rejected'){
        const { code, message } = forgotPassowrdState?.error || {};
        let snackbarProps = {
          message: i18n('error_message.AN_ERROR'),
          type: 'error'
        }

        if (step === 1) {
          snackbarProps.message = i18n('register_page.step_1.failed')
        } else if (step === 2) {
          snackbarProps.message = i18n('register_page.step_2.failed')
        }

        showSnackbar({
          ...snackbarProps,
          message: `${snackbarProps.message} (${code}: ${message})`
        })
        setLoading(false)
        resetDataForgotPassword()
      } else if (forgotPassowrdState?.status === 'pending') {
        // do nothing
      } else {
        showSnackbar({
          message: `${JSON.stringify(forgotPassowrdState)}`,
          type: 'error'
        })
        setLoading(false)
      }
    }
  },[forgotPassowrdState?.status])

  return (
    <section className='forgot-pass-container'>
      {loading && <Loading/>}
      <Icon icon="arrow" placement='left' onClick={() => history.push('/login')}/>
      <div className='title'>{i18n('login_page.reset')}</div>
      <form onSubmit={handleSubmit}>
        {step === 1 &&
          <TextInput
            label={i18n('label.handphone')}
            type='text'
            value={form?.phone}
            inputProps={{
              onChange: (e) => handlerChanges(e.target.value, 'phone')
            }}
            withHangingLabel
            errorMessage={errors?.phone && i18n(`validation.${errors?.phone}`)}
            textLeft="+62"
            placeholder="contoh: 813XX"
            withPlaceholder
          />
        }

        {step === 2 && 
        <NumberVerification
          i18n={i18n}
          handlerChanges={val => handlerChanges(val, 'otp_code')}
          err={errors?.otp_code && i18n(`validation.${errors?.otp_code}`)}
          otpCode={form?.otp_code}
          resendOtp={resendOtp}
          phone={form?.phone}
          setIsOTP={setIsOTP}
        />
        }

        {step === 3 && (
          <>
          <TextInput
            label={i18n('label.new_password')}
            value={form?.newPassword}
            inputProps={{
              type: 'password',
              onChange: (e) => handlerChanges(e.target.value, 'newPassword')
            }}
            withHangingLabel
            errorMessage={errors?.newPassword && i18n(`validation.${errors?.newPassword}`)}
          />
          <TextInput
            label={i18n('label.re_password')}
            value={form?.confirmPassword}
            inputProps={{
              type: 'password',
              onChange: (e) => handlerChanges(e.target.value, 'confirmPassword')
            }}
            withHangingLabel
            errorMessage={errors?.confirmPassword && i18n(`validation.${errors?.confirmPassword}`)}
          />
          </>
        )}
        
        <Button color="purple" type="submit">{i18n(`cta.${cta[step]}`)}</Button>
      </form>
    </section>
  );
};

ForgotPassword.propTypes = {
  postResetPassword: PropTypes.func,
  resetDataForgotPassword: PropTypes.func,
  forgotPassowrdState: PropTypes.object
};


const mapStateToProps = (state) => ({
  forgotPassowrdState: {
    status: state.postResetPasswordReducer.status,
    error: state.postResetPasswordReducer.error,
    data: state.postResetPasswordReducer.data
  }
});

export default compose(
  connect(mapStateToProps, { 
    postResetPassword, 
    resetDataForgotPassword 
  }
))(ForgotPassword);