import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Button from '../../Button';

import './styles.scss';

const NumberInput = ({
  label,
  sublabel,
  placeholder,
  errorMessage,
  inputProps,
  value,
  className = '',
  iconLeft,
  iconRight,
  textRight,
  textLeft,
  prependText,
  disable = false,
  detailInput,
  inputMode,
  iconRightClick,
  withHangingLabel,
  addMinusControl
}) => {
  const [focus, setFocus] = useState(false);

  const additionalInputProps = {
    onBlur: () => setFocus(false),
    onFocus: () => setFocus(true)
  }

  return (
    <div
      className={cn(`number-input-container ${className}`, {
        'err': errorMessage,
        'hanging-label': withHangingLabel,
        disable
      })}
    >
      {label && (
        <div className={cn('label active')}>
          {label}
          {sublabel && <div className='sublabel'>{sublabel}</div>}
        </div>
      )}
      <div className='input-wrap'>
        <div className='input-group'>
          <Button classname='minus' color="pink" type='button' onClick={()=> addMinusControl(true)}>-</Button>
          {(textLeft && (!!value || focus)) && <span className={'text left'}>{textLeft}</span>}
          {inputMode === 'textarea' ? (
            <textarea
              type='text'
              placeholder={placeholder}
              value={value || ''}
              readOnly={disable}
              rows='4'
              style={{ resize: 'none', width: '100%' }}
              {...additionalInputProps}
              {...inputProps}
            />
          ) : (
            <input
              type='text'
              placeholder={placeholder}
              value={value || ''}
              readOnly={disable}
              {...additionalInputProps}
              {...inputProps}
            />
          )}
          
          <Button classname='add' color="purple" type='button' onClick={()=> addMinusControl(false)}>+</Button>
        </div>
        {detailInput && <div className='detail-input'>{detailInput}</div>}
        {errorMessage && <div className='err-message'>{errorMessage}</div>}
      </div>
    </div>
  );
};

NumberInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.any,
  inputProps: PropTypes.object,
  value: PropTypes.string,
  className: PropTypes.string,
  sublabel: PropTypes.any,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  inputMode: PropTypes.string
};

export default NumberInput;
