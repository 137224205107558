import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Button from '../../../../components/Button';
import Drawer from '../../../../components/Drawer'
import Checkbox from '../../../../components/Input/Checkbox';
import usePrevious from '../../../../helpers/usePreviousState';

const FilterDrawer = ({ 
  i18n, 
  showDrawer,
  setShowDrawer,
  onSubmit, 
  defaultFilter
}) => {
  const [ filterSearch, setFilterSearch ] = useState([]);
  const [ filterSearchSubmit, setFilterSearchSubmit ] = useState([]);
  const [ showButtonSubmit, setShowButtonSubmit ] = useState(false);
  const prevFilterSearch = usePrevious(filterSearch);

  const filterDrawer = {
    key: 'filter', 
    list: [
      {
        value: [
          {label: "Unapproved", value: 1},
          // {label: "New Member", value: 2}
        ]
      },
    ]
  }

  const filterHandler = (value) => {

    const active = filterSearch.find(selectedItem => selectedItem.value === value.value);
    
    if (active) {
      const tmpFilterSearch = filterSearch.filter((selectedItem) => selectedItem.value !== value.value);
      setFilterSearch(tmpFilterSearch)
    } else {
      setFilterSearch([ ...filterSearch, value ])
    }
  }

  const submitFilter = (data = filterSearch) => {
    setFilterSearchSubmit(data)
    setShowDrawer(false)
    setShowButtonSubmit(false)

    onSubmit && onSubmit(data)
  }

  const closeDrawer = () => {
    setShowDrawer(!showDrawer)
    setFilterSearch(filterSearchSubmit || [])
    setShowButtonSubmit(false)
  }

  useEffect(() => {
    if (showDrawer && JSON.stringify(filterSearch) !== JSON.stringify(prevFilterSearch)) {
      setShowButtonSubmit(true)
    }
  }, [showDrawer, filterSearch])

  useEffect(() => {
    if (showDrawer && defaultFilter) {
      if (defaultFilter !== filterSearch && filterSearch.length === 0) {
        setFilterSearch(defaultFilter)
      }
    }
  }, [showDrawer, defaultFilter])
  
  return (
    <Drawer
      showDrawer={showDrawer}
      top={300}
      threshold={0.5}
      onCloseDrawer={closeDrawer}
      className="filter-drawer-component"
    >
      {onCloseDrawer => {
        return (
          <>
            <Drawer.Header
              title={"Filter anggota"}
              onCloseDrawer={onCloseDrawer}
            />
            <Drawer.Body className="list-drawer">
              <div className="filter-list">
                {filterDrawer?.list.map(({ label, value }, idx) => (
                  <div className="filter-item-container checkbox">
                    <div className="title">{label}</div>
                    <div className="filter-item-wrap">
                      {value.map((itemVal, idx) => {
                        const active = filterSearch.find(selectedItem => JSON.stringify(selectedItem.value) === JSON.stringify(itemVal.value));

                        return (
                          <Checkbox
                            key={idx}
                            className='filter-item'
                            label={itemVal?.label}
                            checked={!!active}
                            onChange={() => filterHandler(itemVal)}
                          />
                        )
                      })}
                    </div>
                  </div>
                ))}
              </div>
              
              {showButtonSubmit && 
              <Button color='pink' onClick={() => submitFilter()}>{i18n('cta.apply')}</Button>
              }
            </Drawer.Body>
          </>
        );
      }}
    </Drawer>);
};

FilterDrawer.propTypes = {
  i18n: PropTypes.object, 
  showDrawer: PropTypes.bool,
  setShowDrawer: PropTypes.func,
  onSubmit: PropTypes.func
};

export default FilterDrawer;
