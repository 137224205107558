import { apiCaller } from '../../helpers/apiCaller';

// get member
const getMemberListRequest = () => ({
  type: 'GET_MEMBER_LIST_REQUEST'
});

const getMemberListSuccess = (data) => ({
  type: 'GET_MEMBER_LIST_SUCCESS',
  payload: data
});

const getMemberListFailure = (error) => ({
  type: 'GET_MEMBER_LIST_FAILURE',
  payload: error
});

const getMemberList = (params) => {
  return (dispatch) => {
    dispatch(getMemberListRequest());
    return apiCaller('account/member', 'get', undefined, undefined, {
      Size: 100,
      Page: 1,
      ...params,
    })
      .then((data) => {
        if (data?.response?.data?.code) {
          return dispatch(getMemberListFailure(data));
        }

        return dispatch(getMemberListSuccess(data));
      })
      .catch((error) => dispatch(getMemberListFailure(error)));
  };
};

// get member detail
const getMemberDetailRequest = () => ({
  type: 'GET_MEMBER_DETAIL_REQUEST'
});

const getMemberDetailSuccess = (data) => ({
  type: 'GET_MEMBER_DETAIL_SUCCESS',
  payload: data
});

const getMemberDetailFailure = (error) => ({
  type: 'GET_MEMBER_DETAIL_FAILURE',
  payload: error
});

const getMemberDetail = (id, params) => {
  return (dispatch) => {
    dispatch(getMemberDetailRequest());
    return apiCaller(`v1/account/member/${id}`, 'get')
      .then((data) => {
        if (data?.response?.data?.code) {
          return dispatch(getMemberDetailFailure(data));
        }

        return dispatch(getMemberDetailSuccess(data));
      })
      .catch((error) => dispatch(getMemberDetailFailure(error)));
  };
};

// post member detail
const postMemberDetailRequest = () => ({
  type: 'POST_MEMBER_DETAIL_REQUEST'
});

const postMemberDetailSuccess = (data) => ({
  type: 'POST_MEMBER_DETAIL_SUCCESS',
  payload: data
});

const postMemberDetailFailure = (error) => ({
  type: 'POST_MEMBER_DETAIL_FAILURE',
  payload: error
});

const postMemberDetail = (id, params) => {
  return (dispatch) => {
    dispatch(postMemberDetailRequest());
    return apiCaller(`v1/account/member/${id}`, 'put', params)
      .then((data) => {
        if (data?.response?.data?.code) {
          return dispatch(postMemberDetailFailure(data));
        }

        return dispatch(postMemberDetailSuccess(data));
      })
      .catch((error) => dispatch(postMemberDetailFailure(error)));
  };
};

const resetPostMemberDetail = () => ({
  type: 'RESET_POST_MEMBER_DETAIL'
});

// put kuota member
const putKuotaMemberRequest = () => ({
  type: 'PUT_KUOTA_MEMBER_REQUEST'
});

const putKuotaMemberSuccess = (data) => ({
  type: 'PUT_KUOTA_MEMBER_SUCCESS',
  payload: data
});

const putKuotaMemberFailure = (error) => ({
  type: 'PUT_KUOTA_MEMBER_FAILURE',
  payload: error
});

const putKuotaMember = (params) => {
  return (dispatch) => {
    dispatch(putKuotaMemberRequest());
    return apiCaller(`account/kuota`, 'put', params)
      .then((data) => {
        if (data?.response?.data?.code) {
          return dispatch(putKuotaMemberFailure(data));
        }

        return dispatch(putKuotaMemberSuccess(data));
      })
      .catch((error) => dispatch(putKuotaMemberFailure(error)));
  };
};

const resetputKuotaMember = () => ({
  type: 'RESET_PUT_KUOTA_MEMBER'
});

// delete member
const deleteMemberRequest = () => ({
  type: 'DELETE_MEMBER_REQUEST'
});

const deleteMemberSuccess = (data) => ({
  type: 'DELETE_MEMBER_SUCCESS',
  payload: data
});

const deleteMemberFailure = (error) => ({
  type: 'DELETE_MEMBER_FAILURE',
  payload: error
});

const deleteMember = (id) => {
  return (dispatch) => {
    dispatch(deleteMemberRequest());
    return apiCaller(`v1/account/member/${id}`, 'delete')
      .then((data) => {
        if (data?.response?.data?.code) {
          return dispatch(deleteMemberFailure(data));
        }

        return dispatch(deleteMemberSuccess(data));
      })
      .catch((error) => dispatch(deleteMemberFailure(error)));
  };
};

const resetdeleteMember = () => ({
  type: 'RESET_DELETE_MEMBER'
});

const setSearchParamsLeavePage = (data) => ({
  type: 'SEARCH_PARAMS_LEAVE_PAGE',
  payload: data
});

export { 
  getMemberList,
  getMemberDetail,
  postMemberDetail,
  resetPostMemberDetail,
  putKuotaMember,
  resetputKuotaMember,
  deleteMember,
  resetdeleteMember,
  setSearchParamsLeavePage
};
