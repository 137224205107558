// import axios from 'axios';
import { apiCaller } from '../../helpers/apiCaller';

const getAccountInfoRequest = () => ({
  type: 'GET_ACCOUNT_INFO_REQUEST'
});

const getAccountInfoSuccess = (data) => ({
  type: 'GET_ACCOUNT_INFO_SUCCESS',
  payload: data
});

const getAccountInfoFailure = (error) => ({
  type: 'GET_ACCOUNT_INFO_FAILURE',
  payload: error
});

const getAccountInfo = () => {
  return (dispatch) => {
    dispatch(getAccountInfoRequest());
    return apiCaller('v1/account/info', 'get')
      .then((data) => {
        if (data?.response?.data?.status === 422) {
          return dispatch(getAccountInfoFailure(data));
        }

        const { data: {data: { isApproved }}} = data || {};

        if (!isApproved) {
          localStorage.removeItem('accessToken');
          localStorage.setItem('ERROR_LOGIN_NEED_APPROVE', 'show message');
          window.location.href = '/login';
        }

        return dispatch(getAccountInfoSuccess(data));
      })
      .catch((error) => dispatch(getAccountInfoFailure(error)));
  };
};

// Change pass
const postChangePasswordRequest = () => ({
  type: 'POST_CHANGE_PASSWORD_REQUEST'
});

const postChangePasswordSuccess = (data) => ({
  type: 'POST_CHANGE_PASSWORD_SUCCESS',
  payload: data
});

const postChangePasswordFailure = (error) => ({
  type: 'POST_CHANGE_PASSWORD_FAILURE',
  payload: error
});

const postChangePassword = (params) => {
  return (dispatch) => {
    dispatch(postChangePasswordRequest());
    return apiCaller('account/change-pass', 'post', params)
      .then((data) => {
        if (data?.response?.data?.code) {
          return dispatch(postChangePasswordFailure(data));
        }

        return dispatch(postChangePasswordSuccess(data));
      })
      .catch((error) => dispatch(postChangePasswordFailure(error)));
  };
};

const resetChangePassword = () => ({
  type: 'RESET_CHANGE_PASSWORD'
});

// Change phone number
const postChangePhoneRequest = () => ({
  type: 'POST_CHANGE_PHONE_REQUEST'
});

const postChangePhoneSuccess = (data) => ({
  type: 'POST_CHANGE_PHONE_SUCCESS',
  payload: data
});

const postChangePhoneFailure = (error) => ({
  type: 'POST_CHANGE_PHONE_FAILURE',
  payload: error
});

const postChangePhone = (params) => {
  return (dispatch) => {
    dispatch(postChangePhoneRequest());
    return apiCaller('account/change-phone/send-otp', 'post', params)
      .then((data) => {
        if (data?.response?.data?.code) {
          return dispatch(postChangePhoneFailure(data));
        }

        return dispatch(postChangePhoneSuccess(data));
      })
      .catch((error) => dispatch(postChangePhoneFailure(error)));
  };
};

const resetChangePhone = () => ({
  type: 'RESET_CHANGE_PHONE'
});

// Verify phone number 
const postVerifyPhoneRequest = () => ({
  type: 'POST_VERIFY_PHONE_REQUEST'
});

const postVerifyPhoneSuccess = (data) => ({
  type: 'POST_VERIFY_PHONE_SUCCESS',
  payload: data
});

const postVerifyPhoneFailure = (error) => ({
  type: 'POST_VERIFY_PHONE_FAILURE',
  payload: error
});

const postVerifyPhone = (params) => {
  return (dispatch) => {
    dispatch(postVerifyPhoneRequest());
    return apiCaller('account/change-phone/verify-and-apply', 'post', params)
      .then((data) => {
        if (data?.response?.data?.code) {
          return dispatch(postVerifyPhoneFailure(data));
        }

        return dispatch(postVerifyPhoneSuccess(data));
      })
      .catch((error) => dispatch(postVerifyPhoneFailure(error)));
  };
};

const resetVerifyPhone = () => ({
  type: 'RESET_VERIFY_PHONE'
});

export { 
  getAccountInfo, 
  postChangePassword, 
  postChangePhone,
  postVerifyPhone,
  resetChangePassword,
  resetChangePhone,
  resetVerifyPhone
};
