import { sanitizeLang, i18n } from './setup';
import moment from 'moment';
import 'moment/locale/id';

const defaultLang = sanitizeLang(localStorage.getItem('userLang') || 'id');
moment.locale(defaultLang);
const defaultState = {
  lang: defaultLang,
  i18n: i18n(defaultLang)
};

const langReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'GET_LANG':
      localStorage.setItem('userLang', action.lang);
      moment.locale(action.lang);

      return {
        ...state,
        lang: action.lang,
        i18n: i18n(action.lang)
      };
    default:
      return state;
  }
};

export { langReducer };
