import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import Button from '../../components/Button';
import Icon from '../../components/Icon';
import Box from './components/SlideBox';
import SlideButton from './components/SlideButton';
import DantaLogo from '../../components/DantaLogo';

import Image1 from '../../assets/img/WelcomeSplash/splash-1.svg';
import Image2 from '../../assets/img/WelcomeSplash/splash-2.svg';
import Image3 from '../../assets/img/WelcomeSplash/splash-3.svg';

import './styles.scss';

const WelcomeSplash = ({
  i18n
}) => {
  const history = useHistory();
  const [ activeSlide, setActiveSlide ] = useState(0);
  let nextPage = '/sign-in';

  const slideContent = [
    { img: Image1, keyLang: 'avail_class' },
    { img: Image2, keyLang: 'quota' },
    { img: Image3, keyLang: 'regis_class' },
  ]

  const nextHandler = () => {
    if (activeSlide < slideContent?.length - 1) {
      const tmpSlide = activeSlide + 1;
      setActiveSlide(tmpSlide);
    } else {
      // todo : should check already login, redirect to login or home 
      history.push(nextPage);
    }
  }

  const prevHandler = () => {
    if (activeSlide > 0) {
      const tmpSlide = activeSlide - 1;
      setActiveSlide(tmpSlide);
    }
  }
  
  return (
    <div className='welcome-splash-wrap'>
      <section className="frame-animation" id="frame1">
        <DantaLogo/>
        <span className="version">1.6.1</span>
      </section>
      <div className="frame-animation" id="frame2">
        <Button flat color="pink" onClick={() => history.push(nextPage)} id="skip">Skip</Button>
        <section>
          <div className="slide-wrap">
            {slideContent.map(({ img, keyLang }, idx) => (        
              <Box
                key={idx}
                imageAlt={keyLang}
                active={idx === activeSlide}
                image={img}
                title={i18n(`splash.${keyLang}.title`)}
                desc={i18n(`splash.${keyLang}.desc`)}
              />
            ))}
          </div>
          <SlideButton
            activeDot={activeSlide}
            setActiveDot={setActiveSlide}
            total={slideContent.length}
          />
          <div className="slide-nav">
            <div className="prev" onClick={() => prevHandler()}/>
            <div className="next" onClick={() => nextHandler()}/>
          </div>
        </section>
        <Button flat onClick={() => nextHandler()} id="next">
          <Icon icon="arrow"/>
        </Button>
      </div>
    </div>
  );
};

WelcomeSplash.propTypes = {
  i18n: PropTypes.func,
};

export default WelcomeSplash;
