import React from 'react';
import { useHistory } from 'react-router-dom';

import Button from '../../components/Button';
import DantaLogo from '../../components/DantaLogo';

const style = {
  height: "100%",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  zIndex: "1000",
  position: "absolute",
  background: "#FFF9F9",
  overflow: "hidden"
}

const NotFound = () => {
  const history = useHistory();

  return (
    <section className='notfound-container' style={style}>
      <DantaLogo width="340"/>
      <h1>Sorry, page not found!</h1>
      <Button color="purple" onClick={() => history.push('/')}>Back to home</Button>
    </section>
  );
};

export default NotFound;
