import React, { useState } from 'react';
import moment from 'moment';

import TextInput from '../../Input/TextInput';
import Drawer from '../../Drawer';
import Icon from '../../Icon';

import './styles.scss';

const DrawerList = ({
  i18n,
  label,
  value,
  onChange,
  errorMessage,
  labelDrawer,
  options,
  valueKey = 'id',
  labelKey = 'label',
  iconInput,
  withHangingLabel = true,
  placeholder,
  disable,
}) => {

  const [ showDrawer, setShowDrawer ] = useState(false);

  const selectedValue = options.find((option) => option[valueKey] === value);

  const handlerCalendarDrawer = () => {
    if (showDrawer) {
      document.body.classList.remove("stop-scrolling");
    } else {
      document.body.classList.add("stop-scrolling");
    }

    setShowDrawer(!showDrawer)
  }

  const handlerChanges = value => {
    onChange(value)
    handlerCalendarDrawer()
  }

  const maxHeight = (options.length * 52) + 18 + 80;

  return (
    <div className='list-drawer-container'>
      <TextInput
        label={label}
        value={selectedValue?.[labelKey] || label || placeholder}
        inputProps={{
          type:'button',
          onClick: !disable && handlerCalendarDrawer
        }}
        withHangingLabel={withHangingLabel}
        errorMessage={errorMessage}
        iconLeft={iconInput}
        iconRight="arrow down white"
        placeholder={placeholder}
        disable={disable}
      />

      <Drawer
        showDrawer={showDrawer}
        maxHeight={maxHeight}
        threshold={0.5}
        onCloseDrawer={handlerCalendarDrawer}
      >
        {onCloseDrawer => {
          return (
            <>
              <Drawer.Header
                title={labelDrawer}
                onCloseDrawer={onCloseDrawer}
              />
              <Drawer.Body className="list-drawer"> 
                <div className="list-wrap">
                  {options.map((option, idx) => (
                    <div className="list-item" key={idx} onClick={() => handlerChanges(option[valueKey])}>
                      {option[labelKey]}
                      {option[valueKey] === value && 
                        <Icon icon="check"/>
                      }
                    </div>
                  ))}
                </div>
              </Drawer.Body>
            </>
          );
        }}
      </Drawer>

    </div>
    
  );
};

export default DrawerList;
