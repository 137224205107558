import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const Icon = ({ icon, placement = '', className = '', ...props }) => {
  return <span className={`icon ${icon} ${placement} ${className}`} {...props} />;
};

Icon.propTypes = {
  icon: PropTypes.string,
  placement: PropTypes.string
};

export default Icon;
