import React, { useEffect } from 'react';
import Parser from 'html-react-parser';
import { connect } from 'react-redux';
import { compose } from 'redux';

import Header from '../../../components/Header';
import HeaderDetail from '../../../components/Header/Detail';

import { getAccountInfo } from '../../Account/action';

import './styles.scss';

const BookingHelp = ({
  i18n,
  getAccountInfo,
  accountState,
}) => {
  const { data: { name } } = accountState;

  const tncList = [
    "Sebelum mendaftar kelas di Danta Yoga, kamu harus mempunyai minimum 1 Kuota",
    "Jika kamu belum memiliki kuota (0 Kuota), kamu dapat melakukan Top Up Kuota melalui Whatsapp Admin Danta (Lihat Panduan Top-Up)", // todo disini link ke help>Topup aja
    "Untuk melakukan pendaftaran kelas, kamu dapat masuk ke menu Daftar Kelas (klik disini)", //todo put link
    "Kemudian cari jadwal kelas yang kamu inginkan. Kamu bisa mencari berdasarkan kategori kelas dan tanggal kelas diadakan. Untuk dewasa gunakan kelas Reguler",
    "Kelas yang tersedia diantaranya: Yoga Matt, Aerial Danta Yoga, Muaythai, dan TRX Workout (Total Resistance Exercises).",
    "Kelas Anak, valid untuk usia 6 sampai 12 Tahun",
    "Jika kelas yang kamu cari tidak tampil, coba untuk mengganti tanggal/hari lainnya",
    "Jika dari list yang tampil, Nama kelas, Jam kelas dan Instruktur sudah sesuai yang diinginkan, Kamu dapat menekan tombol 'Pilih'",
    "Anda kemudian diarahkan ke halaman konfirmasi. Silahkan Cek kembali Kelas, Tanggal, dan Instruktur yang kamu pilih sebelumnya",
    "Mohon membaca dan menyetujui ketentuan yang berlaku di Danta Yoga Studio. Lalu klik 'Daftar' untuk melakukan Pendaftaran.",
    "Konfirmasi sekali lagi dengan klik 'OK'",
    "Horee! Kamu telah terdaftar di Kelas yang anda pilih. Kamu dapat melihat riwayat pendaftaranmu di Menu 'Kelas Saya'",
    "Jika pendaftaran gagal, mohon cek kembali: 1. Kuota anda apakah mencukupi, 2. Kuota kelas sudah penuh. 3. Koneksi anda kurang baik",
    `Jika masih mengalami kendala pendaftaran, silahkan hubungi Admin Danta di nomor +6281805018899 atau <a href="https://wa.me/6281805018899?text=Halo%20Danta%20Yoga%2C%20Saya%20${name}%20butuh%20bantuan" target="_blank">Whatsapp</a>` 
  ]
  useEffect(() => {
    getAccountInfo();
  }, []);

  return (<>
    <Header label={i18n('label.tnc')}/>
    <HeaderDetail title={i18n('booking_help_page.title')} desc={i18n('booking_help_page.desc')}/>
    <section className='booking-help-container'>
      {/* <HeaderHelp i18n={i18n}/> */}
      
      <main>
        <div className="body-wrap">
          <div className="top">
            <div className="title">{i18n('booking_help_page.header1')}</div>
          </div>
          <div className="content-wrap">
              <ol className="tnc-list">
                {tncList.map((tnc, idx) => (
                  <li className="item" key={idx}>{Parser(tnc)}</li>
                ))}
              </ol>
          </div>
        </div>
      </main>
    </section>
  </>);
};

const mapStateToProps = (state) => ({
  accountState: {
    status: state.getAccountInfoReducer.status,
    error: state.getAccountInfoReducer.error,
    data: state.getAccountInfoReducer.data
  }
});

export default compose(connect(mapStateToProps, { 
  getAccountInfo,
 }))(BookingHelp);
