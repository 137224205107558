import React from 'react';
import { useHistory } from 'react-router-dom';
import DantaLogo from '../../components/DantaLogo';
import Button from '../../components/Button';

import './styles.scss';

const SignIn = ({
  i18n
}) => {
  const history = useHistory();

  return (
    <section className='new-user-container'>
      <DantaLogo/>
      <Button color="purple" onClick={() => history.push('/login')}>{i18n('cta.login')}</Button>
      <div className="or-wrap">
        <div className="line-word"/>
        <span className="word">{i18n('login_page.or')}</span>
      </div>
      <Button color="pink" onClick={() => history.push('/register')}>{i18n('cta.register')}</Button>
    </section>
  );
};

export default SignIn;
