import React from 'react';

import TextInput from '../../../components/Input/TextInput';
import Datepicker from '../../../components/Dropdown/Datepicker';
import Checkbox from '../../../components/Input/Checkbox';
import Button from '../../../components/Button';
import RadioButton from '../../../components/Input/RadioButton';

import './styles.scss';

const FormMember = ({ 
  i18n, 
  form,
  handlerChanges,
  errors,
  handleDeleteBtn,
}) => {

  const gender_options = [
    { value: 'FEMALE', name: i18n('label.women') },
    { value: 'MALE', name: i18n('label.man') }
  ];
  
  return (
    <div className='member-form'>
      <TextInput
        label={i18n('label.handphone')}
        value={form?.phone}
        inputProps={{
          onChange: (e) => handlerChanges(e.target.value, 'phone')
        }}
        withHangingLabel
        textLeft="+62"
        disable
      />
      <TextInput
        label={i18n('label.fullname')}
        value={form?.name}
        inputProps={{
          onChange: (e) => handlerChanges(e.target.value, 'name')
        }}
        withHangingLabel
        errorMessage={errors?.name && i18n(`validation.${errors?.name}`)}
      />
      <TextInput
        label={i18n('label.email')}
        value={form?.email}
        inputProps={{
          type:'text',
          onChange: (e) => handlerChanges(e.target.value, 'email')
        }}
        withHangingLabel
        errorMessage={errors?.email && i18n(`validation.${errors?.email}`)}
      />
       <RadioButton
        label="Gender"
        className='gender-type dark-mode form'
        options={gender_options}
        value={form?.gender}
        inputProps={{
          onChange: (e) =>handlerChanges(e.target.value, 'gender')
        }}
      />
      <TextInput
        label={i18n('label.instagram')}
        value={form?.instagram}
        inputProps={{
          type:'text',
          onChange: (e) => handlerChanges(e.target.value, 'instagram')
        }}
        withHangingLabel
        errorMessage={errors?.instagram && i18n(`validation.${errors?.instagram}`)}
      />
      <Datepicker
        i18n={i18n}
        label={i18n('label.bod')}
        labelDrawer={i18n('label.select_bod')}
        value={form?.birth}
        withoutLabelEmpty
        onChange={(date) => handlerChanges(date, 'birth')}
        errorMessage={errors?.birth && i18n(`validation.${errors?.birth}`)}
        minDate={new Date("1970-01-02")}
        maxDate={new Date()}
      />
      <div className="approval-wrap">
        <Checkbox
          className='isApproved'
          label={<span className="label">{i18n('label.approve_account')}</span>}
          checked={form?.isApproved}
          onChange={() => handlerChanges(!form?.isApproved, 'isApproved')}
          errorMessage={errors?.isApproved && i18n(`validation.${errors?.isApproved}`)}
        />
      </div>
      {/* <NumberInput
        label={i18n('label.quota')}
        value={form?.kuota}
        inputProps={{
          type: 'number',
          onChange: (e) => handlerChanges(e.target.value, 'kuota')
        }}
        withHangingLabel
        addMinusControl={isMinus => {
          const myQuota = Number(form?.kuota || 0)
          const tmpKuota = isMinus ? myQuota - 1 : myQuota + 1;
          handlerChanges(tmpKuota, 'kuota')
        }}
        errorMessage={errors?.name && i18n(`validation.${errors?.name}`)}
      /> */}
      {handleDeleteBtn &&
        <Button type='button' id="delete-member" color="purple" onClick={handleDeleteBtn}>{i18n(`cta.delete`)}</Button>
      }
    </div>
  );
};

export default FormMember;
