import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as yup from 'yup';

import { errorScroll } from '../../../helpers/functions';
import { convertArrayToObject } from '../../../helpers';

import AccountHeader from '../components/Header';
import Button from '../../../components/Button';
import TextInput from '../../../components/Input/TextInput';
import Loading from '../../../components/Loading';
import useSnackbar from '../../../components/Snackbar/functions';

import { postChangePassword, resetChangePassword } from '../action';

import './styles.scss';

const ChangePassword = ({
  i18n,
  accountState,
  postChangePasswordState,
  postChangePassword
}) => {
  const history = useHistory();
  const { data: { name, phone  } } = accountState;

  const [ form, setForm ] = useState();
  const [ errors, setErrors ] = useState();
  const [ loading, setLoading ] = useState(false);
  const { showSnackbar } = useSnackbar();

  const handleSubmit = (e) => {
    e.preventDefault();
    const params = { ...form }
    let validatinsSchema = {
      oldPassword: yup
        .string()
        .test(
          'len',
          i18n('validation.min_length', { min: 8 }),
          (val) => val && val.toString().length > 7
        )
        .required('required'),
      newPassword: yup
        .string()
        .test(
          'len',
          i18n('validation.min_length', { min: 8 }),
          (val) => val && val.toString().length > 7
        )
        .required('required'),
      confirmPassword: yup.string()
        .required('required')
        .oneOf([yup.ref('newPassword'), null], i18n('validation.password_not_match')),
    }

    let schema = yup.object().shape({
      ...validatinsSchema
    });

    schema
      .validate(params, { abortEarly: false })
      .then(() => {
        // submit register kk
        setLoading(true)
        postChangePassword({
          "newPassword": params?.newPassword,
          "oldPassword": params?.oldPassword
        })
      })
      .catch((err) => {
        setErrors(convertArrayToObject(err.inner, 'path', 'message'));
        errorScroll();
      });
  };

  const handlerChanges = (value, key, errorKey) => {
    setForm({ ...form, [key]: value });
    let errorMessage = { ...errors, [errorKey ? errorKey : key]: undefined };

    if (key === 'confirmPassword') {
      if(form?.newPassword !== value) {
        errorMessage = {...errors, [key]: 'password_not_match'}
      }
    }

    setErrors(errorMessage);
  };

  useEffect(() => {
    if (loading) {
      const { status, error } = postChangePasswordState;

      if (status === 'resolve') {
        setLoading(false)
        showSnackbar({
          message: i18n('profile_page.change_pass_message.success'),
          type: 'success'
        })
        history.push('/account')
      } else if(status === 'rejected'){
        const errMessage = error?.response?.data?.message
        setLoading(false)
        showSnackbar({
          message: i18n(`error_message.${errMessage}`),
          type: 'error'
        })
        resetChangePassword()
      }
    }
  }, [postChangePasswordState?.status])

  return (<>
    {loading && <Loading/>}
    <AccountHeader i18n={i18n} name={name} history={history} onBackDest={'/account'}/>
    <section className='change-password-container'>
      <div className="title">{i18n('profile_page.change_password')}</div>
      <form onSubmit={handleSubmit}>
        <TextInput
          label={i18n('label.old_password')}
          value={form?.oldPassword}
          inputProps={{
            type: 'password',
            onChange: (e) => handlerChanges(e.target.value, 'oldPassword')
          }}
          withHangingLabel
          errorMessage={errors?.oldPassword && i18n(`validation.${errors?.oldPassword}`)}
        />
        <TextInput
          label={i18n('label.new_password')}
          value={form?.newPassword}
          inputProps={{
            type: 'password',
            onChange: (e) => handlerChanges(e.target.value, 'newPassword')
          }}
          withHangingLabel
          errorMessage={errors?.newPassword && i18n(`validation.${errors?.newPassword}`)}
        />
        <TextInput
          label={i18n('label.re_password')}
          value={form?.confirmPassword}
          inputProps={{
            type: 'password',
            onChange: (e) => handlerChanges(e.target.value, 'confirmPassword')
          }}
          withHangingLabel
          errorMessage={errors?.confirmPassword && i18n(`validation.${errors?.confirmPassword}`)}
        />
        <Button color="pink" id='logout' type='submit'>{i18n('profile_page.change_password')}</Button>
      </form>
    </section>
  </>);
};

const mapStateToProps = (state) => ({
  postChangePasswordState: {
    status: state.postChangePasswordReducer.status,
    error: state.postChangePasswordReducer.error,
    data: state.postChangePasswordReducer.data
  }
});

export default compose(connect(mapStateToProps, { 
  postChangePassword,
  resetChangePassword
}))(ChangePassword);