import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useHistory, useLocation } from "react-router-dom";
import PropTypes from 'prop-types';
import * as yup from 'yup';
import moment from 'moment';

import { errorScroll, parseQuery } from '../../../helpers/functions';
import { convertArrayToObject } from '../../../helpers';
import { validation, setEndTime } from '../functions';

import Header from '../../../components/Header';
import Loading from '../../../components/Loading';
import Button from '../../../components/Button';
import useSnackbar from '../../../components/Snackbar/functions';
import FormSchedule from '../Form';

import { postAvail, resetPostAvail } from '../action'

import './styles.scss';

const CreateSchedule = ({ 
  i18n, 
  postAvailState,
  postAvail,
  resetPostAvail
}) => {
  const history = useHistory();
  const { search } = useLocation();
  const { startDate } = parseQuery(search) || {};
  const { showSnackbar } = useSnackbar();
  
  const [ form, setForm ] = useState();
  const [ loading, setLoading ] = useState(false);
  const [ errors, setErrors ] = useState();

  const handleSubmit = (e) => {
    e.preventDefault();
    const params = { ...form }

    let schema = yup.object().shape(validation(false));

    schema
      .validate(params, { abortEarly: false })
      .then(() => {
        setLoading(true);
        const postParams = {
          "classId": params?.class,
          "date": `${moment(params?.startDate).format('YYYY-MM-DD')} ${params?.start_time?.hh}:${params?.start_time?.mm}`,
          "instructorId": params?.instructor,
          "minAttendance": Number(params?.min_attendance),
          "timezone": "Asia/Jakarta"
        }

        if (params?.repeat_class) {
          postParams.dateUntil =  `${moment(params?.endDate).format('YYYY-MM-DD')} ${params?.start_time?.hh}:${params?.start_time?.mm}`
          postParams.hour = `${params?.start_time?.hh}:${params?.start_time?.mm}`
        }

        postAvail(postParams);
      })
      .catch((err) => {
        setErrors(convertArrayToObject(err.inner, 'path', 'message'));
        errorScroll();
      });
  };

  const handlerChanges = (value, key, errorKey) => {
    setForm({ ...form, [key]: value });
    let errorMessage = { ...errors, [errorKey ? errorKey : key]: undefined };

    setErrors(errorMessage);
  };

  useEffect(() => {
    if (startDate) {
      setForm({ 
        ...form, 
        startDate: new Date(startDate)
      })
    }
  }, [startDate])

  useEffect(() => {
    const new_end_time = setEndTime(form);
    if (new_end_time) {
      setForm({
        ...form,
        end_time: new_end_time
      })
    }
  }, [form?.start_time, form?.duration])

  useEffect(() => {
    if (loading) {
      const { status, error } = postAvailState;

      if (status === 'resolve') {
        setLoading(false)
        showSnackbar({
          message: i18n(`schedule_admin.success_create`),
          type: 'success'
        })
        resetPostAvail()
        history.push('/admin/schedule')
      } else if(status === 'rejected'){
        const errMessage = error?.response?.data?.message
        setLoading(false)
        showSnackbar({
          message: i18n(`error_message.${errMessage}`),
          type: 'error'
        })
        resetPostAvail()
      }
    }

  }, [loading, postAvailState])
  
  return (<>
    <Header label={i18n('schedule_admin.title_create')}/>
    {loading && <Loading/>}
    <div className='create-schedule-admin' style={{
      padding: "33px 32px 16px",
      boxSizing: "border-box"
    }}>
      <form onSubmit={handleSubmit} autoComplete="off">
        <FormSchedule
          i18n={i18n}
          form={form}
          handlerChanges={handlerChanges}
          errors={errors}
        />
        <div className='btn-wrap'>
          <Button color="pink" onClick={() => history.push('/admin/schedule')}>{i18n("cta.cancel_2")}</Button>
          <Button color="purple" type='submit'>{i18n("cta.save")}</Button>
        </div>
      </form>
    </div>
  </>);
};

CreateSchedule.propTypes = {
  setLang: PropTypes.func,
  history: PropTypes.object,
  i18n: PropTypes.func,
  postAvailState: PropTypes.object,
  postAvail: PropTypes.func,
  resetPostAvail: PropTypes.func
};

const mapStateToProps = (state) => ({
  lang: state.langReducer.lang,
  postAvailState: {
    status: state.postAvailReducer.status,
    error: state.postAvailReducer.error,
    data: state.postAvailReducer.data
  }
});

export default compose(connect(mapStateToProps, {
  postAvail,
  resetPostAvail
}))(CreateSchedule);
