import { apiCaller } from '../../helpers/apiCaller';

const getClassListRequest = () => ({
  type: 'GET_CLASS_LIST_REQUEST'
});

const getClassListSuccess = (data) => ({
  type: 'GET_CLASS_LIST_SUCCESS',
  payload: data
});

const getClassListFailure = (error) => ({
  type: 'GET_CLASS_LIST_FAILURE',
  payload: error
});

const getClassList = (params) => {
  return (dispatch) => {
    dispatch(getClassListRequest());
    return apiCaller('v1/class', 'get', undefined, undefined, {
      ...params,
      Size: 100
    })
      .then((data) => {
        if (data?.response?.data?.code) {
          return dispatch(getClassListFailure(data));
        }

        return dispatch(getClassListSuccess(data));
      })
      .catch((error) => dispatch(getClassListFailure(error)));
  };
};

// get class detail
const getClassDetailRequest = () => ({
  type: 'GET_CLASS_DETAIL_REQUEST'
});

const getClassDetailSuccess = (data) => ({
  type: 'GET_CLASS_DETAIL_SUCCESS',
  payload: data
});

const getClassDetailFailure = (error) => ({
  type: 'GET_CLASS_DETAIL_FAILURE',
  payload: error
});

const getClassDetail = (id) => {
  return (dispatch) => {
    dispatch(getClassDetailRequest());
    return apiCaller(`v1/class/${id}`, 'get')
      .then((data) => {
        if (data?.response?.data?.code) {
          return dispatch(getClassDetailFailure(data));
        }

        return dispatch(getClassDetailSuccess(data));
      })
      .catch((error) => dispatch(getClassDetailFailure(error)));
  };
};

const resetClassDetail = () => {
  console.log('woyyyy')
  return {
    type: 'RESET_CLASS_DETAIL'
  }
};

// edit class
const postClassDetailRequest = () => ({
  type: 'POST_CLASS_DETAIL_REQUEST'
});

const postClassDetailSuccess = (data) => ({
  type: 'POST_CLASS_DETAIL_SUCCESS',
  payload: data
});

const postClassDetailFailure = (error) => ({
  type: 'POST_CLASS_DETAIL_FAILURE',
  payload: error
});

const postClassDetail = (id, params) => {
  return (dispatch) => {
    dispatch(postClassDetailRequest());
    return apiCaller(`${id ? `v1/class/${id}` : `v1/class`}`, `${id ? 'put':'post'}`, params)
      .then((data) => {
        if (data?.response?.data?.code) {
          return dispatch(postClassDetailFailure(data));
        }

        return dispatch(postClassDetailSuccess(data));
      })
      .catch((error) => dispatch(postClassDetailFailure(error)));
  };
};

const resetPostClassDetail = () => ({
  type: 'RESET_POST_CLASS_DETAIL'
});

// delete class
const deleteClassRequest = () => ({
  type: 'DELETE_CLASS_REQUEST'
});

const deleteClassSuccess = (data) => ({
  type: 'DELETE_CLASS_SUCCESS',
  payload: data
});

const deleteClassFailure = (error) => ({
  type: 'DELETE_CLASS_FAILURE',
  payload: error
});

const deleteClass = (id) => {
  return (dispatch) => {
    dispatch(deleteClassRequest());
    return apiCaller(`v1/class/${id}`, 'delete')
      .then((data) => {
        if (data?.response?.data?.code) {
          return dispatch(deleteClassFailure(data));
        }

        return dispatch(deleteClassSuccess(data));
      })
      .catch((error) => dispatch(deleteClassFailure(error)));
  };
};

const resetDeleteClass = () => ({
  type: 'RESET_DELETE_CLASS'
});

export { 
  getClassList,
  getClassDetail,
  postClassDetail,
  resetPostClassDetail,
  resetClassDetail,
  deleteClass,
  resetDeleteClass,
};
