import React from 'react';
import Logo from '../assets/img/WelcomeSplash/Logo.svg';

const DantaLogo = (props) => {
  return (
    <img src={Logo} alt="logo" id="danta-logo" {...props} width="100%" style={{ maxWidth: "360px", padding: "50px", boxSizing: "border-box", margin: "0 auto"}}/>
  );
};

export default DantaLogo;
