import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { compose } from 'redux';
import moment from 'moment-timezone';

import { errorScroll } from '../../helpers/functions';
import useSnackbar from '../../components/Snackbar/functions';

import Header from '../../components/Header';
import HeaderDetail from '../../components/Header/Detail';
import TopNavigation from '../../components/TopNavigation';
import Card from '../../components/Card';
import Checkbox from '../../components/Input/Checkbox';
import Row from '../../components/Row';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import Loading from '../../components/Loading';

import { getAvailDetail, postBooking, resetData } from './action';
import { getAccountInfo } from '../Account/action';

import './styles.scss';

export const convertDuration = (time, i18n) => {
  let milliseconds = Math.floor((time % 1000) / 100)
  let seconds = Math.floor((time / 1000) % 60)
  let minutes = Math.floor((time / (1000 * 60)) % 60)
  let hours = Math.floor((time / (1000 * 60 * 60)) % 24);

  return `${i18n('class_register_page.hour', { h: hours })} 
  ${minutes !== 0 ? i18n('class_register_page.minutes', {
     m: minutes }) : ''
  }`
}

const ClassDetail = ({
  i18n,
  accountState,
  getAvailDetail, 
  getAvailDetailState,
  postBooking,
  postBookingState,
  resetData,
}) => {
  const [ form, setForm ] = useState()
  const [ errors, setErrors ] = useState()
  const [ showModal, setShowModal ] = useState(false)
  const [ showDescriptionModal, setShowDescriptionModal ] = useState(false)
  const [ loading, setLoading ] = useState(false);
  
  const { data: { id: idAccount, quota  } } = accountState;
  const { data } = getAvailDetailState || {};
  
  const history = useHistory();
  const { id } = useParams();
  const { showSnackbar } = useSnackbar();
  
  const accountDetail = {
    id: idAccount,
    quota
  };

  // TODO: detail class not complete yet
  const classDetail = {
    ...data,
    "hour": moment(data?.date).format('HH:mm'),
    "duration": convertDuration(data?.duration, i18n)
  }

  const remainingQuota = accountDetail?.quota - classDetail?.classFee;

  const tncList = [
    "Kelas dapat otomatis dibatalkan jika kelas tidak memenuhi minimum jumlah peserta yang ditentukan",
    "Hadirlah 15 menit sebelum kelas dimulai, perkenalkan diri anda pada instruktur kelas. Sampaikan pada instruktur kelas jika ada cedera pada tubuh anda, atau hal hal penting lain yang ingin anda sampaikan",
    "Tinggalkan sepatu / alas kaki anda di rak sepatu di lantai dua",
    "Tersedia 2 ruang ganti dan 1 kamar shower untuk dipergunakan",
    "Gunakan baju olahraga yang nyaman dan layak",
    "Simpan barang barang anda di loker",
    "Anda hanya diperbolehkan membawa tumbler air minum, handuk kecil, dan ponsel dalam mode senyap ke ruang kelas. Semua Alat Peraga Danta yang dibutuhkan tersedia dan selalu dibersihkan setelah digunakan",
    "Ikuti instruksi instruktur selama kelas berlangsung, jangan ragu meminta bantuan instruktur jika diperlukan",
    "Segera tinggalkan kelas jika anda harus menerima panggilan telepon, batuk atau bersin bersin",
    "Member hanya boleh mengambil foto atau video kelas dengan izin instruktur. Kesempatan pengambilan foto dan video disediakan di sesi foto dan video setelah kelas",
    "Letakan, matras dan hammock bekas pakai di tempat yang sudah disediakan, petugas kami akan membersihkannya untuk anda."
  ]
  
  const showSnackbarBy = (message, type) => {
    showSnackbar({
      message: message,
      type: type
    })
  }

  const submit = (e) => {
    e.preventDefault();
    const params = {
      bookingId: '1',
      availId: id,
      accountId: accountDetail?.id,
      aggrement: form?.aggrement
    }

    if (params?.aggrement) {
      if (showModal) {
        window && window.scrollTo(0, 0)
        setLoading(true);
        setShowModal(false)
        postBooking({          
          accountId: accountDetail?.id,
          availId: classDetail?.id
        })
      } else {
        setShowModal(true)
      }
    } else {
      setErrors({...errors, aggrement: 'selected'});
      errorScroll();
    }
  }

  const handlerChanges = (value, key, errorKey) => {
    setForm({ ...form, [key]: value });
    setErrors({ ...errors, [errorKey ? errorKey : key]: undefined });
  };

  useEffect(() => {
    return () => {
      resetData();
    }
  }, [])

  useEffect(() => {
    setLoading(true)
    getAvailDetail(id)
  }, [id])


  useEffect(() => {
    if (loading) {
      const { status, error } = getAvailDetailState;
      if(status === 'resolve') {
        setLoading(false);
      } else if (status === 'rejected'){
        setLoading(false);
        const errMessage = i18n(`error_message.${error?.response?.data?.error}`);
        showSnackbarBy(errMessage, 'error')
      }
    }
  }, [getAvailDetailState?.status])


  useEffect(() => {
    if (loading) {
      const { status, error } = postBookingState;

      if(status === 'resolve') {
        setLoading(false);
        showSnackbarBy(i18n('class_register_page.success_regis'), 'success');
        history.push('/schedule')
      } else if (status === 'rejected'){
        setLoading(false);
        const errMessage = i18n(`error_message.${error?.response?.data?.error}`);
        showSnackbarBy(errMessage, 'error')
      }
    }
  }, [postBookingState?.status])

  return (<>
    {loading && <Loading/>}
    <Header label={i18n('label.class_regis')}/>
    <HeaderDetail 
      title={i18n('class_register_page.class_confirmation')}
      desc={i18n('class_register_page.desc_confirm_class_2')}
    />
    <section className='class-detail-container'>
      <TopNavigation className="class-summary">
        <div className="title">
          {classDetail?.className}
          <div className="tooltip-desc" onClick={() => setShowDescriptionModal(!showDescriptionModal)}>
            <div className="icon">!</div>
          </div>
        </div>
        <div className="detail">
          <span className="date">{i18n('class_register_page.class_date')}: <b>{moment(classDetail?.date).format('dddd, DD MMMM YYYY')}</b></span>
          <span className="time">{i18n('class_register_page.hour_start')}: <b>{classDetail?.hour} WIB</b></span>
          <span className="duration">{i18n('class_register_page.duration')}:  <b>{classDetail?.duration}</b></span>
          <span className="instructor-name">{i18n('label.instructor')}: <b>{classDetail?.instructor}</b></span>
          <h4>Deskripsi</h4>
          <div className="desc">{classDetail?.classDescription}</div>
        </div>
      </TopNavigation>

      <Card color="purple" className="class-tnc">
        <div className="title">{i18n('label.tnc')}</div>
        <ol className="tnc-list">
          {tncList.map((tnc, idx) => (
            <li className="item" key={idx}>{tnc}</li>
          ))}
        </ol>

        <Checkbox
          className='aggrement'
          label={<span className="label">{i18n('class_register_page.i_agree')}</span>}
          checked={form?.aggrement}
          onChange={() => handlerChanges(!form?.aggrement, 'aggrement')}
          errorMessage={errors?.aggrement && i18n(`validation.${errors?.aggrement}`)}
        />
      </Card>

      <div className="footer-purchasing">
        <Row className="my-coupon" label={i18n('class_register_page.my_quota')} value={(<>      
          <Button color="white-pink" onClick={() => history.push('/help/payment')}>+ {i18n('cta.top_up')}</Button>
          <span>{i18n('label.number_quota', { number: accountDetail?.quota})}</span> 
        </>)}/>
        <Row label={i18n('class_register_page.regis_fee')} value={i18n('label.number_quota', { number: classDetail?.classFee})}/>
        <Row className='last-row' label={i18n('class_register_page.coupon_remaining')} value={i18n('label.number_quota', { number: remainingQuota})}/>

        <Button color="white-pink" disabled={accountDetail?.quota < 1} type="submit" onClick={submit}>{i18n('cta.regis_now')}</Button>
      </div>
    </section>
    <Modal
      onClose={() => setShowModal(false)}
      isOpen={showModal}
      hasCloseIcon
      buttonSecondaryActionHandler={() => setShowModal(false)}
      buttonActionHandler={submit}
      buttonText={i18n('cta.yes_sure')}
      buttonSecondaryText={i18n('cta.cancel')}
      title={i18n('confirm_modal.title')}
      portalId="class-confirm"
    >
      <div className="title-confirm">{i18n('confirm_modal.title_join_class')}</div>
      <div className="detail-class-confirm">
        <div className="title"><b>{classDetail?.className}</b></div>
        <div className="detail">
          <div className="date"><b><i>{moment(classDetail?.date).format('LLLL')}</i></b></div>
          <div className="instructor-name">{i18n('label.instructor')}: <i>{classDetail?.instructor}</i></div>
        </div>
      </div>
    </Modal>
    <Modal
      onClose={() => setShowDescriptionModal(false)}
      isOpen={showDescriptionModal}
      hasCloseIcon
      buttonActionHandler={() => setShowDescriptionModal(false)}
      buttonText={'Tutup'}
      title={classDetail?.className}
      portalId="description-class"
    >
      <div className="title-confirm">{classDetail?.classDescription}</div>
    </Modal>
  </>);
};

const mapStateToProps = (state) => ({
  getAvailDetailState: {
    status: state.getAvailDetailReducer.status,
    error: state.getAvailDetailReducer.error,
    data: state.getAvailDetailReducer.data
  },
  postBookingState: {
    status: state.postBookingReducer.status,
    error: state.postBookingReducer.error,
    data: state.postBookingReducer.data
  }
});

export default compose(
  connect(
    mapStateToProps, { 
      getAvailDetail, 
      postBooking,
      resetData
    }
  )
)(ClassDetail);
