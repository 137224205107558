import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useHistory, useParams } from "react-router-dom";
import PropTypes from 'prop-types';
import * as yup from 'yup';

import { errorScroll } from '../../../helpers/functions';
import { convertArrayToObject } from '../../../helpers';

import Header from '../../../components/Header';
import Button from '../../../components/Button';
import Loading from '../../../components/Loading';
import Modal from '../../../components/Modal';
import useSnackbar from '../../../components/Snackbar/functions';
import FormMember from './form';

import { getMemberDetail, 
  postMemberDetail, 
  resetPostMemberDetail, 
  deleteMember, 
  resetdeleteMember 
} from '../action';

import './styles.scss';
import moment from 'moment';

const EditMember = ({ 
  i18n, 
  getMemberDetailState,
  postMemberDetailState,
  getMemberDetail,
  postMemberDetail,
  resetPostMemberDetail,
  deleteMemberState,
  deleteMember,
  resetdeleteMember,
}) => {
  const history = useHistory();

  const [ form, setForm ] = useState();
  const [ errors, setErrors ] = useState();
  const { showSnackbar } = useSnackbar();
  const [ loading, setLoading ] = useState(false);
  const [ showModal, setShowModal ] = useState(false)
  
  const { id } = useParams();
  const memberDetail = getMemberDetailState?.data || {};

  const handlerChanges = (value, key, errorKey) => {
    setForm({ ...form, [key]: value });
    let errorMessage = { ...errors, [errorKey ? errorKey : key]: undefined };

    setErrors(errorMessage);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const params = { ...form }

    let schema = yup.object().shape({
      name: yup.string().required('required'),
      email: yup.string().email('email'),
      instagram: yup.string(),
      birth: yup.date(),
      gender: yup.string()
    });

    schema
      .validate(params, { abortEarly: false })
      .then(() => {
        setLoading(true);
        const tmpParams = {
          ...params,
          birth: moment(params.birth).format('YYYY-MM-DD'),
          isApproved: params.isApproved
        }

        postMemberDetail(id, tmpParams)
      })
      .catch((err) => {
        setErrors(convertArrayToObject(err.inner, 'path', 'message'));
        errorScroll();
      });
  };

  const handleDeleteMember = () => {
    deleteMember(id);
    setLoading(true);
    setShowModal(false);
  }

  useEffect(() => {
    if (getMemberDetailState.status === 'resolve' && String(id) === String(memberDetail?.id)) {
      setForm({
        name: memberDetail?.name,
        email: memberDetail?.email,
        instagram: memberDetail?.instagram,
        kuota: memberDetail?.kuota,
        gender: memberDetail?.gender,
        birth: memberDetail.birth ? new Date(memberDetail.birth) : undefined,
        phone: memberDetail?.phone.replace("+62", ''),
        isApproved: memberDetail?.isApproved
      })
      setLoading(false)
    } else if (getMemberDetailState.status === 'rejected') {
      showSnackbar({
        message: "Data member tidak ditemukan. Mohon refresh halaman ini",
        type: 'error'
      })
      setLoading(false)
      history.push('/admin/member');
    }
  }, [getMemberDetailState.status])

  useEffect(() => {
    if (id) {
      setLoading(true)
      getMemberDetail(id)
    }
  }, [id])

  useEffect(() => {
    if (loading) {
      const { status, error } = postMemberDetailState;

      if (status === 'resolve') {
        setLoading(false)
        showSnackbar({
          message: i18n(`member_admin.success${id ? '':'_create'}`),
          type: 'success'
        })
        resetPostMemberDetail()
        history.push('/admin/member')
        setLoading(false)
      } else if(status === 'rejected'){
        const errMessage = error?.response?.data?.message
        setLoading(false)
        showSnackbar({
          message: i18n(`error_message.${errMessage}`),
          type: 'error'
        })
        resetPostMemberDetail()
        setLoading(false)
      }
    }
  }, [loading, postMemberDetailState])

  useEffect(() => {
    if (loading) {
      const { status, error } = deleteMemberState;

      if (status === 'resolve') {
        setLoading(false)
        showSnackbar({
          message: i18n(`member_admin.success_delete`),
          type: 'success'
        })
        resetdeleteMember()
        history.push('/admin/member')
        setLoading(false)
      } else if(status === 'rejected'){
        const errMessage = error?.response?.data?.message
        setLoading(false)
        showSnackbar({
          message: i18n(`error_message.${errMessage}`),
          type: 'error'
        })
        resetdeleteMember()
        setLoading(false)
      }
    }
  }, [loading, deleteMemberState])
  
  return (<>
    <Header label={i18n('label.edit_member')} onBackClick={() => {
      history.push(`/admin/member`);
    }}/>
    {(loading || getMemberDetailState.status === 'pending') && <Loading/>}
    <div className='edit-member'>
      <form onSubmit={handleSubmit} autoComplete="off">
        {/* <Button color="pink" classname='history-btn' flat onClick={() => history.push(`/admin/member/${id}/history`)}>Lihat History Anggota</Button> */}
        <FormMember
          i18n={i18n}
          form={form}
          handlerChanges={handlerChanges}
          errors={errors}
          memberApprove={memberDetail?.isApproved}
          handleDeleteBtn={() => setShowModal(true)}
        />

        <Button classname='submit-form' type='submit' color="pink">{i18n(`cta.save`)}</Button>
      </form>
    </div>
    
    <Modal
      onClose={() => setShowModal(false)}
      isOpen={showModal}
      hasCloseIcon
      buttonSecondaryActionHandler={() => setShowModal(false)}
      buttonActionHandler={handleDeleteMember}
      buttonText={i18n('cta.yes_sure')}
      buttonSecondaryText={i18n('cta.cancel')}
      title={i18n('confirm_modal.title')}
      portalId="class-confirm"
    >
      <div className="title-confirm">{i18n('confirm_modal.title_delete_member')}</div>
      <div className="detail-class-confirm">
        <div className="title">{form?.name}</div>
        <div className="detail">
          <div className="phone">{memberDetail?.phone}</div>
        </div>
      </div>
    </Modal>
  </>);
};

EditMember.propTypes = {
  setLang: PropTypes.func,
  history: PropTypes.object,
  i18n: PropTypes.func,
  getMemberDetailState: PropTypes.object,
  postMemberDetailState: PropTypes.object,
  getMemberDetail: PropTypes.func,
  postMemberDetail: PropTypes.object,
  resetPostMemberDetail: PropTypes.object,
  deleteMemberState:PropTypes.object,
  deleteMember: PropTypes.func, 
  resetdeleteMember: PropTypes.func,
};

const mapStateToProps = (state) => ({
  lang: state.langReducer.lang,
  getMemberDetailState: {
    status: state.getMemberDetailReducer.status,
    error: state.getMemberDetailReducer.error,
    data: state.getMemberDetailReducer.data
  },
  postMemberDetailState: {
    status: state.postMemberDetailReducer.status,
    error: state.postMemberDetailReducer.error,
    data: state.postMemberDetailReducer.data
  },
  deleteMemberState: {
    status: state.deleteMemberReducer.status,
    error: state.deleteMemberReducer.error,
    data: state.deleteMemberReducer.data
  }
});

export default compose(connect(mapStateToProps, {
  getMemberDetail, 
  postMemberDetail, 
  resetPostMemberDetail,
  deleteMember, 
  resetdeleteMember 
}))(EditMember);
