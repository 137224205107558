import { bool, func, number, oneOf, string } from 'prop-types';
import React, {
  forwardRef,
  useEffect,
  useRef,
  useState,
  useContext,
} from 'react';
import cn from 'classnames';
import useSnackbar from './functions';

import './styles.scss';

export const ANIMATION_DURATION = 225;

const ANIMATIONS = {
  SLIDE: 'slide',
  GROW: 'grow',
};

const TYPES = {
  DEFAULT: 'default',
  ERROR: 'error',
};

const Snackbar = forwardRef((props, ref) => {
  const {
    action,
    actionText,
    anchorElementId,
    bottom,
    isMobile,
    message,
    onClose,
    timeout,
    type,
  } = props;
  

  const Context = React.createContext({});

  const context = useContext(Context);
  const mobile = context.isMobile !== undefined ? context.isMobile : isMobile;
  const device = mobile ? 'mobile' : 'desktop';
  const { queue } = useSnackbar();
  const [cname, setClassName] = useState({
    visible: false,
  });

  // Reduce dom element complexity for performance optimization https://web.dev/dom-size/
  const [isAttached, attachElement] = useState(props.show);
  const [animationOut, setAnimationOut] = useState(ANIMATIONS.SLIDE);
  const [offsetBottom, setOffsetBottom] = useState(bottom);
  const mounted = useRef(false);

  const calculateOffsetBottom = () => {
    let offset = 0;
    if (anchorElementId && document.getElementById(anchorElementId)) {
      offset =
        window.innerHeight -
        document.getElementById(anchorElementId).getBoundingClientRect().y;
    }
    setOffsetBottom(bottom + offset);
  };

  const show = () => {
    calculateOffsetBottom();
    attachElement(true);

    // Wait dom attached
    setTimeout(() => {
      setClassName({ ...cname, visible: true });
    }, 50);

    setTimeout(hide, Math.max(0, timeout));
  };

  const hide = () => {
    const didUnmount = mounted.current === false;
    if (didUnmount) {
      return;
    }

    setClassName({
      ...cname,
      animating: true,
    });

    setTimeout(() => {
      setClassName({
        ...cname,
        animating: false,
        visible: false,
      });
      onClose();
      attachElement(false);
    }, ANIMATION_DURATION);
  };

  useEffect(() => {
    if (props.show === cname.visible) {
      return;
    } else if (props.show) {
      show();
    } else {
      hide();
    }
  }, [props.show]);

  useEffect(() => {
    const isMultipleSnacbars = queue.length > 1;
    if (isMultipleSnacbars) setAnimationOut(ANIMATIONS.GROW);
  }, [queue]);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  return (
    isAttached && (
      <div
        style={{ bottom: `${offsetBottom}px` }}
        className={cn(
          'snackbar',
          'v4',
          `type-${type}`,
          `out-${animationOut}`,
          cname,
          device,
          { 'has-action': actionText }
        )}
        ref={ref}
      >
        <span className="message">
          {message}
        </span>
        {actionText && (
          <button onClick={action || hide}>
            <span b1 bold invert>
              {actionText}
            </span>
          </button>
        )}
      </div>
    )
  );
});

Snackbar.propTypes = {
  /** CTA button action */
  action: func,
  /** CTA button text */
  actionText: string,
  /** Anchor element id, example Bottom Navigation */
  anchorElementId: string,
  /** Offset bottom */
  bottom: number,
  message: string.isRequired,
  /** Function to set show prop value in the parent component */
  onClose: func.isRequired,
  show: bool,
  /** Snackbar duration */
  timeout: number,
  type: oneOf(Object.values(TYPES)),
  /** Render as mobile/desktop component */
  isMobile: bool,
};

Snackbar.defaultProps = {
  bottom: 20,
  isMobile: true,
  show: false,
  timeout: 4000,
  type: TYPES.DEFAULT,
};

Snackbar.TYPES = TYPES;

export default Snackbar;
