export const errorScroll = () => {
  const firstErrorElement = document.querySelectorAll('.err-message')[0];

  firstErrorElement &&
    firstErrorElement.scrollIntoView &&
    firstErrorElement.scrollIntoView(true);
  window && window.scrollBy(0, -100);
};

export const errorAlertScroll = () => {
  const firstErrorElement = document.querySelectorAll('.alert')[0];

  firstErrorElement &&
    firstErrorElement.scrollIntoView &&
    firstErrorElement.scrollIntoView(true);
  window && window.scrollBy(0, -100);
};

export const parseQuery = (subject) => {
  const results = {};
  const parser = /[^&?]+/g;
  let match = parser.exec(subject);

  while (match !== null) {
    const parts = match[0].split('=');

    results[parts[0]] = parts[1];
    match = parser.exec(subject);
  }

  return results;
};

export const miliseconds = (hrs = 0,min = 0,sec = 0) => {
  return (hrs*60*60+min*60+sec)*1000;
}

export const sort_date = (array, dateKey, type) => {
  return array.sort((a,b) => {
    if (type === 'dsc') {
      return new Date(b[dateKey]) - new Date(a[dateKey]);
    } else {
      return new Date(a[dateKey]) - new Date(b[dateKey]);
    }
  });
}