import * as yup from 'yup';

const validation = {
  name: yup.string().required('required'),
  type: yup.string().required('required'),
  class: yup.string().required('required'),
  capacity: yup.number().required('required'),
  duration: yup.string().required('required'),
  desc_class: yup.string().required('required')
}

export {
  validation
}