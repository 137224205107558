import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './styles.scss';

const TimeInput = ({
  label,
  sublabel,
  placeholder,
  errorMessage,
  inputProps,
  value,
  className = '',
  disable = false,
  inputMode
}) => {
  const [focus, setFocus] = useState(false);

  const additionalInputProps = {
    onBlur: () => setFocus(false),
    onFocus: () => setFocus(true)
  }

  return (
    <div
      className={cn(`input-time-container ${className}`, {
        'err': errorMessage,
        'hanging-label': true,
        disable,
        'text': inputMode === 'textarea'
      })}
    >
      {label && (
        <div className={cn('label', {
          'active': true,
        })}>
          {label}
          {sublabel && <div className='sublabel'>{sublabel}</div>}
        </div>
      )}
      <div className='input-wrap'>
        <div className='input-group'>
          <input
            type='text'
            placeholder={placeholder}
            name="hh"
            value={value?.hh || ''}
            readOnly={disable}
            maxLength={2}
            {...additionalInputProps}
            {...inputProps}
          />
          <span className="divider">:</span>
          <input
            type='text'
            placeholder={placeholder}
            name="mm"
            value={value?.mm || ''}
            readOnly={disable}
            maxLength={2}
            {...additionalInputProps}
            {...inputProps}
          />
        </div>
        {errorMessage && <div className='err-message'>{errorMessage}</div>}
      </div>
    </div>
  );
};

TimeInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.any,
  inputProps: PropTypes.object,
  value: PropTypes.string,
  className: PropTypes.string,
  sublabel: PropTypes.any,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  inputMode: PropTypes.string
};

export default TimeInput;
