import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';

import './styles.scss';

const FooterNavBar = ({ i18n }) => {
  const history = useHistory();
  const navList = [
    {keyLang: 'class_regis', link: '/class'},
    {keyLang: 'my_schedule', link: '/schedule'},
    {keyLang: 'account', link: '/account'},
    {keyLang: 'help', link: '/help'},
  ]

  return (
    <footer className='footer-nav-bar-wrap'>
      {navList.map(({ keyLang, link }, idx) => (
        <div className="footer-menu" key={idx} onClick={() => history.push(link)}>
          <span className="nav-icon" id={keyLang}></span>
          {i18n(`label.${keyLang}`)}
        </div>
      ))}
    </footer>
  )
}

FooterNavBar.propTypes = {
  route: PropTypes.object,
  i18n: PropTypes.func
};

export default FooterNavBar;
