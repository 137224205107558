import React, { useEffect, useState } from 'react';

import Icon from '../../../../components/Icon';

import './styles.scss';

const SearchBar = ({
  label,
  inputType,
  placeholder,
  onSubmit,
  i18n,
  defaulValue
}) => {
  const [ keyword, setKeyword ] = useState();
  const [ nameKeyword, setNameKeyword ] = useState();

  const resetSearch = () => {
    setKeyword('');
    setNameKeyword('');
    onSubmit();
  }

  useEffect(() => {
    if (defaulValue) {
      if (defaulValue?.name !== nameKeyword && !nameKeyword) {
        setNameKeyword(defaulValue?.name)
      }
      if (defaulValue?.phone !== keyword && !keyword) {
        setKeyword(defaulValue?.phone)
      }
    }
  }, [defaulValue])

  return (
    <div className="top search-bar-member">
      <div className="label">
        {label}
      </div>
      <div className="search-bar">
        <form onSubmit={(e) => {
          e.preventDefault();
          onSubmit(keyword, nameKeyword);
        }}>
          <div className="search-input-wrap">
            <input 
              type='text'
              value={keyword} 
              onChange={e => setKeyword(e.target.value)}
              placeholder={i18n('member_admin.placeholder_search')}
            />
          </div>
          <div className="search-input-wrap">
            <input 
              type='text'
              value={nameKeyword} 
              onChange={e => setNameKeyword(e.target.value)}
              placeholder={i18n('member_admin.placeholder_search_name')}
            />
          </div>

          <input type="submit" hidden />
          {(keyword || nameKeyword) && 
            <Icon icon={`close`} onClick={resetSearch}/>
          }
          <Icon icon={`search pink`} onClick={() =>onSubmit(keyword)}/>
        </form>
      </div>
    </div>  
  );
};

export default SearchBar;
