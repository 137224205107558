import React, { useState } from 'react';
import cn from 'classnames';

import Icon from '../../Icon';

import '../styles.scss'
import './styles.scss'

const CardCollapse = ({
  color,
  className,
  detailChild,
  summaryChild,
  defaultOpen = false
}) => {
  const [ openDetail, setOpenDetail ] = useState(defaultOpen)

  return (
    <div className={cn("card card-collapse", className, color, {
      'open': openDetail
    })}
      onClick={() => setOpenDetail(!openDetail)}
    >
      <div className="summary">
        {summaryChild}
        <Icon 
          icon={cn("arrow", "white", {
            'down': openDetail,
            'left': !openDetail
          })}
          onClick={() => setOpenDetail(!openDetail)}
        />
      </div>
      {openDetail && 
      <div className="detail">
        {detailChild}
      </div>
      }
    </div>
  );
};

export default CardCollapse;
