import { apiCaller } from '../../helpers/apiCaller';

// ClassCategoryList
const getClassCategoryListRequest = () => ({
  type: 'GET_CLASS_CATEGORY_LIST_REQUEST'
});

const getClassCategoryListSuccess = (data) => ({
  type: 'GET_CLASS_CATEGORY_LIST_SUCCESS',
  payload: data
});

const getClassCategoryListFailure = (error) => ({
  type: 'GET_CLASS_CATEGORY_LIST_FAILURE',
  payload: error
});

const getClassCategoryList = () => {
  return (dispatch) => {
    dispatch(getClassCategoryListRequest());
    return apiCaller('v1/class-category', 'get')
      .then((data) => {
        if (data?.response?.status) {
          return dispatch(getClassCategoryListFailure(data));
        }
        return dispatch(getClassCategoryListSuccess(data));
      })
      .catch((error) => {
        dispatch(getClassCategoryListFailure(error))
      });
  };
};


// Search Avail
const getSearchAvailRequest = () => ({
  type: 'GET_SEARCH_AVAIL_REQUEST'
});

const getSearchAvailSuccess = (data) => ({
  type: 'GET_SEARCH_AVAIL_SUCCESS',
  payload: data
});

const getSearchAvailFailure = (error) => ({
  type: 'GET_SEARCH_AVAIL_FAILURE',
  payload: error
});

const getSearchAvail = (parameters) => {
  return (dispatch) => {
    dispatch(getSearchAvailRequest());
    return apiCaller('avail/search', 'get', undefined, false, parameters)
      .then((data) => {
        if (data?.response?.status) {
          return dispatch(getSearchAvailFailure(data));
        }
        return dispatch(getSearchAvailSuccess(data));
      })
      .catch((error) => {
        dispatch(getSearchAvailFailure(error))
      });
  };
};

export { getClassCategoryList,  getSearchAvail};
