import React from 'react';
import cn from 'classnames';

import './styles.scss';

const BoxSplash = ({
  active,
  desc,
  image,
  imageAlt,
  title,
}) => {
  
  return (
    <div className={cn('box-splash-wrap', { active })}>
      <img src={image} alt={imageAlt} height="300"/>
      <div className="label">{title}</div>
      <div className="desc">{desc}</div>
    </div>
  );
};

export default BoxSplash;
