import axios from 'axios';

const baseUrl = process.env.REACT_APP_BACKEND_URI;

export const apiCaller = (
  endpoint,
  method = 'get',
  body,
  isAdmin = false,
  params,
  otherProps = {}
) => {
  const accessToken = localStorage.getItem('accessToken');
  const authToken = {
    'Authorization': `Bearer ${accessToken}`,
  };

  return axios({
    method,
    url: `${baseUrl}/${endpoint}`,
    headers: {
        ...(accessToken ? { 
          ...authToken,
        } : {}),
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Credentials': 'true',
        'Content-Type': 'application/json',
    },
    ...(body ? { data: body } : {}),
    ...(params ? { params } : {}),
    ...otherProps
  })
  .then((res) => {
    return res;
  })
  .catch((err) => {
    const errReturn = {
      response: {
        data: {
          ...err?.response?.data,
          message: err?.message,
          isError: true
        }
      }
    }

    if (!err?.response) {
      // need to handle network error send to layout
      return errReturn
    }

    const errMessage = err?.response?.data?.message;
    const errErrorKey = err?.response?.data?.error;
    
    if ((errMessage === 'Unauthorized' || errErrorKey === 'Token is expired') && endpoint=== 'v1/account/info') {
      localStorage.removeItem('accessToken');
      localStorage.setItem('INVALID_TOKEN', 'show message');
      window.location.href = '/admin/login';
    }
    return errReturn;
  });
};
