import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useHistory, useParams } from "react-router-dom";
import PropTypes from 'prop-types';
import * as yup from 'yup';

import { errorScroll } from '../../../helpers/functions';
import { convertArrayToObject } from '../../../helpers';
import { validation } from '../functions';

import Header from '../../../components/Header';
import Button from '../../../components/Button';
import Loading from '../../../components/Loading';
import Modal from '../../../components/Modal';
import FormClass from '../Form';
import useSnackbar from '../../../components/Snackbar/functions';

import { getClassDetail, 
  postClassDetail, 
  resetPostClassDetail, 
  resetClassDetail,
  deleteClass,
  resetDeleteClass
} from '../action';

const CreateEditlass = ({ 
  i18n, 
  getClassDetail,
  classDetailState,
  postClassDetail,
  postClassDetailState,
  resetClassDetail,
  deleteClass,
  resetDeleteClass,
  deleteClassState,
}) => {
  const history = useHistory();
  const [ form, setForm ] = useState();
  const [ loading, setLoading ] = useState(false);
  const [ errors, setErrors ] = useState();
  const [ showModal, setShowModal ] = useState(false);
  const { id } = useParams();
  const isEdit = !!id;
  const { showSnackbar } = useSnackbar();

  const classDetail = classDetailState?.data || {};

  const handleSubmit = (e) => {
    e.preventDefault();
    const params = { ...form }

    let schema = yup.object().shape(validation);

    schema
      .validate(params, { abortEarly: false })
      .then(() => {
        setLoading(true);
        postClassDetail(id, {
          "capacity": Number(params?.capacity),
          "categoryId": params?.class,
          "description": params?.desc_class,
          "duration": params?.duration,
          "name": params?.name,
          "type": params?.type,
          "location": params?.location,
          "locationUrl": params?.location_url
        })
      })
      .catch((err) => {
        setErrors(convertArrayToObject(err.inner, 'path', 'message'));
        errorScroll();
      });
  };

  const handlerChanges = (value, key, errorKey) => {
    setForm({ ...form, [key]: value });
    let errorMessage = { ...errors, [errorKey ? errorKey : key]: undefined };

    setErrors(errorMessage);
  };

  const handleDelete = () => {
    if (showModal) {
      setLoading(true)
      setShowModal(false)
      deleteClass(id)
    } else {
      setShowModal(true)
    }
  }

  useEffect(() => {
    if (id) {
      setLoading(true)
      getClassDetail(id)
    }

    return () => {
      resetClassDetail();
    }
  }, [id])

  useEffect(() => {
    if (classDetail && String(classDetail?.id) === String(id)) {
      setForm({
        name: classDetail?.name,
        type: classDetail?.type,
        class: classDetail?.categoryId,
        capacity: classDetail?.capacity,
        duration: classDetail?.duration,
        desc_class: classDetail?.description,
        location: classDetail?.location,
        location_url: classDetail?.locationUrl
      })
      setLoading(false)
    }
  }, [classDetail])

  useEffect(() => {
    if (loading) {
      const { status, error } = postClassDetailState;

      if (status === 'resolve') {
        setLoading(false)
        showSnackbar({
          message: i18n(`class_admin.success${id ? '':'_create'}`),
          type: 'success'
        })
        resetPostClassDetail()
        history.push('/admin/class')
      } else if(status === 'rejected'){
        const errMessage = error?.response?.data?.message
        setLoading(false)
        showSnackbar({
          message: i18n(`error_message.${errMessage}`),
          type: 'error'
        })
        resetPostClassDetail()
      }
    }

  }, [loading, postClassDetailState])

  useEffect(() => {
    if (loading) {
      const { status, error } = deleteClassState;

      if (status === 'resolve') {
        setLoading(false)
        showSnackbar({
          message: i18n(`class_admin.success_delete`),
          type: 'success'
        })
        resetDeleteClass()
        history.push('/admin/class')
      } else if(status === 'rejected'){
        const errMessage = error?.response?.data?.message
        setLoading(false)
        showSnackbar({
          message: i18n(`error_message.${errMessage}`),
          type: 'error'
        })
        resetPostClassDetail()
      }
    }

  }, [loading, deleteClassState])
  
  return (<>
    <Header label={i18n(`label.${isEdit ? 'edit':'create'}_class`)}/>
    {loading && <Loading/>}
    <form onSubmit={handleSubmit} autoComplete="off">
      <div className='create-class-admin' style={{
        padding: "33px 32px 16px",
        boxSizing: "border-box"
      }}>
        <FormClass
          i18n={i18n}
          form={form}
          handlerChanges={handlerChanges}
          errors={errors}
        />
        {isEdit && 
          <Button color="purple" type="button" style={{ width: '100%', marginTop: "30px", marginBottom: "30px" }} onClick={() => handleDelete()}>{i18n("cta.delete")}</Button>
        }
      </div>
      <footer className='member-footer'>
        <Button color="pink" type='submit'>{i18n("cta.save")}</Button>
      </footer>
  </form> 
  <Modal
    onClose={() => setShowModal(false)}
    isOpen={showModal}
    hasCloseIcon
    buttonSecondaryActionHandler={() => setShowModal(false)}
    buttonActionHandler={handleDelete}
    buttonText={i18n('cta.yes_sure')}
    buttonSecondaryText={i18n('cta.cancel')}
    title={i18n('confirm_modal.title_delete_class')}
    portalId="class-confirm-delete"
  />
  </>);
};

CreateEditlass.propTypes = {
  setLang: PropTypes.func,
  history: PropTypes.object,
  i18n: PropTypes.func,
  getClassDetail: PropTypes.func,
  classDetailState: PropTypes.array,
  postClassDetail: PropTypes.func,
  postClassDetailState: PropTypes.array,
  deleteClass: PropTypes.func,
  resetDeleteClass: PropTypes.func,
  deleteClassState: PropTypes.object,
};

const mapStateToProps = (state) => ({
  lang: state.langReducer.lang,
  classDetailState: {
    status: state.getClassDetailReducer.status,
    error: state.getClassDetailReducer.error,
    data: state.getClassDetailReducer.data
  },
  postClassDetailState: {
    status: state.postClassDetailReducer.status,
    error: state.postClassDetailReducer.error,
    data: state.postClassDetailReducer.data
  },
  deleteClassState: {
    status: state.deleteClassReducer.status,
    error: state.deleteClassReducer.error,
    data: state.deleteClassReducer.data
  }
});

export default compose(connect(mapStateToProps, {
  getClassDetail,
  postClassDetail,
  resetPostClassDetail,
  resetClassDetail,
  deleteClass,
  resetDeleteClass
}))(CreateEditlass);
