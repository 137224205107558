import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { compose } from 'redux';

import DatepickerDropdown from '../../../components/Dropdown/Datepicker';
import ListDropdown from '../../../components/Dropdown/List';
import Checkbox from '../../../components/Input/Checkbox';
import TimeInput from '../../../components/Input/TimeInput';
import TextInput from '../../../components/Input/TextInput';

import { getInstructurList } from '../action';
import { getClassList } from '../../Class/action';

import './styles.scss';

const FormClass = ({ 
  i18n, 
  form, 
  handlerChanges, 
  errors, 
  isEdit,
  getInstructurListState,
  getInstructurList,
  classListState,
  getClassList,
  setCapacityClass
}) => {
  const instructurList = getInstructurListState?.data || [];
  const classList = classListState?.data || [];

  const datePickerProps = {
    i18n,
    iconInput: "calendar",
    minDate: new Date(moment().add(1, 'day').format('YYYY-MM-DD')),
    maxDate: new Date(moment().add(4, 'month').set('date', 1).subtract(1, 'day').format('YYYY-MM-DD')),
    formatDateValue: "dddd, DD MMM YYYY"
  }

  useEffect(() => {
    getInstructurList();
    getClassList();
  }, [])

  useEffect(() => {
    if (form?.class) {
      const detailClass = classList.find(({ id }) => id === form.class)
      handlerChanges(detailClass?.duration, 'duration');
    }
  }, [classList, form?.class])

  useEffect(() => {
    if (isEdit && classList && form?.class) {
      const findClassDetail = classList.find(({ id }) => String(id) === String(form?.class));
      findClassDetail && setCapacityClass(findClassDetail?.capacity)
    }
  }, [isEdit, classList, form?.class])
  
  return (
    <div className='schedule-form-admin box'>
      <ListDropdown
        i18n={i18n}
        placeholder={i18n('label.class')}
        labelDrawer={i18n('label.select', { label: i18n('label.class')})}
        value={form?.class} 
        onChange={val => handlerChanges(val, 'class')}  
        iconInput="class"
        options={classList}
        valueKey="id"
        labelKey='name'
        withHangingLabel={false}
        errorMessage={errors?.class && i18n(`validation.${errors?.class}`)}
        disable={isEdit}
      />
      <DatepickerDropdown
        {...datePickerProps}
        placeholder={i18n('label.date')}
        labelDrawer={i18n('label.select', { label: i18n('label.date')})}
        value={form?.startDate} 
        onChange={(date) => handlerChanges(date, 'startDate') }  
        withHangingLabel={false}
        errorMessage={errors?.startDate && i18n(`validation.${errors?.startDate}`)}
        disabled={!isEdit}
      />
      {!isEdit && 
      <Checkbox
        className='repeat-class'
        label={<span className="label">{i18n('label.repeat_class')}</span>}
        checked={form?.repeat_class}
        onChange={() => handlerChanges(!form?.repeat_class, 'repeat_class')}
        errorMessage={errors?.repeat_class && i18n(`validation.${errors?.repeat_class}`)}
      />}
      {form?.repeat_class && 
      <DatepickerDropdown
        {...datePickerProps}
        placeholder={i18n('label.end_date')}
        labelDrawer={i18n('label.select', { label: i18n('label.end_date')})}
        value={form?.endDate} 
        onChange={(date) => handlerChanges(date, 'endDate') }  
        withHangingLabel={false}
        errorMessage={errors?.endDate && i18n(`validation.${errors?.endDate}`)}
      />
      }
      <div className="time-container">
        <TimeInput
          label={i18n('label.start_time')}
          value={form?.start_time}
          inputProps={{
            onChange: (e) => {
              let isValid = false;
              const tmpTime = {...form?.start_time};
              tmpTime[e.target.name] = e.target.value

              if (e.target.value < 24 && e.target.name === 'hh') {
                isValid = true
              } else if (e.target.value < 60 && e.target.name === 'mm') {
                isValid = true
              }

              if (isValid) {
                handlerChanges(tmpTime, 'start_time', `start_time.${e.target.name}`)
              }
            }
          }}
          withHangingLabel
          errorMessage={(errors?.['start_time.hh'] || errors?.['start_time.mm']) && i18n(`validation.${errors?.['start_time.hh'] || errors?.['start_time.mm']}`)}
        />
        <TimeInput
          label={i18n('label.end_time')}
          value={form?.end_time}
          inputProps={{
            onChange: (e) => {
              let isValid = false;
              const tmpTime = {...form?.end_time};
              tmpTime[e.target.name] = e.target.value

              if (e.target.value < 24 && e.target.name === 'hh') {
                isValid = true
              } else if (e.target.value < 60 && e.target.name === 'mm') {
                isValid = true
              }

              if (isValid) {
                handlerChanges(tmpTime, 'end_time', `end_time.${e.target.name}`)
              }
            }
          }}
          disable={true}
          withHangingLabel
          errorMessage={(errors?.['end_time.hh'] || errors?.['end_time.mm']) && i18n(`validation.${errors?.['end_time.hh'] || errors?.['end_time.mm']}`)}
        />
      </div>
      <ListDropdown
        i18n={i18n}
        placeholder={i18n('label.instructor')}
        labelDrawer={i18n('label.select', { label: i18n('label.instructor')})}
        value={form?.instructor} 
        onChange={val => handlerChanges(val, 'instructor')}  
        iconInput="user"
        options={instructurList}
        valueKey="id"
        labelKey='name'
        withHangingLabel={false}
        errorMessage={errors?.instructor && i18n(`validation.${errors?.instructor}`)}
      />
      <TextInput 
        placeholder={i18n('label.min_attendance')}
        value={form?.min_attendance}
        inputProps={{
          type: "number",
          onChange: (e) => handlerChanges(e.target.value, 'min_attendance')
        }}
        iconLeft="people"
        onChange={val => handlerChanges(val, 'min_attendance')}  
        withHangingLabel={false}
        errorMessage={errors?.min_attendance && i18n(`validation.${errors?.min_attendance}`)}
      />
    </div>
  );
};

FormClass.propTypes = {
  setLang: PropTypes.func,
  history: PropTypes.object,
  i18n: PropTypes.func,
  getInstructurListState: PropTypes.object,
  getInstructurList: PropTypes.func,
  classListState: PropTypes.array,
  getClassList: PropTypes.func
};

const mapStateToProps = (state) => ({
  lang: state.langReducer.lang,
  getInstructurListState: {
    status: state.getInstructurListReducer.status,
    error: state.getInstructurListReducer.error,
    data: state.getInstructurListReducer.data
  },
  classListState: {
    status: state.getClassListReducer.status,
    error: state.getClassListReducer.error,
    data: state.getClassListReducer.data
  }
});

export default compose(connect(mapStateToProps, {
  getInstructurList,
  getClassList,
}))(FormClass);
