import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';

import AccountHeader from './components/Header';
import Button from '../../components/Button';
import TextInput from '../../components/Input/TextInput';
import useSnackbar from '../../components/Snackbar/functions';
import Loading from '../../components/Loading';

import './styles.scss';

const Account = ({
  i18n,
  accountState
}) => {
  const history = useHistory();
  const { data: { name, phone  } } = accountState;
  const { showSnackbar } = useSnackbar();
  const [ loading, setLoading ] = useState(false);

  const showSnackbarBy = (message, type) => {
    showSnackbar({
      message: message,
      type: type
    })
  }

  const logoutHandler = () => {
    localStorage.removeItem('accessToken');
    setLoading(true)

    showSnackbar({
      message: i18n('profile_page.logout_success'),
      type: 'success'
    }) 
    
    setTimeout(() => {
      history.push('/login')
    }, 300)
  }

  const userMenu = [
    // { keyLang: 'change_phone', link: '/account/change-phone', icon: 'account' },
    { keyLang: 'change_password', link: '/account/change-password', icon: 'password' },
    { keyLang: 'term_condition', link: '/help/term-and-condition', icon: 'document' }
  ]

  useEffect(() => {
    // getAccountInfo();

    if (accountState?.status === 'rejected') {
      let errMessage = `${i18n('common_apicall.server_problem')} `;
      switch(accountState?.error?.response?.status) {
        case 409:
          errMessage += `${i18n('register_page.step_1.conflict')}`
          break;
        case 429:
          errMessage += `${i18n('register_page.step_1.too_many_request')}`
          break;
        default:
          errMessage += accountState?.error?.response?.data?.message
      }
      showSnackbarBy(errMessage, 'error')
    }
  }, []);

  return (<>
    {loading && <Loading/>}
    <AccountHeader i18n={i18n} name={name} history={history} onBackDest={'/'}/>
    <section className='profile-account-container'>
      <div className="user-phone-detail">
        <div className="name">{name}</div>
        <div className="phone">{phone}</div>
      </div>

      <div className="user-menu">
        {userMenu.map(({ keyLang, link, icon }, idx) => (
          <TextInput
            key={idx}
            placeholder={i18n(`profile_page.${keyLang}`)}
            inputProps={{
              onClick: () => history.push(link)
            }}
            withHangingLabel={false}
            iconLeft={icon}
            iconRight="arrow right dark-grey"
          />
        ))}
      </div>

      {/* todo pop up confirmation logout, logout kadang ngebug */}
      <Button color="pink" id='logout' onClick={() => logoutHandler()}>{i18n('profile_page.logout')}</Button>
    </section>
  </>);
};

Account.propTypes = {
  accountState: PropTypes.object
};

export default Account;
