import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';

import Header from '../../components/Header';
import SearchBar from '../components/SearchBar';
import Button from '../../components/Button';
import Loading from '../../components/Loading';

import { getInstructorList } from './action';

import './styles.scss';

const Instructor = ({ 
  i18n, 
  instructorListState,
  getInstructorList,
}) => {
  const history = useHistory();
  const [ loadingSearch, setLoadingSearch ] = useState(false);
  
  const instructorList = instructorListState?.data || [];

  const handlerSearchSubmit = (searchName) => {
    getInstructorList({
      searchName
    })
    setLoadingSearch(true)
  }

  useEffect(() => {
    setLoadingSearch(true)
    getInstructorList();
  }, []);

  useEffect(() => {
    if (loadingSearch && instructorListState.status === 'resolve') {
      setLoadingSearch(false)
    }
  }, [loadingSearch, instructorListState.status])
  
  return (<>
    <Header label={i18n('label.instructor_regis')} onBackClick={() => history.push('/admin')}/>
    {(loadingSearch || instructorListState?.status === 'pending') && <Loading/>}
    <div className='instructor-admin'>
      <div className="instructor-list-container">
        <div className="top">
          <div className="label">{i18n('label.instructor_regis')}</div>
        </div>
        <div className="instructor-list">
          {instructorList.map((instructorItem, idx) => !instructorItem?.isDeleted && (
            <div className="instructor-wrap" key={idx} onClick={() => history.push(`/admin/instructor/edit/${instructorItem?.id}`)}>
              {instructorItem.name}
            </div>
          ))}
        </div>
      </div>
    </div>
    <footer className='member-footer'>
      <Button color="pink" onClick={() => history.push('/admin/instructor/create')}><span className="add-icon">+</span>{i18n("cta.add_instructor")}</Button>
    </footer>
  </>);
};

Instructor.propTypes = {
  setLang: PropTypes.func,
  history: PropTypes.object,
  i18n: PropTypes.func,
  instructorListState: PropTypes.object,
  getInstructorList: PropTypes.func
};

const mapStateToProps = (state) => ({
  lang: state.langReducer.lang,
  instructorListState: {
    status: state.getInstructorListReducer.status,
    error: state.getInstructorListReducer.error,
    data: state.getInstructorListReducer.data
  }
});

export default compose(connect(mapStateToProps, {
  getInstructorList
}))(Instructor);
