import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useHistory, useParams } from "react-router-dom";
import PropTypes from 'prop-types';
import InfiniteScroll from "react-infinite-scroll-component";

import Header from '../../../components/Header';
import Card from '../../../components/Card';
import Loading from '../../../components/Loading';
import Icon from '../../../components/Icon';
import Button from '../../../components/Button';

import { getBookingHistory, resetBookingHistory } from '../../../routes/ClassSchedule/action';

const HistoryMember = ({ 
  i18n,
  getBookingHistoryState,
  getBookingHistory,
  resetBookingHistory
}) => {
  const history = useHistory();
  const { id } = useParams();
  const [ loading, setLoading ] = useState(false);
  const [ bookClassList, setBookClassList ] = useState([]);
  const [ bookClassPagination, setBookClassPagination ] = useState();

  const { data, status, pagination } = getBookingHistoryState;
  const { page = 0, totalPage = 0 } = pagination;

  const loadData = (params) => {
    getBookingHistory({
      ...params,
      id,
      size: 10
    })
  }

  useEffect(() => {
    loadData();
    setBookClassList([])
    setBookClassPagination()

    return () => {
      resetBookingHistory()
    }
  }, []);

  useEffect(() => {
    if(status === 'pending') {
      setLoading(true)
    } else {
      setLoading(false)
      
      const tmpData = data ? data : [];
      setBookClassList([
        ...bookClassList,
        ...tmpData
      ])
      setBookClassPagination(pagination)
    }
  }, [status]);
  
  return (<>
    <Header label="History" onBackClick={() => {
      history.push(`/admin/member/edit/${id}`);
    }}/>
    {((loading && !bookClassPagination?.page) || status === 'pending') && <Loading/>}
    <div className='history-member'>
      {(bookClassList && bookClassList.length > 0) ? 
      <div className="schedule-list">
        <InfiniteScroll
          dataLength={bookClassList ? bookClassList.length : 0}
          next={() => loadData({ page: page + 1 })}
          hasMore={page!==totalPage}
          loader={<h4>Loading...</h4>}
        >
        {bookClassList.map((bookData, idx) => (
          <Card 
            key={idx}
            bookData={bookData}
            i18n={i18n}
            color="grey"
          />
        ))}
        </InfiniteScroll>
      </div>
      : 
      <div className='empty-class'>
        <Icon icon="empty"/><br/>
        {i18n('history_class.empty')}
        <Button color="pink" onClick={() => history.push('/class')}>{i18n('cta.see_avail_schedule')}</Button>
      </div> 

      }
    </div>
  </>);
};

HistoryMember.propTypes = {
  getBookingHistoryState: PropTypes.object
};

const mapStateToProps = (state) => ({
  getBookingHistoryState: state.getBookingHistoryReducer
});

export default compose(connect(mapStateToProps, {
  getBookingHistory,
  resetBookingHistory
}))(HistoryMember);
