import { useEffect, useState } from 'react';

let queue = [];
let listeners = [];

const publish = (newQueue) => {
  listeners.forEach((listener) => {
    listener(newQueue);
  });
};

const showSnackbar = (snackbar) => {
  queue = [...queue, { ...snackbar, id: `snackbar-${Date.now()}` }];
  publish(queue);
};

const clearQueue = () => {
  queue = [];
  publish(queue);
};

const useSnackbar = () => {
  const newListener = useState()[1];
  useEffect(() => {
    listeners.push(newListener);
  }, []);

  return {
    clearQueue,
    queue,
    showSnackbar,
  };
};

export default useSnackbar;
