import { combineReducers } from 'redux';

import { langReducer } from '../lang/langReducer';
import { postLoginReducer, postResetPasswordReducer } from '../routes/Login/reducer';
import { 
  postRegisterSendOTPReducer, 
  postRegisterVerifyOTPReducer, 
  postRegisterReducer 
} from '../routes/Register/reducer';
import { 
  getAccountInfoReducer,
  postChangePhoneReducer,
  postVerifyPhoneReducer,
  postChangePasswordReducer  
} from '../routes/Account/reducer';
import { getHomeInfoReducer } from '../routes/Home/reducer';
import { 
  getClassCategoryListReducer, 
  getSearchAvailReducer 
} from '../routes/ClassRegister/reducer';
import { 
  getMyBookingListReducer, 
  getBookingHistoryReducer, 
  putBookingCancelReducer 
} from '../routes/ClassSchedule/reducer';
import { 
  getAvailDetailReducer, 
  postBookingReducer
} from '../routes/ClassDetail/reducer';
import { 
  getClassListReducer,
  getClassDetailReducer,
  postClassDetailReducer,
  deleteClassReducer,
 } from '../routesAdmin/Class/reducer';
 import { 
  getInstructorListReducer,
  getInstructorDetailReducer,
  postInstructorDetailReducer,
  deleteInstructorReducer,
 } from '../routesAdmin/Instructor/reducer';
 import {
   getMemberListReducer,
   getMemberDetailReducer,
   postMemberDetailReducer,
   putKuotaMemberReducer,
   deleteMemberReducer,
   searchParamsLeavePageReducer
 } from '../routesAdmin/Member/reducer'
 import { 
  getAvailListReducer,
  getInstructurListReducer,
  putAvailReducer,
  postAvailReducer,
  getMemberAvailListReducer,
  deleteMemberAvailReducer,
  deleteAvailReducer,
  setSelectedDateAvailReducer,
} from '../routesAdmin/Schedule/reducer'

export default combineReducers({
  langReducer,
  // LOGIN
  postLoginReducer,
  postResetPasswordReducer,
  // REGISTER
  postRegisterSendOTPReducer,
  postRegisterVerifyOTPReducer,
  postRegisterReducer,
  // ACCOUNT
  getAccountInfoReducer,
  postChangePhoneReducer,
  postVerifyPhoneReducer,
  postChangePasswordReducer,
  // HOME
  getHomeInfoReducer, 
  // Class Search
  getClassCategoryListReducer,
  getSearchAvailReducer,
  // Class Detail
  getAvailDetailReducer, 
  postBookingReducer,
  // Schedule
  getMyBookingListReducer,
  getBookingHistoryReducer,
  putBookingCancelReducer,
  // CLASS ADMIN 
  getClassListReducer,
  getClassDetailReducer,
  postClassDetailReducer,
  deleteClassReducer,
  // INSTRUCTOR
  getInstructorListReducer,
  getInstructorDetailReducer,
  postInstructorDetailReducer,
  deleteInstructorReducer,
  // MEMBER ADMIN
  getMemberListReducer,
  getMemberDetailReducer,
  postMemberDetailReducer,
  putKuotaMemberReducer,
  deleteMemberReducer,
  searchParamsLeavePageReducer,
  // SCHEDULE ADMIN
  getAvailListReducer,
  getInstructurListReducer,
  putAvailReducer,
  postAvailReducer,
  getMemberAvailListReducer,
  deleteMemberAvailReducer,
  deleteAvailReducer,
  setSelectedDateAvailReducer,
});
