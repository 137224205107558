// import axios from 'axios';
import { apiCaller } from '../../helpers/apiCaller';

// get schedule by date
const getAvailListRequest = () => ({
  type: 'GET_AVAIL_LIST_REQUEST'
});

const getAvailListSuccess = (data) => ({
  type: 'GET_AVAIL_LIST_SUCCESS',
  payload: data
});

const getAvailListFailure = (error) => ({
  type: 'GET_AVAIL_LIST_FAILURE',
  payload: error
});

const getAvailList = (params) => {
  return (dispatch) => {
    dispatch(getAvailListRequest());
    return apiCaller('v1/avail', 'get', undefined, undefined, params)
      .then((data) => {
        if (data?.response?.data?.code) {
          return dispatch(getAvailListFailure(data));
        }

        return dispatch(getAvailListSuccess(data));
      })
      .catch((error) => dispatch(getAvailListFailure(error)));
  };
};

// get instructur list
const getInstructurListRequest = () => ({
  type: 'GET_INSTRUCTUR_LIST_REQUEST'
});

const getInstructurListSuccess = (data) => ({
  type: 'GET_INSTRUCTUR_LIST_SUCCESS',
  payload: data
});

const getInstructurListFailure = (error) => ({
  type: 'GET_INSTRUCTUR_LIST_FAILURE',
  payload: error
});

const getInstructurList = () => {
  return (dispatch) => {
    dispatch(getInstructurListRequest());
    return apiCaller('v1/instructor', 'get')
      .then((data) => {
        if (data?.response?.data?.code) {
          return dispatch(getInstructurListFailure(data));
        }

        return dispatch(getInstructurListSuccess(data));
      })
      .catch((error) => dispatch(getInstructurListFailure(error)));
  };
};

// put/edit avail
const putAvailRequest = () => ({
  type: 'PUT_AVAIL_REQUEST'
});

const putAvailSuccess = (data) => ({
  type: 'PUT_AVAIL_SUCCESS',
  payload: data
});

const putAvailFailure = (error) => ({
  type: 'PUT_AVAIL_FAILURE',
  payload: error
});

const putAvail = (id, params) => {
  return (dispatch) => {
    dispatch(putAvailRequest());
    return apiCaller(`v1/avail/${id}`, 'put', params)
      .then((data) => {
        if (data?.response?.data?.code) {
          return dispatch(putAvailFailure(data));
        }

        return dispatch(putAvailSuccess(data));
      })
      .catch((error) => dispatch(putAvailFailure(error)));
  };
};

const resetPutAvail = () => ({
  type: 'RESET_PUT_AVAIL'
});


// post / create avail
const postAvailRequest = () => ({
  type: 'POST_AVAIL_REQUEST'
});

const postAvailSuccess = (data) => ({
  type: 'POST_AVAIL_SUCCESS',
  payload: data
});

const postAvailFailure = (error) => ({
  type: 'POST_AVAIL_FAILURE',
  payload: error
});

const postAvail = (params) => {
  return (dispatch) => {
    dispatch(postAvailRequest());
    return apiCaller(`v1/avail${params?.dateUntil ? '/multi':''}`, 'post', params)
      .then((data) => {
        if (data?.response?.data?.code) {
          return dispatch(postAvailFailure(data));
        }

        return dispatch(postAvailSuccess(data));
      })
      .catch((error) => dispatch(postAvailFailure(error)));
  };
};

const resetPostAvail = () => ({
  type: 'RESET_POST_AVAIL'
});

// get member list
const getMemberAvailListRequest = () => ({
  type: 'GET_MEMBER_AVAIL_LIST_REQUEST'
});

const getMemberAvailListSuccess = (data) => ({
  type: 'GET_MEMBER_AVAIL_LIST_SUCCESS',
  payload: data
});

const getMemberAvailListFailure = (error) => ({
  type: 'GET_MEMBER_AVAIL_LIST_FAILURE',
  payload: error
});

const getMemberAvailList = (id) => {
  return (dispatch) => {
    dispatch(getMemberAvailListRequest());
    return apiCaller(`v1/avail/${id}/attendance`, 'get')
      .then((data) => {
        if (data?.response?.data?.code) {
          return dispatch(getMemberAvailListFailure(data));
        }

        return dispatch(getMemberAvailListSuccess(data));
      })
      .catch((error) => dispatch(getMemberAvailListFailure(error)));
  };
};

const resetMemberAvailList = () => ({
  type: 'RESET_MEMBER_AVAIL_LIST'
});

// get member list export
const deleteAvailRequest = () => ({
  type: 'DELETE_AVAIL_REQUEST'
});

const deleteAvailSuccess = (data) => ({
  type: 'DELETE_AVAIL_SUCCESS',
  payload: data
});

const deleteAvailFailure = (error) => ({
  type: 'DELETE_AVAIL_FAILURE',
  payload: error
});

const deleteAvail = (id) => {
  return (dispatch) => {
    dispatch(deleteAvailRequest());
    return apiCaller(`v1/avail/${id}`, 'delete')
      .then((data) => {
        if (data?.response?.data?.code) {
          return dispatch(deleteAvailFailure(data));
        }

        return dispatch(deleteAvailSuccess(data));
      })
      .catch((error) => dispatch(deleteAvailFailure(error)));
  };
};

const resetDeleteAvail = () => ({
  type: 'RESET_DELETE_AVAIL'
});

// get member list export
const deleteMemberAvailRequest = () => ({
  type: 'DELETE_MEMBER_AVAIL_REQUEST'
});

const deleteMemberAvailSuccess = (data) => ({
  type: 'DELETE_MEMBER_AVAIL_SUCCESS',
  payload: data
});

const deleteMemberAvailFailure = (error) => ({
  type: 'DELETE_MEMBER_AVAIL_FAILURE',
  payload: error
});

const deleteMemberAvail = (id, memberId) => {
  return (dispatch) => {
    dispatch(deleteMemberAvailRequest());
    return apiCaller(`v1/avail/${id}/attendance/${memberId}`, 'delete')
      .then((data) => {
        if (data?.response?.data?.code) {
          return dispatch(deleteMemberAvailFailure(data));
        }

        return dispatch(deleteMemberAvailSuccess(data));
      })
      .catch((error) => dispatch(deleteMemberAvailFailure(error)));
  };
};

const resetDeleteMemberAvail = () => ({
  type: 'RESET_DELETE_MEMBER_AVAIL'
});

const setSelectedDateAvail = (date) => ({
  type: 'SELECTED_DATE_AVAIL',
  payload: date
});

export { 
  getAvailList,
  getInstructurList,
  putAvail,
  resetPutAvail,
  postAvail,
  resetPostAvail,
  getMemberAvailList,
  deleteMemberAvail,
  resetDeleteMemberAvail,
  deleteAvail,
  resetDeleteAvail,
  setSelectedDateAvail,
  resetMemberAvailList,
};
