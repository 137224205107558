import React from 'react';
import { useHistory } from 'react-router';
import moment from 'moment-timezone';
import InfiniteScroll from "react-infinite-scroll-component";

import Icon from '../../../../components/Icon';
import Card from '../../../../components/Card';
import Row from '../../../../components/Row';
import Button from '../../../../components/Button';

import { convertDuration } from '../../../ClassDetail';

import './styles.scss';

const SearchResult = ({
  i18n,
  isDefault,
  isEmpty,
  data = [],
  loadData,
  pagination = {},
}) => {
  const history = useHistory();
  const { page = 0, totalPage = 0 } = pagination;
  const minRemainderSlot = 2;

  const detailRow = [
    { keyLang: 'instructor', keyValue: 'instructor' },
    { keyLang: 'category', keyValue: 'category' },
    { keyLang: 'duration', keyValue: 'duration', detailValue: (label, value) => (
      <Row label={label} value={convertDuration(value, i18n)}/>
    )},
    { keyLang: 'studio', detailValue: (label, detailValue) => detailValue.location && (
      <Row label={label} value={
        <a href={detailValue.locationUrl} target="_blank" rel="noopener noreferrer">{detailValue.location}</a>
      }/>
    )},
    { keyLang: 'capacity', keyValue: 'capacity', detailValue: (label, value) => (
      <Row label={label} value={i18n('label.capacity_value', { number: value })}/>
    )},
    { keyLang: 'avail', keyValue: 'avail', detailValue: (label, value) => (
      <Row label={label} value={i18n('label.avail_value', { number: value })}/>
    )},
  ]

  return (
    <div className="search-result-container">
      {isDefault && 
        <div className="info">
          {i18n('class_register_page.search_result.default')}
        </div>
      }

      {isEmpty && 
        <div className="info">
          <Icon icon="empty"/>
          <div>{i18n('class_register_page.search_result.empty')}</div>
        </div>
      }

      <div className="search-list">
        {!isEmpty && !isDefault && 
        <InfiniteScroll
          dataLength={data ? data.length : 0}
          next={() => loadData({ page: page + 1 })}
          hasMore={page!==totalPage}
          loader={<h4>Loading...</h4>}
        >
        {data.map((detail, idx) => (
          <Card color="purple" className='search-item' key={idx}>
            <div className="top">
              <div className="title">{detail?.className}</div>
              <div className="time">{moment(detail?.completeDate).format('HH:mm')}</div>
            </div>
            <div className="detail">
              {detailRow.map(({ keyValue, keyLang, detailValue }, idxDetail) => {
                const label = i18n(`label.${keyLang}`);
                const value = detail?.[keyValue];
                
                return (
                  detailValue ? detailValue(label, keyValue ? value : detail) : 
                  <Row label={label} value={value}/>
                )
              })}
            </div>
            <div className="choose-btn">
              {detail.avail < minRemainderSlot && !detail.fullBook && 
                <div className="label-remainder-slot">{i18n('label.remaining_avail', { number: detail.avail })}</div>
              }
              {detail.fullBook ? 
              <div className="sold-out-wrap">
                <div className="circle"/>
                <div className="label">{i18n('label.sold_out')}</div>
              </div>
              :
              <Button color="pink" onClick={() => history.push(`/class/detail/${detail?.availId}`)}>{i18n('cta.choose')}</Button>
              }
            </div>
          </Card>
        ))}
        </InfiniteScroll>
        }
      </div>
    </div>
  );
};

export default SearchResult;
