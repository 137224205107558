import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './styles.scss';

const HeaderDetail = ({
  children,
  title, 
  desc,
  simple = false, 
  className,
}) => {
  return (
    <div className={cn('header-detail-wrap', className, {
      simple
    })}>
      {title && 
        <div className="title">{title}</div>
      }
      {desc &&
        <div className="desc">{desc}</div>
      }
      {children}
      <div className='ornament'></div>
    </div>
  )
}

HeaderDetail.propTypes = {
  route: PropTypes.object,
  i18n: PropTypes.func
};

export default HeaderDetail;
