

const defaultData = []

const defaultState = {
  data: defaultData,
  status: 'idle',
  error: '',
  pagination: {}
};
// Get Class Category
const getClassCategoryListReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'GET_CLASS_CATEGORY_LIST_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_CLASS_CATEGORY_LIST_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_CLASS_CATEGORY_LIST_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    default:
      return state;
  }
};

// Seach Avail;
const getSearchAvailReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'GET_SEARCH_AVAIL_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_SEARCH_AVAIL_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_SEARCH_AVAIL_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data,
        pagination: action.payload.data.pagination
      };
    default:
      return state;
  }
};

export { getClassCategoryListReducer, getSearchAvailReducer };
