import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as yup from 'yup';
import PropTypes from 'prop-types';

import { errorScroll } from '../../helpers/functions';
import { convertArrayToObject } from '../../helpers';

import DantaLogo from '../../components/DantaLogo';
import Button from '../../components/Button';
import TextInput from '../../components/Input/TextInput';
import Icon from '../../components/Icon';
import Modal from '../../components/Modal';
import useSnackbar from '../../components/Snackbar/functions';
import Loading from '../../components/Loading';

import { postLogin, resetData } from './action';

import './styles.scss';

const Login = ({
  i18n,
  postLogin,
  loginState,
  resetData,
}) => {
  const history = useHistory();
  const [ form, setForm ] = useState();
  const [ errors, setErrors ] = useState();
  const [ showModal, setShowModal ] = useState(false);
  const { showSnackbar } = useSnackbar();

  const isInvalidToken = localStorage.getItem('INVALID_TOKEN');
  const isAccountNeedApproval = localStorage.getItem('ERROR_LOGIN_NEED_APPROVE');

  const handleSubmit = (e) => {
    e.preventDefault();
    const params = {...form}
    const phoneRegExp = /^(?!.*[+][6][2]).*/

    let schema = yup.object().shape({
      password: yup.string().required('required'),
      phone: yup
        .string()
        .matches(phoneRegExp, 'phone')
        .test(
          'len',
          i18n('validation.min_phone', { min: 8 }),
          (val) => val && val.toString().length > 8
        )
        .required('required'),
    });

    if (params.phone.startsWith("0")) {
      setErrors({phone: "Format nomor handphone salah (cth: +62 8XXX)"});
      errorScroll();
      return;
    }

    schema
      .validate(params, { abortEarly: false })
      .then(() => {
        postLogin({
          ...params,
          phone: `+62${params.phone}`
        })
      })
      .catch((err) => {
        setErrors(convertArrayToObject(err.inner, 'path', 'message'));
        errorScroll();
      });
  };

  const handlerChanges = (value, key, errorKey) => {
    setForm({ ...form, [key]: value });
    setErrors({ ...errors, [errorKey ? errorKey : key]: undefined });
  };

  const accountNeedApprovalHandler = () => {
    if (showModal) {
      setShowModal(false);
    };

    window.open(`https://wa.me/6281805018899?text=Halo%20Danta%20Yoga%2C%20Saya%20butuh%20bantuan%20approval%20akun%20dengan%20nomor%20Whatsapp%20sebagai%20berikut%2062${form.phone}`)
  }
  
  useEffect(() => {
    if (loginState?.status === 'resolve' && loginState?.data?.session) {
      localStorage.setItem('accessToken', loginState?.data?.session);
      const role = loginState?.data?.role;

      if (role === 'ADMIN') {
        history.push('/admin');
      } else {
        history.push('/');
      }
      resetData()
    } else if(loginState?.status === 'rejected'){
      const { code, message } = loginState?.error || {};
      let errMessage = i18n('error_message.ERROR_LOGIN');

      if (code === 403) {
        errMessage = i18n('error_message.ERROR_LOGIN_NEED_APPROVE');
        setShowModal(true)
        return;
      }

      showSnackbar({
        message: `${errMessage} (${code}: ${message})`,
        type: 'error'
      })
      resetData()
    }

  },[loginState?.status])

  useEffect(() => {
    if(isInvalidToken === 'show message') {
      setTimeout(() => {
        showSnackbar({
          message: i18n('error_message.INVALID_TOKEN'),
          type: 'error'
        })
        localStorage.removeItem('INVALID_TOKEN');
      }, 300)
    }
  }, [isInvalidToken])

  useEffect(() => {
    if(isAccountNeedApproval === 'show message') {
      setTimeout(() => {      
        setShowModal(true)
        localStorage.removeItem('ERROR_LOGIN_NEED_APPROVE');
      }, 300)
    }
  }, [isAccountNeedApproval])

  return (<>
    <section className='login-container'>
      {loginState?.status === 'pending' && <Loading/>}
      <Icon icon="arrow" placement='left' onClick={() => history.push('/sign-in')}/>
      <DantaLogo width="340"/>
      <form onSubmit={handleSubmit}>
        <TextInput
          label={i18n('label.handphone')}
          type='text'
          value={form?.phone}
          inputProps={{
            onChange: (e) => handlerChanges(e.target.value, 'phone')
          }}
          withHangingLabel
          errorMessage={errors?.phone && i18n(`validation.${errors?.phone}`)}
          textLeft="+62"
          placeholder="contoh: 813XX"
          withPlaceholder
        />
        
        <TextInput
          label={i18n('label.password')}
          value={form?.password}
          inputProps={{
            type: 'password',
            onChange: (e) => handlerChanges(e.target.value, 'password')
          }}
          errorMessage={errors?.password && i18n(`validation.${errors?.password}`)}
          withHangingLabel
        />
        <Button color="purple" type="submit">{i18n('cta.login')}</Button>
        <Button 
          flat color="purple" 
          onClick={() => history.push('/forgot-password')}
        >
          {i18n('cta.forgot_pass')}
        </Button>
      </form>
    </section>
    <Modal
      onClose={() => setShowModal(false)}
      isOpen={showModal}
      hasCloseIcon
      title={i18n('confirm_modal.login_need_approve.title')}
      portalId="login-need-approve"
      buttonActionHandler={accountNeedApprovalHandler}
      buttonText={i18n('cta.contact_admin')}
      >
      <div className="title-confirm">{i18n('confirm_modal.login_need_approve.desc')}</div>
    </Modal>
  </>);
};

Login.propTypes = {
  postLogin: PropTypes.func,
  loginState: PropTypes.object
};


const mapStateToProps = (state) => ({
  loginState: {
    status: state.postLoginReducer.status,
    error: state.postLoginReducer.error,
    data: state.postLoginReducer.data
  }
});

export default compose(connect(mapStateToProps, { postLogin, resetData }))(Login);