import React from 'react';
import cn from 'classnames';

const Row = ({
  className,
  label,
  value
}) => {

  return (
    <div 
      className={cn("row", className)}
      style={{
        display: 'flex'
      }}
    >
      <span className="label" style={{marginRight: "8px"}}>{label}</span>
      <span className="value">{value}</span>
    </div>
  );
};

export default Row;
