import React from 'react';
import Header from '../../../../components/Header';
import HeaderDetail from '../../../../components/Header/Detail';

import './styles.scss';

const AccountHeader = ({
  i18n,
  name = "A",
  history,
  onBackDest,
}) => {
  const matches = name.match(/\b(\w)/g);
  const acronym = matches.join('');

  return (<>
    <Header label={i18n('label.account')}
      onBackClick={() => history.push(onBackDest)}
    />
    <HeaderDetail className="account">
      <div className="acronym-info">
        {acronym}
      </div>
    </HeaderDetail>
  </>);
};

export default AccountHeader;
