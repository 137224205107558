import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import InfiniteScroll from "react-infinite-scroll-component";

import Icon from '../../../components/Icon';
import SearchBar from '../../components/SearchBar';
import Loading from '../../../components/Loading';
import Drawer from '../../../components/Drawer';
import Modal from '../../../components/Modal';
import useSnackbar from '../../../components/Snackbar/functions';

import { 
  getMemberList, 
} from '../../Member/action';
import {
  postBooking,
  resetData
} from '../../../routes/ClassDetail/action';

import './styles.scss';

const AddNewMember = ({ 
  i18n, 
  getMemberList,
  getMemberListState,
  showDrawer,
  setShowDrawer,
  participantList,
  postBooking,
  postBookingState,
  resetData,
  availId,
  refetchData,
}) => {
  const [ loadingSearch, setLoadingSearch ] = useState(false);
  const [ selectedMember, setSelectedMember ] = useState();
  const [ showModal, setShowModal ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ searchKey, setSearchKey ] = useState();
  
  const memberList = getMemberListState?.data || [];
  const { page = 0, totalPage = 0 } = getMemberListState;
  
  const { showSnackbar } = useSnackbar();
  const showSnackbarBy = (message, type) => {
    showSnackbar({
      message: message,
      type: type
    })
  }

  const setSearchKeywordParams = (val) => {
    let params = {};

    if (val) {
      var reg = /^0\d+$/;
      let isIndoFormat = reg.test(val);

      if (!isIndoFormat) {
        val = '+' + val;
      }

      params = {
        ...({ Phone: val }),
      }
    }

    return params;
  }

  const handlerSearchSubmit = (val) => {
    setLoadingSearch(true);
    const params = {
      Sort: 'name asc',
      ...setSearchKeywordParams(val)
    };
    setSearchKey(val)
    getMemberList(params);
  }

  const handlerSearchNameSubmit = (val) => {
    setLoadingSearch(true);
    const params = {
      Sort: 'name asc',
      SearchName: val,    
    };
    getMemberList(params);
  }

  const handleAddNewParticipant = (member) => {
    if (showModal) {
      setLoading(true);
      setShowModal(false);
      // setShowDrawer(false);

      postBooking({          
        accountId: Number(selectedMember?.id),
        availId: Number(availId)
      })
    } else {
      setShowModal(true);
      setSelectedMember(member)
    }
  }

  const loadData = (params) => {
    getMemberList({
      Sort: 'name asc',
      size: 100,
      ...setSearchKeywordParams(searchKey),
      ...params
    })
  }

  useEffect(() => {
    getMemberList({
      Sort: `name asc`
    });
  }, [])

  useEffect(() => {
    if ((loadingSearch || loading) && getMemberListState.status === 'resolve') {
      setLoadingSearch(false)
    } else if (getMemberListState?.status === 'rejected') {
      setLoadingSearch(false)
      showSnackbar({
        message: "Terjadi kesalahan. Mohon refresh",
        type: 'error'
      })
    }

  }, [loadingSearch, getMemberListState.status])

  useEffect(() => {
    if (loading) {
      const { status, error } = postBookingState;

      if(status === 'resolve') {
        setLoading(false);
        showSnackbarBy(i18n('class_register_page.success_regis'), 'success');
        refetchData()
        setShowDrawer(false)
        resetData()
      } else if (status === 'rejected'){
        setLoading(false);
        const errMessage = i18n(`error_message.${error?.response?.data?.error}`);
        showSnackbarBy(errMessage, 'error')
        resetData()
      }
    }
  }, [postBookingState?.status])
  
  return (<>
    <Drawer
      showDrawer={showDrawer}
      top={0}
      threshold={0.5}
      onCloseDrawer={()=>setShowDrawer(false)}
      className="filter-drawer-component"
    >
      {onCloseDrawer => {
        return (
          <>
            <Drawer.Header
              title={"Tambah peserta baru"}
              onCloseDrawer={onCloseDrawer}
            />
            <Drawer.Body className="list-drawer">
              {(loadingSearch || loading) && <Loading/>}
              <div className='member'>
                <SearchBar
                  inputType={'number'}
                  placeholder={i18n('member_admin.placeholder_search')}
                  onSubmit={handlerSearchSubmit}
                />
                <SearchBar
                  inputType={'text'}
                  placeholder={i18n('member_admin.placeholder_search_name')}
                  onSubmit={handlerSearchNameSubmit}
                />
                <div className="member-list-container">
                  {memberList.length > 0 ?
                  <div className="member-list">
                    <InfiniteScroll
                      dataLength={memberList ? memberList.length : 0}
                      next={() => loadData({ page: page + 1 })}
                      hasMore={page!==totalPage}
                      loader={<h4>Loading...</h4>}
                    >
                    {memberList.map((member, idx) => {
                      const alreadyJoin = participantList.find((participant) => participant.phone === member.phone);
                      
                      if (!alreadyJoin) {
                        return (
                          <div className="member-wrap" key={idx} onClick={() => handleAddNewParticipant(member)}>
                            <div className="detail-wrap">
                              <div className="name">{member?.name}</div>
                              <div className="phone">{member?.phone}</div>
                            </div>
                          </div>  
                        )
                      }
                    })}
                    </InfiniteScroll>
                  </div>
                  : 
                  <div className='empty-member'>
                    <Icon icon="empty"/><br/>
                    {i18n('member_admin.empty')}
                  </div> 
                  }
                </div>
              </div>
            </Drawer.Body>
          </>
        );
      }}
    </Drawer>

    <Modal
      onClose={() => setShowModal(false)}
      isOpen={showModal}
      hasCloseIcon
      buttonSecondaryActionHandler={() => setShowModal(false)}
      buttonActionHandler={handleAddNewParticipant}
      buttonText={i18n('cta.yes_sure')}
      buttonSecondaryText={i18n('cta.cancel')}
      title={i18n('confirm_modal.title')}
      portalId="class-confirm"
    >
      <div className="title-confirm">{i18n('confirm_modal.title_add_participant')}</div>
      <div className="detail-class-confirm">
        <div className="title">{selectedMember?.name}</div>
        <div className="detail">
          <div className="phone">{selectedMember?.phone}</div>
        </div>
      </div>
    </Modal>
  </>);
};

AddNewMember.propTypes = {
  setLang: PropTypes.func,
  history: PropTypes.object,
  i18n: PropTypes.func,
  getMemberList: PropTypes.func,
  getMemberListState: PropTypes.object,
};

const mapStateToProps = (state) => ({
  lang: state.langReducer.lang,
  getMemberListState: {
    status: state.getMemberListReducer.status,
    error: state.getMemberListReducer.error,
    data: state.getMemberListReducer.data
  },
  postBookingState: {
    status: state.postBookingReducer.status,
    error: state.postBookingReducer.error,
    data: state.postBookingReducer.data
  }
});

export default compose(connect(mapStateToProps, {
  getMemberList, 
  postBooking,
  resetData,
}))(AddNewMember);
