const defaultResponse = {
  data: {},
  status: 'idle',
  error: ''
};

const getAccountInfoReducer = (state = defaultResponse, action) => {
  switch (action.type) {
    case 'GET_ACCOUNT_INFO_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_ACCOUNT_INFO_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_ACCOUNT_INFO_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    default:
      return state;
  }
};

const postChangePhoneReducer = (state = defaultResponse, action) => {
  switch (action.type) {
    case 'POST_CHANGE_PHONE_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'POST_CHANGE_PHONE_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'POST_CHANGE_PHONE_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'POST_CHANGE_PHONE_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_CHANGE_PHONE':
      return {
        ...state,
        ...defaultResponse
      };
    default:
      return state;
  }
};

const postVerifyPhoneReducer = (state = defaultResponse, action) => {
  switch (action.type) {
    case 'POST_VERIFY_PHONE_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'POST_VERIFY_PHONE_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'POST_VERIFY_PHONE_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_VERIFY_PHONE':
      return {
        ...state,
        ...defaultResponse
      };
    default:
      return state;
  }
};

const postChangePasswordReducer = (state = defaultResponse, action) => {
  switch (action.type) {
    case 'POST_CHANGE_PASSWORD_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'POST_CHANGE_PASSWORD_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'POST_CHANGE_PASSWORD_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_CHANGE_PASSWORD':
      return {
        ...state,
        ...defaultResponse
      };
    default:
      return state;
  }
};

export { getAccountInfoReducer,
  postChangePhoneReducer,
  postVerifyPhoneReducer,
  postChangePasswordReducer 
};
