import React from 'react';
import './style.scss';

const LoadingOverlay = () => {
  return (
    <div className='the-loading-overlay'>
      <div className='loading' />
    </div>
  );
};

export default LoadingOverlay;
