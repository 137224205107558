import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import Datepicker from '../../components/Dropdown/Datepicker/Datepicker';

import './styles_calendar.scss';

const CalendarClass = ({ 
  i18n, 
  selectedDate,
  setselectedDate
}) => { 

  const events = {}
  
  const renderDayContents = (day, date) => {
    let eventFlag = false;

    if (events[moment(date).format('YYYY-MM-DD')]) {
      eventFlag = true
    }

    return (
      <div className='date-cal'>
        {eventFlag &&
        <div className="event-flag"></div>
        }
        <span>{moment(date).format('D')}</span>
      </div>
    );
};
  
  return (
    <div className="calendar-class box">
      <Datepicker
        todayButton="Hari ini"
        selected={selectedDate}
        onChange={(date) => setselectedDate(date)}
        inline
        disabledKeyboardNavigation
        dropdownMode="select"
        minDate={new Date(2022, 5, 25, 0, 0, 0, 0)}
        renderDayContents={renderDayContents}
      />
    </div>);
};

CalendarClass.propTypes = {
  i18n: PropTypes.func,
};

export default CalendarClass;
