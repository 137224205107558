import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import Header from '../../components/Header';
import TopNavigation from '../../components/TopNavigation';
import Button from '../../components/Button';
import HeaderDetail from '../../components/Header/Detail';
import Modal from '../../components/Modal';
import BannerPromo from './components/BannerPromo';

import { getAccountInfo } from '../Account/action';
import { getHomeInfo} from './action';

import './styles.scss';

const Home = ({ 
  i18n, 
  accountState,
  getHomeInfoState,
  getHomeInfo
}) => {
  const history = useHistory();
  const [ selectedItem, setSelectedItem ] = useState();
  const [ showModal, setShowModal ] = useState(false);
  const [ showAnnouncementModal, setShowAnnouncementModal ] = useState(false);

  const { data: { name, quota: quotaVisit  } } = accountState;
  const promoBannerList = getHomeInfoState?.data?.promo || [];
  const specialBannerList = getHomeInfoState?.data?.special || [];
  const announcement = getHomeInfoState?.data?.announcement || '';

  const topUpHandler = () => {
    if (showModal) {
      setShowModal(false);
    };

    window.open(`https://wa.me/6281805018899?text=Halo%20Danta%20Yoga%2C%20Saya%20${name}%20ingin%20top-up%20kuota%20saya.%20Mohon%20bantuannya`)
  }

  useEffect(() => {
    getHomeInfo();

    setTimeout(() => {
      setSelectedItem(1)
    }, 5000);
  }, []);

  useEffect(() => {
    if (quotaVisit === 0) {
      setTimeout(() => {
        setShowModal(true)
      }, 500)
    }
  }, [accountState])

  useEffect(() => {
    if (announcement && announcement !== ' ' && announcement !== '') {
      setTimeout(() => {
        setShowAnnouncementModal(true)
      }, 500)
    }
  }, [announcement])
  
  return (<>
    <Header withoutBackButton/>
    <div className='home'>
      <HeaderDetail>
        <div className="user-info">
          <div>{i18n('welcome')}, </div>
          <div className='name'>{name}</div>
        </div>
      </HeaderDetail>
      <section>
        <TopNavigation className="quota-wrap">
          <div className="left">
            <div className='quota-label'>{i18n('label.ur_quota')}</div>
            <div className='quota-value'>{i18n('label.visit', { number: quotaVisit })}</div>
          </div>
          
          <Button color="white-pink" onClick={topUpHandler}>
          {i18n('cta.top_up')}
          </Button>
        </TopNavigation>
        
        <div className="promo-list-section">
          <div className="promo-list-container">
            <Carousel autoPlay infiniteLoop showArrows={false} selectedItem={selectedItem} showStatus={false}>
              {promoBannerList.map((bannerData, idx) => (
                <BannerPromo
                  key={idx}
                  i18n={i18n}
                  data={bannerData}
                />
              ))}
            </Carousel>
          </div>

          <div className="title special-promo">Special di Danta</div>
          <div className="promo-list-container">
            <Carousel autoPlay infiniteLoop showArrows={false} selectedItem={selectedItem} showStatus={false}>
              {specialBannerList.map((bannerData, idx) => (
                <BannerPromo
                  key={idx}
                  i18n={i18n}
                  data={bannerData}
                  withoutTitle={true}
                />
              ))}
            </Carousel>
          </div>
        </div>
      </section>
    </div>
    <Modal
      onClose={() => setShowModal(false)}
      isOpen={showModal}
      hasCloseIcon
      buttonSecondaryActionHandler={() => setShowModal(false)}
      buttonActionHandler={topUpHandler}
      buttonText={"Contact WA Admin"}
      title={i18n('confirm_modal.title_quota_empty')}
      portalId="home-empty-quota"
      enableCloseOutside
    />
    <Modal
      onClose={() => setShowAnnouncementModal(false)}
      isOpen={showAnnouncementModal}
      hasCloseIcon
      buttonSecondaryActionHandler={() => setShowAnnouncementModal(false)}
      title={"Pengumuman"}
      portalId="home-announcement"
      enableCloseOutside
    >{announcement}</Modal>
  </>);
};

Home.propTypes = {
  setLang: PropTypes.func,
  history: PropTypes.object,
  i18n: PropTypes.func,
  getEventList: PropTypes.func,
  getTourList: PropTypes.func,
  eventList: PropTypes.object,
  tourList: PropTypes.object,
  getAccountInfo: PropTypes.func,
  accountState: PropTypes.object,
  getHomeInfoState: PropTypes.object,
  getHomeInfo: PropTypes.func
};

const mapStateToProps = (state) => ({
  lang: state.langReducer.lang,
  accountState: {
    status: state.getAccountInfoReducer.status,
    error: state.getAccountInfoReducer.error,
    data: state.getAccountInfoReducer.data
  },
  getHomeInfoState: {
    status: state.getHomeInfoReducer.status,
    error: state.getHomeInfoReducer.error,
    data: state.getHomeInfoReducer.data
  }
});

export default compose(connect(mapStateToProps, { 
  getAccountInfo,
  getHomeInfo,
 }))(Home);
