import React, { Fragment, Component } from 'react';
import { oneOf, number, bool, string, func, node, oneOfType } from 'prop-types';
import Observer from '@researchgate/react-intersection-observer';
import { Transition } from 'react-transition-group';
import Helmet from 'react-helmet';

import Icon from '../Icon';

import './styles.scss';

const mobileOverflowCSS = `html.mobile{overscroll-behavior-y: contain;} html.mobile body {overscroll-behavior-y: contain;}`;

const duration = 300;

const transition = {
  entering: { visibility: 'visible' },
  entered: { visibility: 'visible' },
  exiting: { backgroundColor: 'transparent'},
};

const drawerTransition = {
  exiting: { transform: 'translateY(100%)', transition: `transform ${duration}ms ease`},
  exited: { transform: 'translateY(100%)', transition: `transform ${duration}ms ease`},
};

export const DrawerHeader = props => {
  const { title, subtitle, onCloseDrawer, children } = props;

  const handleCloseDrawer = () => {
    if(onCloseDrawer) {
      onCloseDrawer();
    }
  };

  return (
    <div className="drawer-header">
      {children
        ? children
        : (
          <Fragment>
            <div className="drawer-header-title-container">
              <span className="drawer-header-logo" onClick={handleCloseDrawer}><Icon icon="close"/></span>
              {title && <span className="drawer-header-title">{title}</span>}
            </div>
            {subtitle && (
              <div className="drawer-header-subtitle-container">
                <span className="drawer-header-subtitle">{subtitle}</span>
              </div>
            )}
          </Fragment>
        )
      }

    </div>
  );
};

export const DrawerBody = props => {
  const { className, children } = props;

  return (
    <div
      className={`drawer-body${className ? ` ${className}`: ''}`}
      style={{
        height: '100%'
      }}
    >
      <div>
        {children}
      </div>
    </div>
  );
};

class DrawerMobile extends Component {
  static Header = DrawerHeader;
  static Body = DrawerBody;

  constructor(props) {
    super(props);

    this.initialState = {
      isIntersecting: false,
      initialTouchY: 0,
      touchY: 0
    };

    this.state = {
      ...this.initialState,
      touchY: '100%'
    };

    this.handleTouchStart = this.handleTouchStart.bind(this);
    // this.handleTouchStart.options = { passive: false };
    this.handleTouchMove = this.handleTouchMove.bind(this);
    // this.handleTouchMove.options = { passive: false}
    this.handleTouchEnd = this.handleTouchEnd.bind(this);
    // this.handleTouchEnd.options = { passive: false}
  }

  componentDidUpdate(prevProps) {
    const { showDrawer } = this.props;

    if(showDrawer && showDrawer !== prevProps.showDrawer) {
      this.setState(this.initialState);
    }

    if (showDrawer) {
      window && window.scrollTo(0, 0);
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'initial';
    }
  }

  handleTouchStart(e) {
    e.preventDefault();
    const initialTouchY = e.touches[0].pageY;

    this.setState({
      initialTouchY,
      touchY: 0
    });
  }

  handleTouchMove(e) {
    e.preventDefault();
    const { initialTouchY } = this.state;
    const currentTouchY = e.touches[0].pageY;

    const diffTouchY = currentTouchY - initialTouchY;

    if(diffTouchY > 0) {
      this.setState({
        touchY: `${diffTouchY}px`
      });
    }
  }

  handleTouchEnd(e) {
    e.preventDefault();

    const { onCloseDrawer } = this.props;
    const { isIntersecting } = this.state;

    this.setState({
      initialTouchY: 0,
      touchY: isIntersecting ? 0 : '100%'
    }, () => {
      if(!isIntersecting) {
        onCloseDrawer();
      }
    });
  }

  handleIntersection(e) {
    const { threshold } = this.props;

    this.setState({
      isIntersecting: e.intersectionRatio > threshold
    });
  }

  render() {
    const {
      className,
      threshold,
      top = 0,
      minHeight = 0,
      showDrawer,
      onCloseDrawer,
      children,
      withOverlay = true,
      withEllipsis = true,
      maxHeight,
      ...rest
    } = this.props;
    const { initialTouchY, touchY } = this.state;

    return (
      <>
        {showDrawer && withEllipsis && (
          <Helmet>
            <style>{`${mobileOverflowCSS}`}</style>
          </Helmet>
        )}
        <Transition in={showDrawer} timeout={duration}>
          {state => (
            <div
              className={`drawer-component${className ? ` ${className}` : ''}`}
              style={{
                transition: `all ${duration}ms ease-in-out`,
                visibility: 'hidden',
                ...(transition[state] ? transition[state] : {})
              }}
              tabIndex={-1}
            >
              {withOverlay && (
                <div className="drawer-overlay" onClick={onCloseDrawer}/>
              )}
              <Observer
                onChange={this.handleIntersection.bind(this)}
                threshold={[threshold]}
              >
                <div
                  className="drawer-container"
                  style={{
                    minHeight,
                    maxHeight: maxHeight ? maxHeight : `calc(100% - ${top}px)`,
                    transform: `translateY(${touchY})`,
                    ...(initialTouchY === 0 ? {transition: `transform ${showDrawer ? '300' : '100'}ms ease`} : {}),
                    ...(drawerTransition[state] ? drawerTransition[state] : {})
                  }}
                >
                  {withEllipsis && (
                    <div
                      className="drawer-ellipsis-container"
                      onTouchStart={this.handleTouchStart}
                      onTouchMove={this.handleTouchMove}
                      onTouchEnd={this.handleTouchEnd}
                    >
                      <span className="drawer-ellipsis"/>
                    </div>
                  )}
                  {typeof children === 'function'
                    ? children(onCloseDrawer, rest)
                    : React.cloneElement(React.Children.only(children), {...rest, onCloseDrawer})
                  }
                </div>
              </Observer>
            </div>
          )}
        </Transition>
      </>
    );
  }
}

DrawerHeader.propTypes = {
  title: string,
  subtitle: string,
  onCloseDrawer: func,
  children: node
};

DrawerBody.propTypes = {
  className: string,
  children: node.isRequired
};

DrawerMobile.propTypes = {
  className: string,
  minHeight: number,
  top: number,
  threshold: oneOf([0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1]).isRequired,
  showDrawer: bool.isRequired,
  withOverlay: bool,
  withEllipsis: bool,
  onCloseDrawer: func.isRequired,
  children: oneOfType([node, func])
};

export default DrawerMobile;
