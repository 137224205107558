import React from 'react';
import Parser from 'html-react-parser';

import TextInput from '../../../../components/Input/TextInput';
import RadioButton from '../../../../components/Input/RadioButton';
import Checkbox from '../../../../components/Input/Checkbox';
import Datepicker from '../../../../components/Dropdown/Datepicker';

import './styles.scss';

const ProfileForm = ({
  errors,
  form,
  handlerChanges,
  i18n,
}) => {

  const gender_options = [
    { value: 'female', name: i18n('label.women') },
    { value: 'male', name: i18n('label.man') }
  ];
  
  return (
    <div className='profile-form-container'>
      <TextInput
        label={i18n('label.handphone')}
        value={`+62${form?.phone}`}
        inputProps={{
          onChange: (e) => handlerChanges(e.target.value, 'phone')
        }}
        withHangingLabel
        disable
      />
      <TextInput
        label={i18n('label.fullname')}
        value={form?.name}
        inputProps={{
          onChange: (e) => handlerChanges(e.target.value, 'name')
        }}
        withHangingLabel
        errorMessage={errors?.name && i18n(`validation.${errors?.name}`)}
      />

      <RadioButton
        label="Gender"
        className='gender-type dark-mode form'
        options={gender_options}
        value={form?.gender}
        inputProps={{
          onChange: (e) =>handlerChanges(e.target.value, 'gender')
        }}
      />

      <TextInput
        label={i18n('label.email')}
        value={form?.email}
        inputProps={{
          type: 'text',
          onChange: (e) => handlerChanges(e.target.value, 'email')
        }}
        withHangingLabel
        errorMessage={errors?.email && i18n(`validation.${errors?.email}`)}
      />
      <TextInput
        label={i18n('label.instagram')}
        value={form?.instagram}
        inputProps={{
          type: 'text',
          onChange: (e) => handlerChanges(e.target.value, 'instagram')
        }}
        withHangingLabel
        errorMessage={errors?.instagram && i18n(`validation.${errors?.instagram}`)}
      />
      <Datepicker
        i18n={i18n}
        label={i18n('label.bod')}
        labelDrawer={i18n('label.select_bod')}
        value={form?.birth_date}
        onChange={(date) => handlerChanges(date, 'birth_date')}
        errorMessage={errors?.birth_date && i18n(`validation.${errors?.birth_date}`)}
        minDate={new Date("1900-01-01")}
        maxDate={new Date()}
        withoutLabelEmpty={true}
      />
      <TextInput
        label={i18n('label.password')}
        value={form?.password}
        inputProps={{
          type: 'password',
          autoComplete: "false",
          onChange: (e) => handlerChanges(e.target.value, 'password')
        }}
        withHangingLabel
        errorMessage={errors?.password && i18n(`validation.${errors?.password}`)}
      />
      <TextInput
        label={i18n('label.re_password')}
        value={form?.confirmPassword}
        inputProps={{
          type: 'password',
          autoComplete: "false",
          onChange: (e) => handlerChanges(e.target.value, 'confirmPassword')
        }}
        withHangingLabel
        errorMessage={errors?.confirmPassword && i18n(`validation.${errors?.confirmPassword}`)}
      />

      <Checkbox
        className='aggrement'
        label={<span className="label">{Parser(i18n('register_page.i_agree'))}</span>}
        checked={form?.aggrement}
        onChange={() => handlerChanges(!form?.aggrement, 'aggrement')}
        errorMessage={errors?.aggrement && i18n(`validation.${errors?.aggrement}`)}
      />
    </div>

  );
};

export default ProfileForm;
