import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';

import Header from '../../components/Header';

import './styles.scss';

const Home = ({ 
  i18n, 
}) => {
  const history = useHistory();

  const menuList = [
    {
      icon: 'class',
      label: i18n('label.class_regis'),
      link: '/admin/class'
    },
    {
      icon: 'instructor',
      label: i18n('label.instructor_regis'),
      link: '/admin/instructor'
    },
    {
      icon: 'time',
      label: i18n('label.class_schedule'),
      link: '/admin/schedule'
    },
    {
      icon: 'member',
      label: i18n('label.member_list_top_up'),
      link: '/admin/member'
    }
  ]
  
  return (<>
    <Header withoutBackButton/>
    <div className='home'>
      <div className="menu-container">
        {menuList.map(({ icon, label, link }, idx) => (
          <div className="menu-wrap" key={idx} onClick={() => history.push(link)}>
            <div className="left">
              <div className={`illustration-menu ${icon}`}/>
            </div>  
            <div className="right">
              {label}
            </div>  
          </div>
        ))}
      </div>
    </div>
  </>);
};

Home.propTypes = {
  setLang: PropTypes.func,
  history: PropTypes.object,
  i18n: PropTypes.func,
  getAccountInfo: PropTypes.func,
  accountState: PropTypes.object
};

const mapStateToProps = (state) => ({
  lang: state.langReducer.lang
});

export default compose(connect(mapStateToProps))(Home);
