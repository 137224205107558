import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';
import InfiniteScroll from "react-infinite-scroll-component";

import Header from '../../components/Header';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import SearchBar from './components/SearchBar';
import TopUpMember from './components/TopUpModal';
import Loading from '../../components/Loading';
import FilterDrawer from './components/FilterDrawer';
import useSnackbar from '../../components/Snackbar/functions';
import { 
  getMemberList, 
  putKuotaMember,
  resetputKuotaMember,
  setSearchParamsLeavePage
} from './action';
import { exportMemberList } from './functions';

import './styles.scss';

const Member = ({ 
  i18n, 
  getMemberList,
  getMemberListState,
  putKuotaMember,
  resetputKuotaMember,
  putKuotaMemberState,
  searchParamsLeavePageState = {},
  setSearchParamsLeavePage
}) => {
  const history = useHistory();
  const [ selectedMember, setSelectedMember ] = useState();
  const [ showModal, setShowModal ] = useState(false);
  const [ loadingSearch, setLoadingSearch ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ showDrawer, setShowDrawer ] = useState(false);
  const [ ascSort, setAscSort ] = useState();
  const [ searchParams, setSearchParams ] = useState({});
  const [ searchFilter, setSearchFilter ] = useState();
  const { showSnackbar } = useSnackbar();
  
  const memberList = getMemberListState?.data || [];
  const { page = 0, totalPage = 0, status } = getMemberListState;

  const setTopupMember = (data) => {
    setSelectedMember(data)
    setShowModal(true)
  }

  const handleTopUpMember = (params) => {
    setShowModal(false);
    setLoading(true);
    setSelectedMember();
    putKuotaMember({
      "accountId": params?.id,
      "kuota": Number(params?.quota)
    })
  }

  const setSearchPhoneKeywordParams = (val) => {
    let params = {};

    if (val) {
      var reg = /^0\d+$/;
      let isIndoFormat = reg.test(val);

      if (!isIndoFormat) {
        val = val;
      }

      params = {
        ...({ Phone: val }),
      }
    }

    return params;
  }

  const handlerSearchSubmit = (phoneParam, nameParam) => {
    setLoadingSearch(true)
    const params = {
      ...searchParams,
      ...setSearchPhoneKeywordParams(phoneParam),
      ...(nameParam && nameParam !== '' ? { SearchName: nameParam } : {}),
    };

    if (!nameParam && params.SearchName) {
      delete params.SearchName;
    }

    if (!phoneParam && params.Phone) {
      delete params.Phone;
    }

    setSearchParams(params)
    getMemberList(params);
  }

  const handleFilter = (data = []) => {
    const notApproved = data.length === 1 && data[0].value === 1;
    const params = {
      ...searchParams,
      NotApproved: notApproved,
    }
    
    // setFilterParams(data);
    setLoadingSearch(true);
    setSearchParams(params)
    getMemberList(params);
    setSearchParamsLeavePage({
      ...searchParamsLeavePageState,
      searchFilter: data
    })
  }

  const loadData = (params) => {
    getMemberList({
      ...searchParams,
      ...params
    })
  }

  const downloadMemberList = () => {
    setLoading(true)
    exportMemberList(setLoading)
  }

  useEffect(() => {
    setLoadingSearch(true);

    let params = {
      Sort: `name asc`
    }
    let ascSort = '1';

    if (searchParamsLeavePageState) {
      if (searchParamsLeavePageState?.searchParams) {
        params = {
          ...params,
          ...searchParamsLeavePageState?.searchParams
        }

        if (searchParamsLeavePageState?.searchParams?.Sort.includes('desc')) {
          ascSort = '0'
        }
      }

      if (searchParamsLeavePageState?.searchFilter) {
        const notApproved = searchParamsLeavePageState?.searchFilter?.length === 1 && searchParamsLeavePageState?.searchFilter[0].value === 1;
        params = {
          ...params,
          NotApproved: notApproved,
        }
        setSearchFilter(searchParamsLeavePageState?.searchFilter)
      }
    }

    setSearchParams(params)
    getMemberList(params);
    setAscSort(ascSort);
    setSearchParamsLeavePage();
  }, []);

  useEffect(() => {
    if (loadingSearch && getMemberListState.status === 'resolve') {
      setLoadingSearch(false)
    }
  }, [loadingSearch, getMemberListState.status])

  useEffect(() => {
    if (ascSort) {
      const params = {
        ...searchParams,
        Sort: `name ${ascSort === '1' ? 'asc' : 'desc'}`
      }

      setLoadingSearch(true);
      setSearchParams(params)
      getMemberList(params);
    }
  }, [ascSort])

  useEffect(() => {
    if (loading) {
      const { status, error } = putKuotaMemberState;

      if (status === 'resolve') {
        setLoading(false)
        showSnackbar({
          message: i18n(`member_admin.success_kuota`),
          type: 'success'
        })
        resetputKuotaMember()
        getMemberList({
          Sort: `name asc`
        });
        setLoading(false)
      } else if(status === 'rejected'){
        const errMessage = error?.response?.data?.message
        setLoading(false)
        showSnackbar({
          message: i18n(`error_message.${errMessage}`),
          type: 'error'
        })
        resetputKuotaMember()
        setLoading(false)
      }
    }
  }, [loading, putKuotaMemberState])

  useEffect(() => {
    setSearchParamsLeavePage({
      ...searchParamsLeavePageState,
      searchParams
    })
  }, [searchParams])
  
  return (<>
    <Header label={i18n('label.member_list')} onBackClick={() => {
      history.push('/admin');
      setSearchParamsLeavePage();
    }}/>
    {(loading || loadingSearch || status === 'pending') && <Loading/>}
    <div className='member'>
      <SearchBar
        i18n={i18n}
        onSubmit={handlerSearchSubmit}
        defaulValue={{
          name: searchParams?.SearchName,
          phone: searchParams?.Phone
        }}
      />
      <div className="member-list-container">
        <div className="top">
          <div className='download-wrap'>
            <Button flat color="purple" onClick={downloadMemberList}><Icon icon="download" className='purple'/></Button>
          </div>
          <div className="filter-wrap">
            <Button flat color="purple" onClick={() => setAscSort(ascSort === '1' ? '0' : '1')}>{i18n(`cta.${ascSort === '1' ? 'un':''}sort_az`)}</Button>
            <Button flat color="purple" onClick={() => setShowDrawer(true)}>{i18n('cta.filter')}</Button>
          </div>  
        </div>
        {memberList.length > 0 ?
        <div className="member-list">

         <InfiniteScroll
          dataLength={memberList ? memberList.length : 0}
          next={() => loadData({ page: page + 1 })}
          hasMore={page!==totalPage}
          loader={<h4>Loading...</h4>}
        >
          {memberList.map((member, idx) => (
            <div className="member-wrap" key={idx}>
              <div className="left">
                <div className="quota-wrap">{member?.quota}<br/> {i18n('label.quota')}</div>
                <div className="detail-wrap" onClick={() => history.push(`/admin/member/edit/${member?.id}`)}>
                  <div className="name">{member?.name}</div>
                  <div className="phone">{member?.phone}</div>
                </div>
              </div>
              <Button color="purple" classname='top-up' onClick={() => setTopupMember(member)}>+</Button>
            </div>  
          ))}
        </InfiniteScroll>
        </div>
        : 
        <div className='empty-member'>
          <Icon icon="empty"/><br/>
          {i18n('member_admin.empty')}
        </div> 
        }
      </div>
    </div>
    <TopUpMember
      i18n={i18n}
      member={selectedMember}
      showModal={showModal}
      setShowModal={setShowModal}
      submit={handleTopUpMember}
    />
    <FilterDrawer
      i18n={i18n} 
      showDrawer={showDrawer}
      setShowDrawer={setShowDrawer}
      onSubmit={handleFilter}
      defaultFilter={searchFilter}
    />
  </>);
};

Member.propTypes = {
  setLang: PropTypes.func,
  history: PropTypes.object,
  i18n: PropTypes.func,
  getMemberList: PropTypes.func,
  getMemberListState: PropTypes.object,
  putKuotaMember: PropTypes.func,
  resetputKuotaMember: PropTypes.func,
  putKuotaMemberState: PropTypes.object
};

const mapStateToProps = (state) => ({
  lang: state.langReducer.lang,
  getMemberListState: {
    status: state.getMemberListReducer.status,
    error: state.getMemberListReducer.error,
    data: state.getMemberListReducer.data
  },
  putKuotaMemberState: {
    status: state.putKuotaMemberReducer.status,
    error: state.putKuotaMemberReducer.error,
    data: state.putKuotaMemberReducer.data
  },
  searchParamsLeavePageState: state.searchParamsLeavePageReducer.data
});

export default compose(connect(mapStateToProps, {
  getMemberList,
  putKuotaMember,
  resetputKuotaMember,
  setSearchParamsLeavePage
}))(Member);
