import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useHistory, useParams } from "react-router-dom";
import PropTypes from 'prop-types';
import * as yup from 'yup';
import moment from 'moment-timezone';

import { errorScroll } from '../../../helpers/functions';
import { convertArrayToObject } from '../../../helpers';
import { validation, setEndTime, exportParticipant } from '../functions';

import Header from '../../../components/Header';
import Loading from '../../../components/Loading';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import useSnackbar from '../../../components/Snackbar/functions';

import FormSchedule from '../Form';
import MemberList from './MemberList';
import AddNewMember from '../AddNewMember';

import { getAvailDetail, resetAvailDetail } from '../../../routes/ClassDetail/action';
import {
  putAvail,
  resetPutAvail,
  getMemberAvailList,
  deleteAvail,
  resetDeleteAvail,
  resetMemberAvailList,
} from '../action';
import {
  putBookingCancel,
  resetPutBookingCancel
} from '../../../routes/ClassSchedule/action'

import './styles.scss';

const EditSchedule = ({ 
  i18n, 
  getAvailDetailState,
  getAvailDetail,
  putAvailState,
  putAvail,
  resetPutAvail,
  getMemberAvailListState,
  getMemberAvailList,
  putBookingCancelState,
  putBookingCancel,
  resetPutBookingCancel,
  deleteAvailState,
  deleteAvail,
  resetDeleteAvail,
  resetMemberAvailList,
}) => {
  const history = useHistory();
  const { id } = useParams(); //use to get detail schedule
  const { showSnackbar } = useSnackbar();
  
  const [ form, setForm ] = useState();
  const [ loading, setLoading ] = useState(false);
  const [ errors, setErrors ] = useState();
  const [ showModal, setShowModal ] = useState(false);
  const [ showDeleteModal, setShowDeleteModal ] = useState(false);
  const [ selectedParticipant, setSelectedParticipant ] = useState();
  const [ capacityClass, setCapacityClass ] = useState();
  const [ showDrawer, setShowDrawer ] = useState(false);

  const availDetail = getAvailDetailState?.data
  const participantList = getMemberAvailListState?.data || [];

  const handleSubmit = (e) => {
    e.preventDefault();
    const params = { ...form }

    let schema = yup.object().shape(validation(true));

    schema
      .validate(params, { abortEarly: false })
      .then(() => {
        setLoading(true);
        putAvail(id, {
          "classId": availDetail?.classId,
          "date": `${moment(params?.startDate).format('YYYY-MM-DD')} ${params?.start_time?.hh}:${params?.start_time?.mm}`,
          "instructorId": params?.instructor,
          "minAttendance": Number(params?.min_attendance),
          "timezone": "Asia/Jakarta"
        });
      })
      .catch((err) => {
        setErrors(convertArrayToObject(err.inner, 'path', 'message'));
        errorScroll();
      });
  };

  const handlerChanges = (value, key, errorKey) => {
    setForm({ ...form, [key]: value });
    let errorMessage = { ...errors, [errorKey ? errorKey : key]: undefined };

    setErrors(errorMessage);
  };

  const removeParticipant = (participant) => {
    if (!selectedParticipant) {
      setSelectedParticipant(participant)
      setShowModal(true)
    } else {
      // submit delete participant 
      putBookingCancel(selectedParticipant?.bookingId)
      setShowModal(false)
      setLoading(true)
    }
  }

  const downloadParticipantList = () => {
    setLoading(true)
    exportParticipant(
      id, 
      availDetail, 
      setLoading
    )
  }

  const deleteSchedule = () => {
    if (showDeleteModal) {
      setShowDeleteModal(false);
      setLoading(true);
      deleteAvail(id);
    } else {
      setShowDeleteModal(true)
    }
  }

  const refetchData = () => {
    getAvailDetail(id)
    getMemberAvailList(id)
  }

  useEffect(() => {
    if (id) {
      setLoading(true)
      refetchData()
    }

    return () => {
      resetAvailDetail();
      resetMemberAvailList();
    }
  }, [id])

  useEffect(() => {
    if (availDetail && String(availDetail?.id) === String(id)) {
      setForm({
        class: availDetail?.classId,
        startDate: new Date(moment(availDetail?.date).format('YYYY-MM-DD')),
        start_time: {
          hh: moment(availDetail?.date).format('HH'),
          mm: moment(availDetail?.date).format('mm')
        },
        instructor: availDetail?.instructorId,
        min_attendance: availDetail?.minAttendance,
        duration: availDetail?.duration
      })
      setLoading(false)
    }

    // TODO handle too many request
  }, [availDetail])

  useEffect(() => {
    const new_end_time = setEndTime(form);
    if (new_end_time) {
      setForm({
        ...form,
        end_time: new_end_time
      })
    }
  }, [form?.start_time, form?.duration])

  useEffect(() => {
    if (loading) {
      const { status, error } = putAvailState;

      if (status === 'resolve') {
        setLoading(false)
        showSnackbar({
          message: i18n(`schedule_admin.success${id ? '':'_create'}`),
          type: 'success'
        })
        resetPutAvail();
        history.push('/admin/schedule')
      } else if(status === 'rejected'){
        const errMessage = error?.response?.data?.message
        setLoading(false)
        showSnackbar({
          message: i18n(`error_message.${errMessage}`),
          type: 'error'
        })
        resetPutAvail()
      }
    }

  }, [loading, putAvailState])

  useEffect(() => {
    if (loading) {
      const { status, error } = putBookingCancelState;

      if (status === 'resolve') {
        setLoading(false)
        showSnackbar({
          message: i18n(`schedule_admin.success_delete_participant`),
          type: 'success'
        })
        resetPutBookingCancel();
        getMemberAvailList(id)
      } else if(status === 'rejected'){
        const errMessage = error?.response?.data?.message
        setLoading(false)
        showSnackbar({
          message: i18n(`error_message.${errMessage}`),
          type: 'error'
        })
        resetPutBookingCancel();
      }
    }

  }, [loading, putBookingCancelState])

  useEffect(() => {
    if (loading) {
      const { status, error } = deleteAvailState;

      if (status === 'resolve') {
        setLoading(false)
        showSnackbar({
          message: i18n(`schedule_admin.succes_delete_avail`),
          type: 'success'
        })
        resetDeleteAvail();
        history.push('/admin/schedule')
      } else if(status === 'rejected'){
        const errMessage = error?.response?.data?.message
        setLoading(false)
        showSnackbar({
          message: i18n(`error_message.${errMessage}`),
          type: 'error'
        })
        resetDeleteAvail();
      }
    }

  }, [loading, deleteAvailState])
  
  // const dateTimeFormat = 'YYYY-MM-DD HH:mm';
  // const today = moment().format(dateTimeFormat);
  const disable_modify = false;

  return (<>
    <Header label={i18n('schedule_admin.title_edit')}/>
    {(loading || getAvailDetailState.status === 'pending') && <Loading/>}
    <div className='create-schedule-admin' style={{
      padding: "33px 32px 16px",
      boxSizing: "border-box"
    }}>
      <form onSubmit={handleSubmit} autoComplete="off">
        <MemberList
          i18n={i18n}
          removeParticipant={removeParticipant}
          downloadParticipantList={downloadParticipantList}
          participantList={participantList}
          capacityClass={capacityClass}
          setShowDrawer={setShowDrawer}
          disableModify={disable_modify}
        />
        <FormSchedule
          i18n={i18n}
          form={form}
          handlerChanges={handlerChanges}
          errors={errors}
          isEdit
          setCapacityClass={setCapacityClass}
        />
        
        <Button color="purple" type="button" classname='delete-schedule' onClick={() => deleteSchedule()} disabled={disable_modify}>{i18n("cta.delete")}</Button>

        <div className='btn-wrap'>
          <Button color="pink" onClick={() => history.push('/admin/schedule')}>{i18n("cta.cancel_2")}</Button>
          <Button color="purple" type='submit' disabled={disable_modify}>{i18n("cta.save")}</Button>
        </div>
      </form>
    </div>
    
    <AddNewMember
      i18n={i18n}
      showDrawer={showDrawer}
      setShowDrawer={setShowDrawer}
      participantList={participantList}
      availId={id}
      refetchData={refetchData}
    />

    <Modal
      title={i18n('confirm_modal.title')}
      onClose={() => setShowModal(false)}
      isOpen={showModal}
      hasCloseIcon
      buttonSecondaryActionHandler={() => {
        setShowModal(false)
        setSelectedParticipant()
      }}
      buttonActionHandler={removeParticipant}
      buttonText={i18n('cta.save')}
      buttonSecondaryText={i18n('cta.cancel_2')}
      portalId="top-up-modal"
    >
      <div className="title-confirm">{i18n('confirm_modal.title_delete_participant')}</div>
      <div className="detail-class-confirm" style={{ fontWeight: "500", textAlign: 'center' }}>
        <div className="title">{selectedParticipant?.name}</div>
      </div>
    </Modal>

    <Modal
      onClose={() => setShowDeleteModal(false)}
      isOpen={showDeleteModal}
      hasCloseIcon
      buttonSecondaryActionHandler={() => setShowDeleteModal(false)}
      buttonActionHandler={() => deleteSchedule()}
      buttonText={i18n('cta.yes_sure')}
      buttonSecondaryText={i18n('cta.cancel')}
      title={i18n('confirm_modal.title_delete_schedule')}
      portalId="schedule-confirm-delete"
    />
  </>);
};

EditSchedule.propTypes = {
  setLang: PropTypes.func,
  history: PropTypes.object,
  i18n: PropTypes.func,
  getAvailDetailState: PropTypes.object,
  getAvailDetail: PropTypes.func,
  putAvailState: PropTypes.object,
  putAvail: PropTypes.func,
  resetPutAvail: PropTypes.func,
  getMemberAvailListState: PropTypes.object,
  getMemberAvailList: PropTypes.func,
  putBookingCancelState: PropTypes.object,
  putBookingCancel: PropTypes.func,
  deleteAvailState: PropTypes.object,
  deleteAvail: PropTypes.func,
  resetDeleteAvail: PropTypes.func,
  resetMemberAvailList: PropTypes.func,
};

const mapStateToProps = (state) => ({
  lang: state.langReducer.lang,
  getAvailDetailState: {
    status: state.getAvailDetailReducer.status,
    error: state.getAvailDetailReducer.error,
    data: state.getAvailDetailReducer.data
  },
  putAvailState: {
    status: state.putAvailReducer.status,
    error: state.putAvailReducer.error,
    data: state.putAvailReducer.data
  },
  getMemberAvailListState: {
    status: state.getMemberAvailListReducer.status,
    error: state.getMemberAvailListReducer.error,
    data: state.getMemberAvailListReducer.data
  },
  putBookingCancelState: {
    status: state.putBookingCancelReducer.status,
    error: state.putBookingCancelReducer.error,
    data: state.putBookingCancelReducer.data
  },
  deleteAvailState: {
    status: state.deleteAvailReducer.status,
    error: state.deleteAvailReducer.error,
    data: state.deleteAvailReducer.data
  }
});

export default compose(connect(mapStateToProps, {
  getAvailDetail,
  putAvail,
  resetPutAvail,
  getMemberAvailList,
  putBookingCancel,
  resetPutBookingCancel,
  deleteAvail,
  resetDeleteAvail,
  resetMemberAvailList
}))(EditSchedule);
