export const arrayHasValue = (arr) =>
  arr !== undefined &&
  arr !== null &&
  Array.isArray(arr) &&
  Object.prototype.toString.call(arr) === '[object Array]' &&
  arr.length > 0;

export const objectHasValue = (obj) =>
  obj !== null &&
  obj !== undefined &&
  Object.keys(obj).length > 0 &&
  Object.prototype.toString.call(obj) === '[object Object]';

export const stringHasValue = (str) =>
  str !== null &&
  str !== undefined &&
  str !== '' &&
  Object.prototype.toString.call(str) === '[object String]';

export const convertArrayToObject = (array, key, itemKey) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: itemKey ? item[itemKey] : item
    };
  }, initialValue);
};
