import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as yup from 'yup';
import { errorScroll } from '../../helpers/functions';
import { convertArrayToObject } from '../../helpers';
import PropTypes from 'prop-types';
import moment from 'moment'

import TextInput from '../../components/Input/TextInput';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import Loading from '../../components/Loading';
import Modal from '../../components/Modal';
import useSnackbar from '../../components/Snackbar/functions';

import NumberVerification from './components/NumberVerification';
import ProfileForm from './components/ProfileForm';

import {
  postRegisterSendOTP,
  postRegisterVerifyOTP,
  postRegister,
  resetRegisterSendOTP,
  resetRegisterVerifyOTP,
  resetRegister
} from './action';
import './styles.scss';

const Register = ({
  i18n,
  postRegisterSendOTP,
  registerSendOTPState,
  postRegisterVerifyOTP,
  registerVerifyOTPState,
  postRegister,
  registerState,
  resetRegisterSendOTP,
  resetRegisterVerifyOTP,
  resetRegister
}) => {
  const history = useHistory();
  const [form, setForm] = useState();
  const [errors, setErrors] = useState();
  const [step, setStep] = useState(1) //1. phone, 2 otp, 3 profile
  const { showSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const cta = {
    1: 'check_number',
    2: 'verifikasi',
    3: 'register'
  }

  const showSnackbarBy = (message, type) => {
    showSnackbar({
      message: message,
      type: type
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const params = { ...form }
    const otpRegExp = /^(\d*)$/
    const phoneRegExp = /^(?!.*[+][6][2])\d{6,16}$/

    let validatinsSchema = {
      phone: yup
        .string()
        .matches(phoneRegExp, 'phone')
        .test(
          'len',
          i18n('validation.min_phone', { min: 8 }),
          (val) => val && val.toString().length > 8
        )
        .required('required'),
    }

    if (step === 2) {
      validatinsSchema = {
        ...validatinsSchema,
        otp_code: yup
          .string()
          .test(
            'len',
            i18n('validation.min_length', { min: 4 }),
            (val) => val && val.toString().length >= 4
          )
          .matches(otpRegExp, 'number')
          .required('required'),
      }
    }

    if (step === 3) {
      validatinsSchema = {
        ...validatinsSchema,
        name: yup.string().required('required'),
        email: yup.string().email('email'),
        birth_date: yup.date(),
        instagram: yup
          .string()
          .test(
            'len',
            i18n('validation.min_length', { min: 2 }),
            (val) => val && val.toString().length > 1
          ),
        password: yup
          .string()
          .test(
            'len',
            i18n('validation.min_length', { min: 8 }),
            (val) => val && val.toString().length > 7
          )
          .required('required'),
        confirmPassword: yup.string()
          .required('required')
          .oneOf([yup.ref('password'), null], i18n('validation.password_not_match')),
        aggrement: yup.boolean()
          .oneOf([true], i18n('validation.aggrement_unchecked'))
          .required(i18n('validation.aggrement_unchecked')),
      }
    }

    if (params.phone.startsWith("0")) {
      setErrors({ phone: 'Format nomor handphone salah (cth: +62 8XXX)' });
      errorScroll();
      return;
    }

    let schema = yup.object().shape({
      ...validatinsSchema
    });

    schema
      .validate(params, { abortEarly: false })
      .then(() => {
        if (step === 1) {
          resendOtp()
        } else if (step === 2) {
          setLoading(true);
          postRegisterVerifyOTP({
            ...params,
            phone: `+62${params.phone}`,
            otpCode: `${params.otp_code}`
          })
        } else {
          setLoading(true);
          let strDate = ''
          if (params.birth_date) {
            strDate = moment(params.birth_date).format("YYYY-MM-DD")
          }

          postRegister({
            ...params,
            phone: `+62${params.phone}`,
            name: `${params.name}`,
            password: `${params.password}`,
            email: params.email,
            instagram: params.instagram,
            birth: strDate,
          })
        }
      })
      .catch((err) => {
        setErrors(convertArrayToObject(err.inner, 'path', 'message'));
        errorScroll();
      });
  };

  const resendOtp = () => {
    setLoading(true);
    postRegisterSendOTP({
      phone: `+62${form.phone}`
    })
  }

  // STEP 1
  useEffect(() => {
    if (loading) {
      const { status, error } = registerSendOTPState;
      if (status === 'resolve') {
        setLoading(false);
        showSnackbarBy(i18n('register_page.step_1.success_2'), 'success')
        // setStep(2)
        setStep(3)
        setForm({ ...form, gender: 'women' });
        resetRegisterSendOTP()
      } else if (status === 'rejected') {
        setLoading(false);

        // let errMessage = `${i18n('register_page.step_1.failed_2')} `;
        let errMessage = '';
        switch (error?.response?.data?.code) {
          case 409:
            errMessage += `${i18n('register_page.step_1.conflict')}`
            break;
          case 429:
            errMessage += `${i18n('register_page.step_1.too_many_request')}`
            break;
          default:
            errMessage += error?.response?.data?.message
        }
        setShowModal(true);
        setErrorMessage(errMessage);
        // showSnackbarBy(errMessage, 'error')
        resetRegisterSendOTP()
        // registerSendOTPState = undefined; // masi ngebug, value masi ada meskipun success
      }
    }
  }, [registerSendOTPState?.status])

  useEffect(() => {
    if (loading) {
      const { status, error } = registerVerifyOTPState;

      if (status === 'resolve') {
        setLoading(false);
        showSnackbarBy(i18n('register_page.step_2.success'), 'success')
        setStep(3)
        resetRegisterVerifyOTP()
      } else if (status === 'rejected') {
        setLoading(false);
        let errMessage = `${i18n('register_page.step_2.failed')} `;
        switch (error?.response?.status) {
          case 400: // Case OTP Salah
            if (error?.response?.data?.error === 'expired code') {
              errMessage += `${i18n('register_page.step_2.bad_request.expired_code')}`
            } else {
              errMessage += `${i18n('register_page.step_2.bad_request.invalid_code')}`
            }
            break;
          case 429:
            errMessage += `${i18n('register_page.step_2.too_many_request')}`
            break;
          default:
            errMessage += registerVerifyOTPState?.error?.response?.data?.message
        }
        showSnackbarBy(errMessage, 'error')
        resetRegisterVerifyOTP()
      }
    }
  }, [registerVerifyOTPState?.status])

  useEffect(() => {
    if (loading) {
      const { status, error } = registerState;

      if (status === 'resolve') {
        setLoading(false);
        showSnackbarBy(i18n('register_page.step_3.success'), 'success')
        resetRegister()
        history.push('/login')
      } else if (status === 'rejected') {
        setLoading(false);

        let errMessage = `${i18n('register_page.step_3.failed')} `;
        switch (error?.response?.status) {
          case 400:
            errMessage += `${i18n('register_page.step_3.bad_request')}`
            break;
          case 409:
            errMessage += `${i18n('register_page.step_3.conflict')}`
            break;
          case 429:
            errMessage += `${i18n('register_page.step_3.too_many_request')}`
            break;
          default:
            errMessage += error?.response?.data?.message
        }
        showSnackbarBy(errMessage, 'error')
        resetRegister()
      }
    }
  }, [registerState?.status])

  const handlerChanges = (value, key, errorKey) => {
    setForm({ ...form, [key]: value });
    let errorMessage = { ...errors, [errorKey ? errorKey : key]: undefined };

    // Handle Step 1 

    // Handle Step 2

    // Handle Step 3
    if (key === 'confirmPassword') {
      if (form?.password !== value) {
        errorMessage = { ...errors, [key]: 'password_not_match' }
      }
    }
    // todo, default value check invalid

    setErrors(errorMessage);
  };

  return (<>
    <section className='register-container' id={`step${step}`}>
      {loading && <Loading />}
      <Icon icon="arrow" placement='left' onClick={() => {
        if (step === 1) { history.push('/login') }
        else if (step === 3) {
          setStep(1);
        } else {
          setStep(step - 1);
        }
      }} />
      <div className='title'>
        {i18n('register_page.title')}
        <div className="desc">{i18n(`register_page.step${step === 3 ? 2 : 1}`)}</div>
      </div>
      <form onSubmit={handleSubmit} autoComplete="off">
        {step === 1 &&
          <TextInput
            label={i18n('label.handphone')}
            type='text'
            value={form?.phone}
            inputProps={{
              onChange: (e) => handlerChanges(e.target.value, 'phone')
            }}
            withHangingLabel
            errorMessage={errors?.phone && i18n(`validation.${errors?.phone}`)}
            textLeft="+62"
            placeholder="contoh: 813XX"
            withPlaceholder
          />
        }

        {step === 2 &&
          <NumberVerification
            i18n={i18n}
            handlerChanges={val => handlerChanges(val, 'otp_code')}
            err={errors?.otp_code && i18n(`validation.${errors?.otp_code}`)}
            otpCode={form?.otp_code}
            resendOtp={resendOtp}
          />
        }

        {step === 3 &&
          <ProfileForm
            i18n={i18n}
            handlerChanges={handlerChanges}
            errors={errors}
            form={form}
          />
        }

        <Button color="pink">{i18n(`cta.${cta[step]}`)}</Button>
      </form>
    </section>

    <Modal
      onClose={() => setShowModal(false)}
      isOpen={showModal}
      hasCloseIcon
      title={i18n('confirm_modal.failed_register')}
      portalId="login-need-approve"
      buttonActionHandler={() => setShowModal(false)}
      buttonText={i18n('cta.ok')}
    >
      <div className="title-confirm">{errorMessage}</div>
    </Modal>
  </>);
};

Register.propTypes = {
  postRegisterSendOTP: PropTypes.func,
  registerSendOTPState: PropTypes.object,
  postRegisterVerifyOTP: PropTypes.func,
  registerVerifyOTPState: PropTypes.object,
  postRegister: PropTypes.func,
  registerState: PropTypes.object,
  resetRegisterSendOTP: PropTypes.func,
  resetRegisterVerifyOTP: PropTypes.func,
  resetRegister: PropTypes.func,
};

const mapStateToProps = (state) => ({
  registerSendOTPState: {
    status: state.postRegisterSendOTPReducer.status,
    error: state.postRegisterSendOTPReducer.error,
    data: state.postRegisterSendOTPReducer.data
  },

  registerVerifyOTPState: {
    status: state.postRegisterVerifyOTPReducer.status,
    error: state.postRegisterVerifyOTPReducer.error,
    data: state.postRegisterVerifyOTPReducer.data
  },

  registerState: {
    status: state.postRegisterReducer.status,
    error: state.postRegisterReducer.error,
    data: state.postRegisterReducer.data
  }
});

export default compose(connect(mapStateToProps, {
  postRegisterSendOTP,
  postRegisterVerifyOTP,
  postRegister,
  resetRegisterSendOTP,
  resetRegisterVerifyOTP,
  resetRegister
}))(Register);
