const adminLogin = {
  data: {},
  status: 'idle',
  error: ''
};

const postLoginReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'POST_LOGIN_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'POST_LOGIN_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'POST_LOGIN_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_POST_LOGIN':
      return {
        ...state,
        ...adminLogin
      };
    default:
      return state;
  }
};

const postResetPasswordReducer = (state = adminLogin, action) => {
  switch (action.type) {
    case 'POST_RESET_PASSWORD_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'POST_RESET_PASSWORD_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'POST_RESET_PASSWORD_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_POST_RESET_PASSWORD':
      return {
        ...state,
        ...adminLogin
      };
    default:
      return state;
  }
};

export { postLoginReducer, postResetPasswordReducer };
