import React, { useState } from 'react';

import Icon from '../../../components/Icon';

import './styles.scss';

const SearchBar = ({
  label,
  inputType,
  placeholder,
  onSubmit
}) => {
  const [ keyword, setKeyword ] = useState();

  const resetSearch = () => {
    setKeyword('');
    onSubmit('');
  }

  return (
    <div className="top search-bar">
      <div className="label">
        {label}
      </div>
      <div className="search-bar">
        <form onSubmit={(e) => {
          e.preventDefault();
          onSubmit(keyword);
        }}>
          <input 
            type={inputType}
            value={keyword} 
            onChange={e => setKeyword(e.target.value)}
            placeholder={placeholder}
          />
          <input type="submit" hidden />
          <Icon icon={`${keyword ? 'close' : 'search'} pink`} onClick={() => keyword ? resetSearch() : onSubmit(keyword)}/>
        </form>
      </div>
    </div>  
  );
};

export default SearchBar;
