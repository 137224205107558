import React from 'react';
import cn from 'classnames';

const Column = ({
  className,
  label,
  value
}) => {

  return (
    <div 
      className={cn("column", className)}
      style={{
        fontSize: "14px",
        display: "flex",
        flexDirection: "column",
        marginBottom: "12px"
      }}
    >
      <span className="label" style={{marginBottom: "4px", fontWeight: "500"}}>{label}</span>
      <span className="value">{value}</span>
    </div>
  );
};

export default Column;
