import React from 'react';
import useSnackbar from '../Snackbar/functions';
import Snackbar from '../Snackbar';

import './styles.scss';

const SnackbarProvider = ({ children }) => {
  const { queue, clearQueue } = useSnackbar();

  const handleClose = () => {
    const elm = document.getElementsByClassName('snackbar');
    const isLastSnackbar = elm.length === 1;

    if (isLastSnackbar) {
      clearQueue();
    }
  };

  return (
    <>
      {children}
      {queue.length > 0 && (
        <div className="snackbar-wrapper v4">
          {queue.map((props, index) => (
            <Snackbar {...props} key={index} onClose={handleClose} show />
          ))}
        </div>
      )}
    </>
  );
};

export default SnackbarProvider;
