import React from 'react';
import moment from 'moment';

import Button from '../../../../components/Button';
import TopNavigation from '../../../../components/TopNavigation';
import DatepickerDropdown from '../../../../components/Dropdown/Datepicker';
import ListDropdown from '../../../../components/Dropdown/List';
import RadioButton from '../../../../components/Input/RadioButton';

import './styles.scss';

const SearchBox = ({
  i18n,
  searchForm,
  setSearchForm,
  onSubmit,
  getClassCategoryListState,
  errors,
  setErrors
}) => {
  
  const { data } = getClassCategoryListState;
  const all_option = {
    classCategory: i18n('label.all'),
    classCategoryId: 0
  }

  const class_type_options = [
    { value: 'REGULER', name: i18n('label.reguler') },
    { value: 'KIDS', name: i18n('label.children') }
  ];

  return (
    <TopNavigation className="search-wrap">
      <ListDropdown
        i18n={i18n}
        placeholder={i18n('label.class_category')}
        labelDrawer={i18n('label.select', { label: i18n('label.class')})}
        value={searchForm?.class} 
        onChange={val => {
          setSearchForm({ ...searchForm, class: val }) 
          setErrors({ ...errors, class: undefined })
        }}  
        iconInput="class"
        options={[all_option, ...data]}
        valueKey="classCategoryId"
        labelKey='classCategory'
        withHangingLabel={false}
        errorMessage={errors?.class && i18n(`validation.${errors?.class}`)}
      />
      <DatepickerDropdown
        i18n={i18n}
        placeholder={i18n('label.date')}
        labelDrawer={i18n('label.select', { label: i18n('label.date')})}
        value={searchForm?.date} 
        onChange={(date) => {
          setSearchForm({ ...searchForm, date }) 
          setErrors({ ...errors, date: undefined })
        }}  
        iconInput="calendar"
        withHangingLabel={false}
        minDate={new Date(moment().format('YYYY-MM-DD'))}
        maxDate={new Date(moment().add(3, 'month').format('YYYY-MM-DD'))}
        errorMessage={errors?.date && i18n(`validation.${errors?.date}`)}
      />

      <RadioButton
        className='class-type'
        options={class_type_options}
        value={searchForm?.classType || 'REGULER'}
        inputProps={{
          onChange: (e) =>setSearchForm({ ...searchForm, classType: e.target.value })
        }}
      />
      <Button 
        color="white-pink" 
        icon="search"
        onClick={onSubmit}
      >
        {i18n('cta.see_schedule')}
      </Button>
    </TopNavigation>
  );
};

export default SearchBox;
