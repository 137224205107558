import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as yup from 'yup';
import { errorScroll } from '../../../helpers/functions';
import { convertArrayToObject } from '../../../helpers';

import AccountHeader from '../components/Header';
import Button from '../../../components/Button';
import TextInput from '../../../components/Input/TextInput';
import Loading from '../../../components/Loading';
import NumberVerification from '../../Register/components/NumberVerification';
import useSnackbar from '../../../components/Snackbar/functions';

import { 
  postChangePhone, 
  postVerifyPhone, 
  resetChangePhone, 
  resetVerifyPhone 
} from '../action';

import '../ChangePassword/styles.scss';

const ChangePhone = ({
  i18n,
  postChangePhone,
  resetChangePhone, 
  postChangePhoneState,
  postVerifyPhone,  
  resetVerifyPhone,
  postVerifyPhoneState,
  accountState
}) => {
  const history = useHistory();
  const [ form, setForm ] = useState();
  const [ errors, setErrors ] = useState();
  const [ loading, setLoading ] = useState(false);
  const [ step, setStep ] = useState(1) //1. phone, 2 otp
  const { showSnackbar } = useSnackbar();

  const name = "Putri Titian";

  const handleSubmit = (e) => {
    e.preventDefault();
    const params = { ...form }
    const phoneRegExp = /^(?!.*[+][6][2]).*/
    
    let validatinsSchema = {
      phone: yup
        .string()
        .matches(phoneRegExp, 'phone')
        .test(
          'len',
          i18n('validation.min_length', { min: 8 }),
          (val) => val && val.toString().length > 7
        )
        .typeError('number')
        .required('required'),
    }

    if (step === 2) {
      validatinsSchema = {
        ...validatinsSchema,
        otp_code: yup
          .number()
          .test(
            'len',
            i18n('validation.min_length', { min: 4 }),
            (val) => val && val.toString().length > 3
          )
          .typeError('number')
          .required('required')
          .integer('number')
          .positive('number'),
      }
    }

    let schema = yup.object().shape({
      ...validatinsSchema
    });

    schema
      .validate(params, { abortEarly: false })
      .then(() => {
        if (step === 1) {
          setLoading(true)
          postChangePhone({
            newPhone: `+62${params?.phone}`
          })
        } else {
          setLoading(true)
          postVerifyPhone({
            newPhone: params?.phone,
            otpCode: params?.otp_code
          })
        }
      })
      .catch((err) => {
        setErrors(convertArrayToObject(err.inner, 'path', 'message'));
        errorScroll();
      });
  };

  const handlerChanges = (value, key, errorKey) => {
    setForm({ ...form, [key]: value });
    let errorMessage = { ...errors, [errorKey ? errorKey : key]: undefined };

    if (key === 'confirmPassword') {
      if(form?.newPassword !== value) {
        errorMessage = {...errors, [key]: 'password_not_match'}
      }
    }

    setErrors(errorMessage);
  };

  const showSnackbarBy = (message, type) => {
    showSnackbar({
      message: message,
      type: type
    })
  }

  useEffect(() => {
    if(loading) {
      const { status, error } = postChangePhoneState;

      if (status === 'resolve') {
        setLoading(false);
        showSnackbarBy(i18n('register_page.step_1.success'), 'success')
        setStep(2)
        resetChangePhone()
      } else if (status === 'rejected') {
        setLoading(false);

        let errMessage = `${i18n('register_page.step_1.failed')} `;
        switch(error?.response?.status) {
          case 409:
            errMessage += `${i18n('register_page.step_1.conflict')}`
            break;
          case 429:
            errMessage += `${i18n('register_page.step_1.too_many_request')}`
            break;
          default:
            errMessage += error?.response?.data?.message
        }
        showSnackbarBy(errMessage, 'error')
        resetChangePhone()
      }
    }
  }, [postChangePhoneState?.status])

  useEffect(() => {
    if (loading) {
      const { status, error } = postVerifyPhoneState;
      if (status === 'resolve') {
        setLoading(false);
        showSnackbarBy(i18n('profile_page.change_phone_message.success'), 'success')
        history.push('/account')
        resetVerifyPhone()
      } else if (status === 'rejected') {
        const errMessage = error?.response?.data?.message;
        setLoading(false);
        showSnackbarBy(i18n(`error_message.${errMessage}`), 'error')
        resetVerifyPhone()
      }

    }
  }, [postVerifyPhoneState?.status])

  return (<>
    {loading && <Loading/>}
    <AccountHeader i18n={i18n} name={name} history={history} onBackDest={'/account'}/>
    <section className='change-password-container'>
      <div className="title">{i18n('profile_page.change_phone')}</div>
      <form onSubmit={handleSubmit}>
        {step === 1 &&
          <TextInput
            label={i18n('profile_page.change_phone')}
            type='text'
            value={form?.phone}
            inputProps={{
              onChange: (e) => handlerChanges(e.target.value, 'phone')
            }}
            withHangingLabel
            errorMessage={errors?.phone && i18n(`validation.${errors?.phone}`)}
            textLeft="+62"
          />
        }

        {step === 2 && 
        <NumberVerification
          i18n={i18n}
          handlerChanges={val => handlerChanges(val, 'otp_code')}
          err={errors?.otp_code && i18n(`validation.${errors?.otp_code}`)}
          otpCode={form?.otp_code}
        />
        }
        <Button color="pink" id='logout' type='submit'>{i18n('profile_page.change_phone')}</Button>
      </form>
    </section>
  </>);
};

const mapStateToProps = (state) => ({
  postChangePhoneState: {
    status: state.postChangePhoneReducer.status,
    error: state.postChangePhoneReducer.error,
    data: state.postChangePhoneReducer.data
  },
  postVerifyPhoneState: {
    status: state.postVerifyPhoneReducer.status,
    error: state.postVerifyPhoneReducer.error,
    data: state.postVerifyPhoneReducer.data
  }
});

export default compose(connect(mapStateToProps, { 
  postChangePhone, 
  postVerifyPhone, 
  resetChangePhone, 
  resetVerifyPhone 
}))(ChangePhone);