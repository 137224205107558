import React from 'react';
import cn from 'classnames';

import './styles.scss';

const Card = ({
  color,
  children,
  className
}) => {

  return (
    <div className={cn("card", color, className)}>
      {children}
    </div>
  );
};

export default Card;
