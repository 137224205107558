import * as yup from 'yup';
import moment from 'moment-timezone';
import axios from 'axios';

const baseUrl = process.env.REACT_APP_BACKEND_URI;

const validation = (isEdit) => ({
  startDate: yup.string().required('required'),
  start_time: yup.object().shape({
    hh: yup.string().required('required'),
    mm: yup.string().required('required')
  }),
  ...isEdit ? {} : {
    class: yup.string().required('required')
  },
  instructor: yup.string().required('required')
})

const setEndTime = (form) => {
  if (form?.start_time?.hh && form?.start_time?.mm && form?.duration) {
    const startFullTime = `${form?.start_time?.hh}:${form?.start_time?.mm}`
    const endFullTime = moment(startFullTime, 'HH:mm').add(form?.duration, 'ms');
    
    const end_time = {
      hh: endFullTime.format('HH'),
      mm: endFullTime.format('mm'),
    }

    return end_time
  }

  return null;
}

const exportParticipant = (id, availDetail, setLoading) => {
  const accessToken = localStorage.getItem('accessToken');
  const authToken = {
    'Authorization': `Bearer ${accessToken}`,
  };

  return axios({
    method: 'get',
    url: `${baseUrl}/v1/avail/${id}/attendance/export`,
    ...(accessToken ? { 
      headers: {
        ...authToken,
      } 
    } : {}),
    responseType: "blob",
  }).then((response) => (
    new Blob([response.data], {
      type: response.headers["content-type"],
    })
  )).then(blob => URL.createObjectURL(blob))
  .then(url => {
    const linkAction = document.createElement("a");
  
    linkAction.href = url;
    linkAction.download = `${availDetail?.className} - Attendance (${moment(availDetail?.date).format('YYYY-MM-DD HH:mm')})`;
    linkAction.click();
    linkAction.remove();
    setLoading(false)
  }).catch((err) => {
    return err;
  });
}

export {
  validation,
  setEndTime,
  exportParticipant,
}