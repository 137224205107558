import React from 'react';
import cn from 'classnames';

import './styles.scss';

const TopNavigation = ({
  children,
  className
}) => {
  return (
    <div className={cn("top-nav-container", className)}>
      {children}
    </div>
  );
};

export default TopNavigation;
