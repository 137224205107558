import React from 'react';
import Datepicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

const DatepickerComponent = props => {
  return (
    <Datepicker
      {...props}
    />
    
  );
};

export default DatepickerComponent;
