import React, { useEffect, useState } from 'react';
import moment from 'moment';

const Timer = ({
  endTime,
  resetTime,
}) => {
  let otpInterval
  const timeFormat = "dddd, MMMM Do YYYY, h:mm:ss a";
  
  const [ duration, setDuration ] = useState();

  const format = (time) => {
    let seconds = time % 60;
    let minutes = Math.floor(time / 60);

    minutes = minutes.toString().length === 1 ? `0${  minutes}` : minutes;
    seconds = seconds.toString().length === 1 ? `0${  seconds}` : seconds;

    return `${minutes  }:${  seconds}`;
  }
  
  const intervalDurationCallback = () => {
    const curr = moment();
    const tmpEndTime = moment(endTime, timeFormat)
    const duration = curr.isSameOrBefore(tmpEndTime) ? tmpEndTime.diff(curr, 'seconds') : 0;

    setDuration(duration)

    if (duration === 0) {
      clearInterval(otpInterval);
      resetTime()
    }
  }

  useEffect(() => {
    return () => clearInterval(otpInterval);
  }, []);

  useEffect(() => {
    if (endTime) {
      intervalDurationCallback();
      
      otpInterval = setInterval(intervalDurationCallback, 1000)
    }
  }, [endTime])

  if (!duration) {
    return null;
  }

  return (
    <span className="timer" style={{paddingLeft: "4px"}}>
      ({format(duration)})
    </span>
    
  );
};

export default Timer;
