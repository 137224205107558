// import axios from 'axios';
import { apiCaller } from '../../helpers/apiCaller';

const postLoginRequest = () => ({
  type: 'POST_LOGIN_REQUEST'
});

const postLoginSuccess = (data) => ({
  type: 'POST_LOGIN_SUCCESS',
  payload: data
});

const postLoginFailure = (error) => ({
  type: 'POST_LOGIN_FAILURE',
  payload: error
});

const postLogin = (params) => {
  return (dispatch) => {
    dispatch(postLoginRequest());
    return apiCaller('v1/account/login', 'post', params)
      .then((data) => {
        if (data?.response?.data?.status === 422 || data?.response?.data?.code === 403 || data?.response?.data?.isError) {
          return dispatch(postLoginFailure(data?.response?.data));
        }
        return dispatch(postLoginSuccess(data));
      })
      .catch((error) => {
        return dispatch(postLoginFailure(error))
      });
  };
};


const postResetPasswordRequest = () => ({
  type: 'POST_RESET_PASSWORD_REQUEST'
});

const postResetPasswordSuccess = (data) => ({
  type: 'POST_RESET_PASSWORD_SUCCESS',
  payload: data
});

const postResetPasswordFailure = (error) => ({
  type: 'POST_RESET_PASSWORD_FAILURE',
  payload: error
});

const postResetPassword = (params, isOtp) => {
  return (dispatch) => {
    dispatch(postResetPasswordRequest());
    return apiCaller(`v1/account/reset-pass${isOtp ? '/send-otp':''}`, 'post', params)
      .then((data) => {
        if (data?.status != 200) {
          return dispatch(postResetPasswordFailure(data?.response?.data));
        }

        return dispatch(postResetPasswordSuccess(data));
      })
      .catch((error) => dispatch(postResetPasswordFailure(error)));
  };
};

// RESET DATA
const resetData = () => ({
  type: 'RESET_POST_LOGIN'
});

const resetDataForgotPassword = () => ({
  type: 'RESET_POST_RESET_PASSWORD'
});

export { postLogin, 
  resetData, 
  postResetPassword, 
  resetDataForgotPassword 
};
