import React from 'react';
import Parser from 'html-react-parser';

import Header from '../../../components/Header';
import HeaderDetail from '../../../components/Header/Detail';

import './styles.scss';

const ContactUs = ({
  i18n
}) => {

  const content = [
    "Pengaturan akun dapat dilakukan pada halaman <a href='/account'>Akun</a>.",
    "Kamu dapat mengubah nomor telepon dan kata sandi, dengan menggunakan verifikasi kode OTP melalui Whatsapp.",
    "Kata sandi atau Password bersifat rahasia. Kami pihak danta tidak akan meminta password dan OTP kepada member kami.",
    "Untuk Bantuan mengenai login bisa hubungi Danta di Whatsapp melalui nomor (<a href='https://wa.me/6281805018899?text=Halo%20Danta%20Yoga%2C%20Saya%20(Nama%20Anda)%20butuh%20bantuan' target='_blank'>+62 818-0501-8899</a>)",
  ]

  return (<>
    <Header label={i18n('label.help')}/>
    <HeaderDetail title={i18n('label.account')}/>
    <section className='contact-us-help-container'>
      <main>
        <h1>Bantuan untuk account</h1>
        <div className="content-wrap">
          <ol>
            {content.map((item, idx) => (
              <li className='item' key={idx}>{Parser(item)}</li>
            ))}
          </ol>
        </div>
      </main>
    </section>
  </>);
};

export default ContactUs;
