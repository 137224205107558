import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useHistory, useParams } from "react-router-dom";
import PropTypes from 'prop-types';
import * as yup from 'yup';

import { errorScroll } from '../../../helpers/functions';
import { convertArrayToObject } from '../../../helpers';
import { validation } from '../functions';

import Header from '../../../components/Header';
import Button from '../../../components/Button';
import Loading from '../../../components/Loading';
import Modal from '../../../components/Modal';
import FormInstructor from '../Form';
import useSnackbar from '../../../components/Snackbar/functions';

import { getInstructorDetail, 
  postInstructorDetail,   
  resetPostInstructorDetail, 
  resetInstructorDetail,
  deleteInstructor,
  resetDeleteInstructor
} from '../action';

const AdminInstructorCreateEdit = ({ 
  i18n, 
  getInstructorDetail,
  instructorDetailState,
  postInstructorDetail,
  postInstructorDetailState,
  resetInstructorDetail,
  deleteInstructor,
  resetDeleteInstructor,
  deleteInstructorState,
  resetPostInstructorDetail,
}) => {
  const history = useHistory();
  const [ form, setForm ] = useState();
  const [ loading, setLoading ] = useState(false);
  const [ errors, setErrors ] = useState();
  const [ showModal, setShowModal ] = useState(false);
  const { id } = useParams();
  const isEdit = !!id;
  const { showSnackbar } = useSnackbar();

  const instructorDetail = instructorDetailState?.data || {};

  const handleSubmit = (e) => {
    e.preventDefault();
    const params = { ...form }

    let schema = yup.object().shape(validation);

    console.log('submit shay11');
    console.log(params, validation, schema);

    schema
      .validate(params, { abortEarly: false })
      .then(() => {
        console.log('submit 1');
        setLoading(true);
        postInstructorDetail(id, {
          "name": params?.name,
          "phoneNumber": params?.phoneNumber
        })
      })
      .catch((err) => {
        setErrors(convertArrayToObject(err.inner, 'path', 'message'));
        errorScroll();
      });
  };

  const handlerChanges = (value, key, errorKey) => {
    setForm({ ...form, [key]: value });
    let errorMessage = { ...errors, [errorKey ? errorKey : key]: undefined };

    setErrors(errorMessage);
  };

  const handleDelete = () => {
    if (showModal) {
      setLoading(true)
      setShowModal(false)
      deleteInstructor(id)
    } else {
      setShowModal(true)
    }
  }

  useEffect(() => {
    if (id) {
      setLoading(true)
      getInstructorDetail(id)
    }

    return () => {
      resetInstructorDetail();
    }
  }, [id])

  useEffect(() => {
    if (instructorDetail && String(instructorDetail?.id) === String(id)) {
      setForm({
        name: instructorDetail?.name,
        phoneNumber: instructorDetail?.phoneNumber
      })
      setLoading(false)
    }
  }, [instructorDetail])

  useEffect(() => {
    if (loading) {
      const { status, error } = postInstructorDetailState;

      if (status === 'resolve') {
        setLoading(false)
        showSnackbar({
          message: i18n(`instructor_admin.success${id ? '':'_create'}`),
          type: 'success'
        })
        resetPostInstructorDetail()
        history.push('/admin/instructor')
      } else if(status === 'rejected'){
        const errMessage = error?.response?.data?.message
        setLoading(false)
        showSnackbar({
          message: i18n(`error_message.${errMessage}`),
          type: 'error'
        })
        resetPostInstructorDetail()
      }
    }

  }, [loading, postInstructorDetailState])

  useEffect(() => {
    if (loading) {
      const { status, error } = deleteInstructorState;

      if (status === 'resolve') {
        setLoading(false)
        showSnackbar({
          message: i18n(`instructor_admin.success_delete`),
          type: 'success'
        })
        resetDeleteInstructor()
        history.push('/admin/instructor')
      } else if(status === 'rejected'){
        const errMessage = error?.response?.data?.message
        setLoading(false)
        showSnackbar({
          message: i18n(`error_message.${errMessage}`),
          type: 'error'
        })
        resetPostInstructorDetail()
      }
    }

  }, [loading, deleteInstructorState])
  
  return (<>
    <Header label={i18n(`label.${isEdit ? 'edit':'create'}_instructor`)}/>
    {loading && <Loading/>}
    <form onSubmit={handleSubmit} autoComplete="off">
      <div className='create-instructor-admin' style={{
        padding: "33px 32px 16px",
        boxSizing: "border-box",
        height: "calc(100vh - 51px - 70px)"
      }}>
        <FormInstructor
          i18n={i18n}
          form={form}
          handlerChanges={handlerChanges}
          errors={errors}
        />
        {isEdit && 
          <Button color="purple" type="button" style={{ width: '100%', marginTop: "30px", marginBottom: "30px" }} onClick={() => handleDelete()}>{i18n("cta.delete")}</Button>
        }
      </div>
      <footer className='member-footer'>
        <Button color="pink" type='submit'>{i18n("cta.save")}</Button>
      </footer>
  </form> 
  <Modal
    onClose={() => setShowModal(false)}
    isOpen={showModal}
    hasCloseIcon
    buttonSecondaryActionHandler={() => setShowModal(false)}
    buttonActionHandler={handleDelete}
    buttonText={i18n('cta.yes_sure')}
    buttonSecondaryText={i18n('cta.cancel')}
    title={i18n('confirm_modal.title_delete_instructor')}
    portalId="instructor-confirm-delete"
  />
  </>);
};

AdminInstructorCreateEdit.propTypes = {
  setLang: PropTypes.func,
  history: PropTypes.object,
  i18n: PropTypes.func,
  getInstructorDetail: PropTypes.func,
  instructorDetailState: PropTypes.object,
  postInstructorDetail: PropTypes.func,
  postInstructorDetailState: PropTypes.object,
  deleteInstructor: PropTypes.func,
  resetDeleteInstructor: PropTypes.func,
  deleteInstructorState: PropTypes.object,
};

const mapStateToProps = (state) => ({
  lang: state.langReducer.lang,
  instructorDetailState: {
    status: state.getInstructorDetailReducer.status,
    error: state.getInstructorDetailReducer.error,
    data: state.getInstructorDetailReducer.data
  },
  postInstructorDetailState: {
    status: state.postInstructorDetailReducer.status,
    error: state.postInstructorDetailReducer.error,
    data: state.postInstructorDetailReducer.data
  },
  deleteInstructorState: {
    status: state.deleteInstructorReducer.status,
    error: state.deleteInstructorReducer.error,
    data: state.deleteInstructorReducer.data
  }
});

export default compose(connect(mapStateToProps, {
  getInstructorDetail,
  postInstructorDetail,
  resetPostInstructorDetail,
  resetInstructorDetail,
  deleteInstructor,
  resetDeleteInstructor
}))(AdminInstructorCreateEdit);
