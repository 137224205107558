import { apiCaller } from '../../helpers/apiCaller';

// Send OTP
const postRegisterSendOTPRequest = () => ({
  type: 'POST_REGISTER_SEND_OTP_REQUEST'
});

const postRegisterSendOTPSuccess = (data) => ({
  type: 'POST_REGISTER_SEND_OTP_SUCCESS',
  payload: data
});

const postRegisterSendOTPFailure = (error) => ({
  type: 'POST_REGISTER_SEND_OTP_FAILURE',
  payload: error
});

const postRegisterSendOTP = (params) => {
  return (dispatch) => {
    dispatch(postRegisterSendOTPRequest());
    return apiCaller('v1/otp/send', 'post', params)
      .then((data) => {
        if (data?.response?.data?.isError) {
          return dispatch(postRegisterSendOTPFailure(data));
        }
        return dispatch(postRegisterSendOTPSuccess(data));
      })
      .catch((error) => {
        dispatch(postRegisterSendOTPFailure(error))
      });
  };
};

const resetRegisterSendOTP = () => ({
  type: 'RESET_REGISTER_SEND_OTP'
});

// Verification OTP
const postRegisterVerifyOTPRequest = () => ({
  type: 'POST_REGISTER_VERIFY_OTP_REQUEST'
});

const postRegisterVerifyOTPSuccess = (data) => ({
  type: 'POST_REGISTER_VERIFY_OTP_SUCCESS',
  payload: data
});

const postRegisterVerifyOTPFailure = (error) => ({
  type: 'POST_REGISTER_VERIFY_OTP_FAILURE',
  payload: error
});

const postRegisterVerifyOTP = (params) => {
  return (dispatch) => {
    dispatch(postRegisterVerifyOTPRequest());
    return apiCaller('v1/otp/verify', 'post', params)
      .then((data) => {
        if (data?.response?.status) {
          return dispatch(postRegisterVerifyOTPFailure(data));
        }
        
        return dispatch(postRegisterVerifyOTPSuccess(data));
      })
      .catch((error) => {
        dispatch(postRegisterVerifyOTPFailure(error))
      });
  };
};

const resetRegisterVerifyOTP = () => ({
  type: 'RESET_REGISTER_VERIFY_OTP'
});

// Post Register
const postRegisterRequest = () => ({
  type: 'POST_REGISTER_REQUEST'
});

const postRegisterSuccess = (data) => ({
  type: 'POST_REGISTER_SUCCESS',
  payload: data
});

const postRegisterFailure = (error) => ({
  type: 'POST_REGISTER_FAILURE',
  payload: error
});

const postRegister = (params) => {
  return (dispatch) => {
    dispatch(postRegisterRequest());
    return apiCaller('v1/account/register', 'post', params)
      .then((data) => {
        if (data?.response?.status) {
          return dispatch(postRegisterFailure(data));
        }
        return dispatch(postRegisterSuccess(data));
      })
      .catch((error) => {
        console.log(error)
        dispatch(postRegisterFailure(error))
      });
  };
};

const resetRegister = () => ({
  type: 'RESET_REGISTER'
});

export { postRegisterSendOTP, 
  postRegisterVerifyOTP, 
  postRegister,
  resetRegisterSendOTP,
  resetRegisterVerifyOTP,
  resetRegister 
};
