import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useHistory } from 'react-router';
import InfiniteScroll from "react-infinite-scroll-component";

import Header from '../../../components/Header';
import HeaderDetail from '../../../components/Header/Detail';
import Card from '../components/Card';
import Loading from '../../../components/Loading';
import Icon from '../../../components/Icon';
import Button from '../../../components/Button';

import { getBookingHistory, resetBookingHistory } from '../action';

import '../styles.scss';

const HistoryClassSchedule = ({
  i18n,
  getBookingHistoryState,
  getBookingHistory,
  resetBookingHistory
}) => {
  const history = useHistory();
  const [ loading, setLoading ] = useState(false);
  const [ bookClassList, setBookClassList ] = useState([]);
  const [ bookClassPagination, setBookClassPagination ] = useState();

  const { data, status, pagination } = getBookingHistoryState;
  const { page = 0, totalPage = 0 } = pagination;

  const loadData = (params) => {
    getBookingHistory({
      ...params,
      size: 10
    })
  }

  useEffect(() => {
    loadData();
    setBookClassList([])
    setBookClassPagination()

    return () => {
      resetBookingHistory()
    }
  }, []);

  useEffect(() => {
    if(status === 'pending') {
      setLoading(true)
    } else {
      setLoading(false)
      
      const tmpData = data ? data : [];
      setBookClassList([
        ...bookClassList,
        ...tmpData
      ])
      setBookClassPagination(pagination)
    }
  }, [status]);

  return (<>
    {((loading && !bookClassPagination?.page) || status === 'pending') && <Loading/>}
    <Header label={i18n('label.my_schedule')}/>
    <HeaderDetail 
      title={i18n('history_class.title')}
      desc={i18n('history_class.desc')}
      simple
    />
    <section className='schedule-container' id="history">
      {(bookClassList && bookClassList.length > 0) ? 
      <div className="schedule-list">
        <InfiniteScroll
          dataLength={bookClassList ? bookClassList.length : 0}
          next={() => loadData({ page: page + 1 })}
          hasMore={page!==totalPage}
          loader={<h4>Loading...</h4>}
        >
        {bookClassList.map((bookData, idx) => (
          <Card 
            key={idx}
            bookData={bookData}
            i18n={i18n}
            color="grey"
          />
        ))}
        </InfiniteScroll>
      </div>
      : 
      <div className='empty-class'>
        <Icon icon="empty"/><br/>
        {i18n('history_class.empty')}
        <Button color="pink" onClick={() => history.push('/class')}>{i18n('cta.see_avail_schedule')}</Button>
      </div> 

      }
    </section>
  </>);
};

const mapStateToProps = (state) => ({
  getBookingHistoryState: state.getBookingHistoryReducer
});

export default compose(connect(
  mapStateToProps, { 
    getBookingHistory,
    resetBookingHistory
  })
)(HistoryClassSchedule);

