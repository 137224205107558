import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import TextInput from '../../../components/Input/TextInput';

import './styles.scss';

const FormInstructor = ({ 
  i18n, 
  form, 
  handlerChanges, 
  errors, 
}) => {
  return (
    <div className='instructor-form-admin box'>
      <TextInput
        label={i18n('label.fullname')}
        value={form?.name}
        inputProps={{
          onChange: (e) => handlerChanges(e.target.value, 'name')
        }}
        withHangingLabel
        errorMessage={errors?.name && i18n(`validation.${errors?.name}`)}
      />
      <TextInput
        label={i18n('label.handphone')}
        value={form?.phoneNumber}
        inputProps={{
          onChange: (e) => handlerChanges(e.target.value, 'phoneNumber')
        }}
        withHangingLabel
        errorMessage={errors?.phoneNumber && i18n(`validation.${errors?.phoneNumber}`)}
      />
    </div>
  );
};

FormInstructor.propTypes = {
  setLang: PropTypes.func,
  history: PropTypes.object,
  i18n: PropTypes.func,
};

export default compose()(FormInstructor);
