import React from 'react';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import PropTypes from 'prop-types';
import reducers from './redux';

const browserHistory = createBrowserHistory();

const Root = (props) => {
  const middleware = [thunk];

  const composeEnhancers =
    process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose;

  const enhancer = composeEnhancers(applyMiddleware(...middleware));
  const store = createStore(reducers, enhancer);

  return (
    <BrowserRouter history={browserHistory}>
      <Provider store={store}>{props.children}</Provider>
    </BrowserRouter>
  );
};

Root.propTypes = {
  children: PropTypes.node
};

export default Root;
