import { apiCaller } from '../../helpers/apiCaller';

const getMyBookingListRequest = () => ({
  type: 'GET_MY_BOOKING_LIST_REQUEST'
});

const getMyBookingListSuccess = (data) => ({
  type: 'GET_MY_BOOKING_LIST_SUCCESS',
  payload: data
});

const getMyBookingListFailure = (error) => ({
  type: 'GET_MY_BOOKING_LIST_FAILURE',
  payload: error
});

const getMyBookingList = (params) => {
  return (dispatch) => {
    dispatch(getMyBookingListRequest());
    return apiCaller('v1/booking/', 'get', undefined, false, {
      page: params?.page || 1,
      size: params?.size || 100,
      sort: params?.sort || 'created_at asc'
    })
      .then((data) => {
        if (data?.response?.status) {
          return dispatch(getMyBookingListFailure(data));
        }
        return dispatch(getMyBookingListSuccess(data));
      })
      .catch((error) => {
        dispatch(getMyBookingListFailure(error))
      });
  };
};

const resetMyBookingList = () => ({
  type: 'RESET_MY_BOOKING_LIST',
})

// Booking history 
const getBookingHistoryRequest = () => ({
  type: 'GET_BOOKING_HISTORY_REQUEST'
});

const getBookingHistorySuccess = (data) => ({
  type: 'GET_BOOKING_HISTORY_SUCCESS',
  payload: data
});

const getBookingHistoryFailure = (error) => ({
  type: 'GET_BOOKING_HISTORY_FAILURE',
  payload: error
});

const getBookingHistory = (params) => {
  return (dispatch) => {
    dispatch(getBookingHistoryRequest());
    return apiCaller('v1/booking/history', 'get', undefined, false, params)
      .then((data) => {
        if (data?.response?.status) {
          return dispatch(getBookingHistoryFailure(data));
        }
        return dispatch(getBookingHistorySuccess(data));
      })
      .catch((error) => {
        dispatch(getBookingHistoryFailure(error))
      });
  };
};

const resetBookingHistory = () => ({
  type: 'RESET_BOOKING_HISTORY',
})

// Cancel Booking  
const putBookingCancelRequest = () => ({
  type: 'PUT_BOOKING_CANCEL_REQUEST'
});

const putBookingCancelSuccess = (data) => ({
  type: 'PUT_BOOKING_CANCEL_SUCCESS',
  payload: data
});

const putBookingCancelFailure = (error) => ({
  type: 'PUT_BOOKING_CANCEL_FAILURE',
  payload: error
});

const putBookingCancel = (bookingId) => {
  return (dispatch) => {
    dispatch(putBookingCancelRequest());
    return apiCaller(`v1/booking/cancel/${bookingId}`, 'put')
      .then((data) => {
        if (data?.response?.status) {
          return dispatch(putBookingCancelFailure(data));
        }
        return dispatch(putBookingCancelSuccess(data));
      })
      .catch((error) => {
        dispatch(putBookingCancelFailure(error))
      });
  };
};

const resetPutBookingCancel = () => ({
  type: 'RESET_PUT_BOOKING_CANCEL',
})


export { 
  getMyBookingList, 
  getBookingHistory, 
  putBookingCancel,
  resetPutBookingCancel,
  resetMyBookingList,
  resetBookingHistory,
};
