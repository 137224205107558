const defaultData = []

const defaultState = {
  data: defaultData,
  status: 'idle',
  error: ''
};

// Seach Avail;
const getAvailDetailReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'GET_AVAIL_DETAIL_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'GET_AVAIL_DETAIL_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'GET_AVAIL_DETAIL_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_AVAIL_DETAIL':
      return {
        ...defaultState
      };
    default:
      return state;
  }
};

// Booking;
const postBookingReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'POST_BOOKING_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'POST_BOOKING_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'POST_BOOKING_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_POST_BOOKING':
      return {
        ...state,
        ...defaultState,
        data: {}
      };
    default:
      return state;
  }
};

export { getAvailDetailReducer, postBookingReducer };
