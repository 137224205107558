import React from 'react';
import cn from 'classnames';
import './styles.scss';

const SlideButton = ({
  activeDot,
  setActiveDot,
  total,
}) => {

  return (
    <div className='slide-dot-wrap'>
      {Array.from({length: total}).map((item, index) => (
        <div 
          key={index}
          className={cn('dot', { active: index === activeDot })}
          onClick={() => index !== activeDot ? setActiveDot(index) : {}}
        />
      ))}
    </div>
  );
};

export default SlideButton;