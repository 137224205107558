import DefaultLayout from './components/Layout/Default';
import FirstLayout from './components/Layout/First';
import DefaultLayoutWithoutFooter from './components/Layout/Default/WithoutFooter';
import DefaultLayoutWithoutFooterAdmin from './components/Layout/Default/WithoutFooterAdmin';

import Home from './routes/Home';
import WelcomeSplash from './routes/WelcomeSplash';

import SignIn from './routes/SignIn';
import Login from './routes/Login';
import ForgotPassword from './routes/Login/ForgotPassword';
import RegisterForm from './routes/Register';

import ClassRegister from './routes/ClassRegister';
import ClassDetail from './routes/ClassDetail';
import ClassSchedule from './routes/ClassSchedule';
import HistoryClassSchedule from './routes/ClassSchedule/History';

import Account from './routes/Account';
import ChangePassword from './routes/Account/ChangePassword';
import ChangePhone from './routes/Account/ChangePhone';

import Help from './routes/Help';
import ContactUs from './routes/Help/ContactUs';
import NotFound from './routes/NotFound';
import TNC from './routes/Help/TNC';
import BookingHelp from './routes/Help/BookingHelp';
import PaymentHelp from './routes/Help/Payment';
import AccountHelp from './routes/Help/Account';
import CancellationHelp from './routes/Help/Cancellation';

// Admin 
import AdminHome from './routesAdmin/Home';
import Member from './routesAdmin/Member';
import EditMember from './routesAdmin/Member/Edit';
import HistoryMember from './routesAdmin/Member/History';
import AdminClass from './routesAdmin/Class';
import AdminSchedule from './routesAdmin/Schedule';
import CreateEditClass from './routesAdmin/Class/CreateEdit';
import CreateSchedule from './routesAdmin/Schedule/Create';
import EditSchedule from './routesAdmin/Schedule/Edit';
import AdminInstructor from './routesAdmin/Instructor';
import AdminInstructorCreateEdit from './routesAdmin/Instructor/CreateEdit';

const routes = [
  {
    component: FirstLayout,
    path: "/welcome",
    routes: [
      {
        path: '/welcome',
        exact: true,
        component: WelcomeSplash
      },
    ]
  },
  {
    component: FirstLayout,
    path: "/sign-in",
    routes: [
      {
        path: '/sign-in',
        exact: true,
        component: SignIn
      },
    ]
  },
  {
    component: FirstLayout,
    path: "/login",
    routes: [
      {
        path: '/login',
        exact: true,
        component: Login
      },
    ]
  },
  {
    component: FirstLayout,
    path: "/forgot-password",
    routes: [
      {
        path: '/forgot-password',
        exact: true,
        component: ForgotPassword
      },
    ]
  },
  {
    component: FirstLayout,
    path: "/register",
    routes: [
      {
        path: '/register',
        exact: true,
        component: RegisterForm
      }
    ]
  },
  {
    component: DefaultLayoutWithoutFooter,
    path: '/class',
    routes: [
      {
        path: '/class',
        exact: true,
        component: ClassRegister
      },
      {
        path: '/class/detail/:id',
        exact: true,
        component: ClassDetail
      },
    ]
  },
  {
    component: DefaultLayoutWithoutFooter,
    path: '/schedule',
    routes: [
      {
        path: '/schedule',
        exact: true,
        component: ClassSchedule
      },
      {
        path: '/schedule/history',
        exact: true,
        component: HistoryClassSchedule
      },
    ]
  },
  {
    component: DefaultLayoutWithoutFooterAdmin,
    path: '/admin',
    routes: [
      {
        path: '/admin',
        exact: true,
        component: AdminHome
      },
      {
        path: '/admin/member',
        exact: true,
        component: Member
      },
      {
        path: '/admin/member/edit/:id',
        exact: true,
        component: EditMember
      },
      {
        path: '/admin/member/:id/history',
        exact: true,
        component: HistoryMember
      },
      {
        path: '/admin/class',
        exact: true,
        component: AdminClass
      },
      {
        path: '/admin/class/create',
        exact: true,
        component: CreateEditClass
      },
      {
        path: '/admin/class/edit/:id',
        exact: true,
        component: CreateEditClass
      },
      {
        path: '/admin/instructor',
        exact: true,
        component: AdminInstructor
      },
      {
        path: '/admin/instructor/create',
        exact: true,
        component: AdminInstructorCreateEdit
      },
      {
        path: '/admin/instructor/edit/:id',
        exact: true,
        component: AdminInstructorCreateEdit
      },
      {
        path: '/admin/schedule',
        exact: true,
        component: AdminSchedule
      },
      {
        path: '/admin/schedule/create',
        exact: true,
        component: CreateSchedule
      },
      {
        path: '/admin/schedule/edit/:id',
        exact: true,
        component: EditSchedule
      }
    ]
  },
  {
    component: DefaultLayout,
    routes: [
      {
        path: '/account',
        exact: true,
        component: Account
      },
      {
        path: '/account/change-password',
        exact: true,
        component: ChangePassword
      },
      {
        path: '/account/change-phone',
        exact: true,
        component: ChangePhone
      },
      {
        path: '/help',
        exact: true,
        component: Help
      },
      {
        path: '/help/contact-us',
        exact: true,
        component: ContactUs
      },
      {
        path: '/help/term-and-condition',
        exact: true,
        component: TNC
      },
      {
        path: '/help/booking',
        exact: true,
        component: BookingHelp
      },
      {
        path: '/help/payment',
        exact: true,
        component: PaymentHelp
      },
      {
        path: '/help/account',
        exact: true,
        component: AccountHelp
      },
      {
        path: '/help/cancellation',
        exact: true,
        component: CancellationHelp
      },
      {
        path: '/',
        exact: true,
        component: Home
      },
      {
        path: '*',
        component: NotFound
      }
    ]
  }
];

export default routes;