import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../../components/Icon';

const MemberList = ({ 
  i18n,
  downloadParticipantList,
  removeParticipant,
  participantList,
  capacityClass = 0,
  setShowDrawer,
  disableModify = false
}) => {
  
  return (
    <div className='participant-container box'>
      <div className="top">
        <div className="label">{i18n('label.participant_list')} {`(${participantList.length}/${capacityClass})`}</div>
        <Icon icon="download" className={participantList.length === 0 ? 'disabled': ''} onClick={() => participantList.length > 0 ? downloadParticipantList() : {}} />
      </div>
      {participantList.map((participant, key) => (
        <div className="member-wrap" key={key}>
          <span className="name">
            <span className="number">{key+1}. </span> 
            {participant?.name}
          </span>
          {
            !disableModify && <Icon icon="close" onClick={() => removeParticipant(participant)} />
          }
        </div>
      ))}
      {participantList.length === 0 && 
        <div className="no-data-found" style={{ fontStyle: 'italic'}}>Belum ada participant</div> 
      }
      {(participantList.length < capacityClass && !disableModify)  &&
      <div className="add-member-btn" onClick={setShowDrawer}>+ Tambah peserta</div>
      }
    </div>
  );
};

MemberList.propTypes = {
  i18n: PropTypes.func,
};

export default MemberList;
