import React from 'react';

import Header from '../../../components/Header';
import HeaderDetail from '../../../components/Header/Detail';

import HeaderHelp from '../components/Header';

import './styles.scss';

const TNC = ({
  i18n
}) => {

  const tncList = [
    "Hadirlah 15 menit sebelum kelas dimulai, perkenalkan diri anda pada instruktur kelas. Sampaikan pada instruktur kelas jika ada cedera pada tubuh anda, atau hal hal penting lain yang ingin anda sampaikan.",
    "Tinggalkan sepatu / alas kaki anda di rak sepatu di lantai dua.",
    "Tersedia 2 ruang ganti dan 1 kamar shower untuk dipergunakan.",
    "Gunakan baju olahraga yang nyaman dan layak.",
    "Simpan barang barang anda di loker.",
    "Anda hanya diperbolehkan membawa tumbler air minum, handuk kecil, dan ponsel dalam mode senyap ke ruang kelas. Semua Alat Peraga Danta yang dibutuhkan tersedia dan selalu dibersihkan setelah digunakan.",
    "Ikuti instruksi instruktur selama kelas berlangsung, jangan ragu meminta bantuan instruktur jika diperlukan.",
    "Segera tinggalkan kelas jika anda harus menerima panggilan telepon, batuk atau bersin bersin.",
    "Member hanya boleh mengambil foto atau video kelas dengan izin instruktur. Kesempatan pengambilan foto dan video disediakan di sesi foto dan video setelah kelas.",
    "Letakan, matras dan hammock bekas pakai di tempat yang sudah disediakan, petugas kami akan membersihkannya untuk anda."
  ]

  return (<>
    <Header label={i18n('label.tnc')}/>
    <HeaderDetail title={i18n('tnc_page.title')} desc={i18n('tnc_page.desc')}/>
    <section className='tnc-container'>
      {/* <HeaderHelp i18n={i18n}/> */}
      <main>
        <div className="tnc-wrap">
          <div className="top">
            <div className="title">{i18n('tnc_page.header1')}</div>
          </div>
          <div className="content-wrap">
              <ol className="tnc-list">
                {tncList.map((tnc, idx) => (
                  <li className="item" key={idx}>{tnc}</li>
                ))}
              </ol>
          </div>
        </div>
      </main>
    </section>
  </>);
};

export default TNC;
