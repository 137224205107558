const defaultState = {
  data: {},
  status: 'idle',
  error: ''
};

// Send OTP
const postRegisterSendOTPReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'POST_REGISTER_SEND_OTP_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'POST_REGISTER_SEND_OTP_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'POST_REGISTER_SEND_OTP_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_REGISTER_SEND_OTP':
      return {
        ...state,
        ...defaultState
      };
    default:
      return state;
  }
};

// Verify OTP
const postRegisterVerifyOTPReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'POST_REGISTER_VERIFY_OTP_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'POST_REGISTER_VERIFY_OTP_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'POST_REGISTER_VERIFY_OTP_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_REGISTER_VERIFY_OTP':
      return {
        ...state,
        ...defaultState
      };
    default:
      return state;
  }
};

// Register 
const postRegisterReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'POST_REGISTER_REQUEST':
      return {
        ...state,
        status: 'pending'
      };
    case 'POST_REGISTER_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    case 'POST_REGISTER_SUCCESS':
      return {
        ...state,
        status: 'resolve',
        data: action.payload.data.data
      };
    case 'RESET_REGISTER':
      return {
        ...state,
        ...defaultState
      };
    default:
      return state;
  }
};
export { postRegisterSendOTPReducer, postRegisterVerifyOTPReducer, postRegisterReducer };
