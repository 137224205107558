import React from 'react';
import cn from 'classnames';
import Icon from '../Icon';
import './styles.scss';

const Button = ({
  children,
  classname = '',
  color = '',
  flat, //flat, regular
  Element = 'button',
  icon,
  ...otherProps
}) => {

  return (
    <Element 
      className={cn(`btn ${classname} ${color}`, {
        flat
      })} 
      {...otherProps}
    >
      {icon && <Icon icon={icon}/>}
      {children}
    </Element>
  );
};

export default Button;