import React, { useState, useEffect } from 'react';
import cn from 'classnames'

import Drawer from '../../Drawer';
import Checkbox from '../../Input/Checkbox';
import Icon from '../../Icon';
import Button from '../../Button';
import usePrevious from '../../../helpers/usePreviousState';

import './styles.scss';

const FilterDrawer = ({
  i18n,
  filterList,
  onSubmit
}) => {
  const [ filterDrawer, setFilterDrawer ] = useState();
  const [ filterSearch, setFilterSearch ] = useState([]);
  const [ filterSearchSubmit, setFilterSearchSubmit ] = useState([]);
  const [ showDrawer, setShowDrawer ] = useState(false);
  const [ showButtonSubmit, setShowButtonSubmit ] = useState(false);
  const prevFilterSearch = usePrevious(filterSearch);

  const drawerHandler = (data) => {
    setFilterDrawer(data)
    setShowDrawer(true);
  }

  const filterHandler = (key, typeFilter, value) => {
    const newFilterSearch = {
      key,
      value 
    };

    if (typeFilter === 'radio') {
      const active = filterSearch.find(selectedItem => selectedItem.key === key && selectedItem.value === value);
      
      if (active) {
        const tmpFilterSearch = filterSearch.filter(selectedItem => selectedItem.key !== key && selectedItem.value !== value);
        setFilterSearch(tmpFilterSearch)
        submitFilter(tmpFilterSearch);
      } else {
        const tmpFilterSearch = filterSearch.filter(selectedItem => selectedItem.key !== key);
        setFilterSearch([ ...tmpFilterSearch, newFilterSearch ])
        submitFilter([ ...tmpFilterSearch, newFilterSearch ]);
      }
      
    } else {
      const active = filterSearch.find(selectedItem => selectedItem.key === key && selectedItem.value.value === value.value);
      
      if (active) {
        const tmpFilterSearch = filterSearch.filter((selectedItem) => selectedItem.value.value !== value.value);
        setFilterSearch(tmpFilterSearch)
      } else {
        setFilterSearch([ ...filterSearch, newFilterSearch ])
      }
    }
  }

  const submitFilter = (data = filterSearch) => {
    setFilterSearchSubmit(data)
    setShowDrawer(false)
    setFilterDrawer()
    setShowButtonSubmit(false)

    onSubmit && onSubmit(data)
  }

  const closeDrawer = () => {
    setShowDrawer(!showDrawer)
    setFilterDrawer()
    setFilterSearch(filterSearchSubmit || [])
    setShowButtonSubmit(false)
  }

  useEffect(() => {
    if (showDrawer && filterSearch.length > 0 && JSON.stringify(filterSearch) !== JSON.stringify(prevFilterSearch)) {
      setShowButtonSubmit(true)
    }
  }, [showDrawer, filterSearch])

  return (<>
    <div className="floating-btn">
      <div className="btn-wrap">
      {filterList.map((filterType, idx) => {
        const active = filterSearch.find(({ key }) => key === filterType.key);
      
        return (
          <button 
            key={idx}
            className={cn({ 'active': !!active })} 
            onClick={() => drawerHandler(filterType)}
          >{i18n(`cta.${filterType?.key}`)}</button>
        )  
      })}
      </div>
    </div>

    <Drawer
      showDrawer={showDrawer}
      top={filterDrawer?.drawerTop || 300}
      threshold={0.5}
      onCloseDrawer={closeDrawer}
      className="filter-drawer-component"
    >
      {onCloseDrawer => {
        return (
          <>
            <Drawer.Header
              title={i18n(`cta.${filterDrawer?.key}`)}
              onCloseDrawer={onCloseDrawer}
            />
            <Drawer.Body className="list-drawer">
              <div className="filter-list" id={filterDrawer?.key}>
                {filterDrawer?.list.map(({ label, value }, idx) => {
                  if (filterDrawer?.type === 'checkbox') {
                    return (
                      <div className="filter-item-container checkbox">
                        <div className="title">{label}</div>
                        <div className="filter-item-wrap">
                          {value.map((itemVal, idx) => {
                            const active = filterSearch.find(selectedItem => selectedItem.key === filterDrawer?.key && JSON.stringify(selectedItem.value) === JSON.stringify(itemVal));

                            return (
                              <Checkbox
                                key={idx}
                                className='filter-item'
                                label={itemVal?.label}
                                checked={!!active}
                                onChange={() => filterHandler(filterDrawer?.key, filterDrawer?.type, itemVal)}
                              />
                            )
                          })}
                        </div>
                      </div>
                    )
                  } else {
                    const active = filterSearch.find(selectedItem => selectedItem.key === filterDrawer?.key && selectedItem.value === value);
                    
                    return (
                      <div className="filter-item-container radio" key={idx} onClick={() => filterHandler(filterDrawer?.key, filterDrawer?.type, value)}>
                        <span className="label">{label}</span>
                        {active && <Icon icon="check"/>}
                      </div>
                    )
                  }
                })}
              </div>
              {/* {filterDrawer?.type === 'checkbox' && filterSearch} */}
              {showButtonSubmit && 
              <Button color='pink' onClick={() => submitFilter()}>{i18n('cta.apply')}</Button>
              }
            </Drawer.Body>
          </>
        );
      }}
    </Drawer>
  </>);
};

export default FilterDrawer;
