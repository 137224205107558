import { apiCaller } from '../../helpers/apiCaller';

// Search Avail Detail
const getAvailDetailRequest = () => ({
  type: 'GET_AVAIL_DETAIL_REQUEST'
});

const getAvailDetailSuccess = (data) => ({
  type: 'GET_AVAIL_DETAIL_SUCCESS',
  payload: data
});

const getAvailDetailFailure = (error) => ({
  type: 'GET_AVAIL_DETAIL_FAILURE',
  payload: error
});

const getAvailDetail = (availId) => {
  return (dispatch) => {
    dispatch(getAvailDetailRequest());
    return apiCaller(`v1/avail/${availId}`, 'get', undefined, false)
      .then((data) => {
        if (data?.response?.status) {
          return dispatch(getAvailDetailFailure(data));
        }
        return dispatch(getAvailDetailSuccess(data));
      })
      .catch((error) => {
        dispatch(getAvailDetailFailure(error))
      });
  };
};

const resetAvailDetail = () => ({
  type: 'RESET_AVAIL_DETAIL'
});

// Booking Class
const postBookingRequest = () => ({
  type: 'POST_BOOKING_REQUEST'
});

const postBookingSuccess = (data) => ({
  type: 'POST_BOOKING_SUCCESS',
  payload: data
});

const postBookingFailure = (error) => ({
  type: 'POST_BOOKING_FAILURE',
  payload: error
});

const postBooking = (params) => {
  return (dispatch) => {
    dispatch(postBookingRequest());
    return apiCaller(`v1/booking`, 'post', params, false)
      .then((data) => {
        if (data?.response?.status) {
          return dispatch(postBookingFailure(data));
        }
        return dispatch(postBookingSuccess(data));
      })
      .catch((error) => {
        dispatch(postBookingFailure(error))
      });
  };
};

// RESET DATA
const resetData = () => ({
  type: 'RESET_POST_BOOKING'
});

export { getAvailDetail, postBooking, resetAvailDetail, resetData };
