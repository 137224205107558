import React from 'react';
import Parser from 'html-react-parser';

import Header from '../../../components/Header';
import HeaderDetail from '../../../components/Header/Detail';

import './styles.scss';

const CancellationHelp = ({
  i18n
}) => {

  const tncList = [
    'Buka Menu <a href="/schedule" target="_blank">“Jadwal Saya”</a>',
    "Klik jadwal yang ingin kamu batalkan. Akan muncul detail kelas dalam bentuk dropdown",
    "Jika tombol tidak muncul, maka kamu sudah tidak bisa melakukan pembatalan karena syarat dan ketentuan yang berlaku.",
    "Jika pembatalan berhasil, kamu akan dihapus dari daftar kelas yang kamu pilih", 
    "Kuota dapat digunakan di kelas manapun (Selain Aerial Danta Yoga for Kids)",
  ]

  return (<>
    <Header label={i18n('label.tnc')}/>
    <HeaderDetail title={i18n('cancellation_help_page.title')} desc={i18n('cancellation_help_page.desc')}/>
    <section className='cancellation-help-container'>
      {/* <HeaderHelp i18n={i18n}/> */}
      <main>
        <div className="body-wrap">
          <div className="content-wrap">
            <div className='sort-info'>Pembatalan Kelas dapat dilakukan baik oleh Member dan Danta Management.</div>
            <div className='sort-info'>Pembatalan oleh Member dapat dilakukan maksimum H-2 (Pukul 11:00 Pagi) sebelum kelas dimulai.</div>
            <div className='sort-info'>Kuota akan dikembalikan di setiap pembatalan kelas.</div>
            <h2>Langkah-Langkah Melakukan Pembatalan</h2>
            <ol className="tnc-list">
              {tncList.map((tnc, idx) => (
                <li className="item" key={idx}>{Parser(tnc)}</li>
              ))}
            </ol>
          </div>
        </div>
      </main>
    </section>
  </>);
};

export default CancellationHelp;
