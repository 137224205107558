import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../../components/Button';
import './styles.scss';

const BannerPromo = ({ 
  i18n,
  data = {},
  withoutTitle = false
}) => {
  const { title, imageUrl, linkUrl, desc } = data;

  return (
    <div className='promo-wrap'>
      <div className="top">
        <div className="left">
          {!withoutTitle && <div className="title">{title}</div>}
          <div className="desc">{desc}</div>
        </div>
        <Button 
          color="purple" 
          flat 
          Element="a" 
          href={linkUrl} 
          target="_blank"
          rel="noreferrer noopener"
        >{i18n('cta.see_detail')}</Button>
      </div>
      <img src={imageUrl} alt={title}/>
    </div>
  );
};

BannerPromo.propTypes = {
  setLang: PropTypes.func,
  history: PropTypes.object,
  i18n: PropTypes.func,
  getEventList: PropTypes.func,
  getTourList: PropTypes.func,
  eventList: PropTypes.object,
  tourList: PropTypes.object
};

export default BannerPromo;
