import { apiCaller } from '../../helpers/apiCaller';

const getInstructorListRequest = () => ({
  type: 'GET_INSTRUCTOR_LIST_REQUEST'
});

const getInstructorListSuccess = (data) => ({
  type: 'GET_INSTRUCTOR_LIST_SUCCESS',
  payload: data
});

const getInstructorListFailure = (error) => ({
  type: 'GET_INSTRUCTOR_LIST_FAILURE',
  payload: error
});

const getInstructorList = (params) => {
  return (dispatch) => {
    dispatch(getInstructorListRequest());
    return apiCaller('v1/instructor', 'get', undefined, undefined, {
      ...params,
      Size: 100
    })
      .then((data) => {
        if (data?.response?.data?.code) {
          return dispatch(getInstructorListFailure(data));
        }

        return dispatch(getInstructorListSuccess(data));
      })
      .catch((error) => dispatch(getInstructorListFailure(error)));
  };
};

// get instructor detail
const getInstructorDetailRequest = () => ({
  type: 'GET_INSTRUCTOR_DETAIL_REQUEST'
});

const getInstructorDetailSuccess = (data) => ({
  type: 'GET_INSTRUCTOR_DETAIL_SUCCESS',
  payload: data
});

const getInstructorDetailFailure = (error) => ({
  type: 'GET_INSTRUCTOR_DETAIL_FAILURE',
  payload: error
});

const getInstructorDetail = (id) => {
  return (dispatch) => {
    dispatch(getInstructorDetailRequest());
    return apiCaller(`v1/instructor/${id}`, 'get')
      .then((data) => {
        if (data?.response?.data?.code) {
          return dispatch(getInstructorDetailFailure(data));
        }

        return dispatch(getInstructorDetailSuccess(data));
      })
      .catch((error) => dispatch(getInstructorDetailFailure(error)));
  };
};

const resetInstructorDetail = () => {
  return {
    type: 'RESET_INSTRUCTOR_DETAIL'
  }
};

// edit instructor
const postInstructorDetailRequest = () => ({
  type: 'POST_INSTRUCTOR_DETAIL_REQUEST'
});

const postInstructorDetailSuccess = (data) => ({
  type: 'POST_INSTRUCTOR_DETAIL_SUCCESS',
  payload: data
});

const postInstructorDetailFailure = (error) => ({
  type: 'POST_INSTRUCTOR_DETAIL_FAILURE',
  payload: error
});

const postInstructorDetail = (id, params) => {
  return (dispatch) => {
    dispatch(postInstructorDetailRequest());
    return apiCaller(`${id ? `v1/instructor/${id}` : `v1/instructor`}`, `${id ? 'put':'post'}`, params)
      .then((data) => {
        if (data?.response?.data?.code) {
          return dispatch(postInstructorDetailFailure(data));
        }

        return dispatch(postInstructorDetailSuccess(data));
      })
      .catch((error) => dispatch(postInstructorDetailFailure(error)));
  };
};

const resetPostInstructorDetail = () => ({
  type: 'RESET_POST_INSTRUCTOR_DETAIL'
});

// delete instructor
const deleteInstructorRequest = () => ({
  type: 'DELETE_INSTRUCTOR_REQUEST'
});

const deleteInstructorSuccess = (data) => ({
  type: 'DELETE_INSTRUCTOR_SUCCESS',
  payload: data
});

const deleteInstructorFailure = (error) => ({
  type: 'DELETE_INSTRUCTOR_FAILURE',
  payload: error
});

const deleteInstructor = (id) => {
  return (dispatch) => {
    dispatch(deleteInstructorRequest());
    return apiCaller(`v1/instructor/${id}`, 'delete')
      .then((data) => {
        if (data?.response?.data?.code) {
          return dispatch(deleteInstructorFailure(data));
        }

        return dispatch(deleteInstructorSuccess(data));
      })
      .catch((error) => dispatch(deleteInstructorFailure(error)));
  };
};

const resetDeleteInstructor = () => ({
  type: 'RESET_DELETE_INSTRUCTOR'
});

export { 
  getInstructorList,
  getInstructorDetail,
  postInstructorDetail,
  resetPostInstructorDetail,
  resetInstructorDetail,
  deleteInstructor,
  resetDeleteInstructor,
};
